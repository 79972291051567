import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddClient from './AddClient';
import EditClient from './EditClient';
import ManageClients from './ManageClients';

const Clients = () => (
    <Switch>
        <Route path="/clients/manage" render={(props) => <ManageClients name="Zarządzaj klientami" {...props} />} />
        <Route path="/clients/add" render={(props) => <AddClient name="Dodaj klienta" {...props} />} />
        <Route path="/clients/edit/:id" render={(props) => <EditClient {...props} />} />
        <Redirect from="/clients" to="/clients/manage" />
    </Switch>
);

export default Clients;
