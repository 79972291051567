import { GET_CLIENT, GET_CLIENTS } from './ClientActions';

const clientReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CLIENT:
            return {
                ...state,
                client: action.client,
            };
        case GET_CLIENTS:
            return {
                ...state,
                clients: action.clients,
            };
        default:
            console.debug('clientReducer :: hit default', action.type);
            return state;
    }
};

export default clientReducer;
