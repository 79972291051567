import React from 'react';

import style from './style.module.scss';

const Spinner = () => (
    <div className={style.spinner}>
        <div className="rect1" />
        <div className="rect2" />
        <div className="rect3" />
        <div className="rect4" />
        <div className="rect5" />
    </div>
);

export default Spinner;

export const CenteredSpinner = () => (
    <div className={style.centered}>
        <Spinner />
    </div>
);
