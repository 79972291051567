import React from 'react';
import { Link } from 'react-router-dom';

import { useNavbarElement } from './helpers';

const NavLink = ({ name, url, icon, pathname, sidebarRegexp }) => {
    const cn = useNavbarElement(sidebarRegexp, pathname, url);

    return (
        <li className={cn} key={name}>
            <Link className="c-sidebar-nav-link" to={url}>
                <i className={`c-sidebar-nav-icon ${icon}`} /> {name}
            </Link>
        </li>
    );
};

export default NavLink;
