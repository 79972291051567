import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import AllActionButtons from '../../../../../components/Table/ActionButtons/AllButtons';
import Table from '../../../../../components/Table/Table';
import * as ListApiClient from '../../../../../domain/list/ListApiClient';
import { useListHandleRequest } from '../../../../../infrastructure/api/hooks';

const ListWithTermTable = ({ data, mutate, type, webUrl }) => {
    const history = useHistory();

    const onDelete = useListHandleRequest(type, ListApiClient.deleteList, mutate, 'Lista została usunięta.');

    const renderActionCell = ({ original }) => (
        <AllActionButtons
            onEdit={() => history.push(`/${webUrl}/manage/${original.id}`)}
            onDelete={() => onDelete(original)}
        />
    );

    const columns = [
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Typ',
            accessor: 'type',
        },
        {
            Header: 'Utworzono',
            accessor: 'createdAt',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: renderActionCell,
        },
    ];

    return <Table data={data} columns={columns} />;
};

ListWithTermTable.propTypes = {
    data: PropTypes.array.isRequired,
    mutate: PropTypes.func.isRequired,
};

export default ListWithTermTable;
