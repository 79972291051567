import { Field, Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import * as Yup from 'yup';

import { withSubmittingAware } from '../../../../../components/SubmittingAware/SubmittingAware';
import TextInput from './TextInput';

class EditPropertyForm extends Component {
    renderForm = withSubmittingAware(({ values, touched, errors, dirty, isSubmitting }) => {
        return (
            <Form>
                <Row className="justify-content-center mt-2">
                    <Col md="10">
                        <Field
                            name={this.props.name}
                            label={this.props.label}
                            type={this.props.type}
                            options={this.props.options}
                            component={TextInput}
                        />
                    </Col>
                    <Col md="2" className="text-right">
                        <Button
                            type="submit"
                            color="primary"
                            className="d-inline-block mr-1"
                            disabled={isSubmitting || !isEmpty(errors) || !dirty}
                        >
                            <i className="fa fa-save" />
                        </Button>
                        <Button color="danger" type="button" className="d-inline" onClick={this.props.onCancel}>
                            <i className="fa fa-ban" />
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    });

    render() {
        return (
            <Formik
                validationSchema={Yup.object().shape({
                    [this.props.name]: Yup.string().required('Pole wymagane'),
                })}
                initialValues={{
                    [this.props.name]: this.props.initialValue,
                }}
                onSubmit={(values, actions) => {
                    this.props.onSave(values[this.props.name], actions);
                }}
                render={this.renderForm}
            />
        );
    }
}

EditPropertyForm.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.array,
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default EditPropertyForm;
