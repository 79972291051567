import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Switch } from '../../../../../components/Switch/Switch';
import AllActionsButtons from '../../../../../components/Table/ActionButtons/AllButtons';
import Table from '../../../../../components/Table/Table';
import { delaysArray } from '../../../../../domain/crawler/Delay';
import { basicFilter, filterWithAllAndCustom, SelectWithAllTrueFalse } from '../../../../../tools/tableHelpers';

const AdminProjectCrawlersTable = ({ data, onToggleCrawlerActive, onDelete }) => {
    const history = useHistory();

    const renderButtons = (row, action) => {
        const { projectId, id } = row._original;
        const onDelete = () => action(id);
        const onEdit = () => history.push(`/projects/${projectId}/crawlers/${id}/edit`);

        return <AllActionsButtons onDelete={onDelete} onEdit={onEdit} withText={false} />;
    };

    const renderActiveCell = (row) => (
        <Switch
            value={row.value}
            onToggle={() => onToggleCrawlerActive(row.original.id)}
            disabled={row.original.crawlerType.crawlerClass.active === false}
        />
    );

    const renderDelayCell = ({ filter, onChange }) => (
        <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
        >
            <option value="all">Wszystkie</option>
            {delaysArray.map((delay, index) => (
                <option key={index} value={delay.key}>
                    {delay.value}
                </option>
            ))}
        </select>
    );

    const columns = [
        {
            Header: 'Typ',
            accessor: 'crawlerTypeName',
        },
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Aktywny',
            accessor: 'active',
            Cell: renderActiveCell,
            filterMethod: basicFilter,
            Filter: SelectWithAllTrueFalse,
        },
        {
            Header: 'Częstotliwość',
            accessor: 'delay',
            filterMethod: filterWithAllAndCustom,
            Filter: renderDelayCell,
        },
        {
            Header: 'Ostatnie uruchomienie',
            accessor: 'lastRun',
        },
        {
            Header: 'Kolejne uruchomienie',
            accessor: 'nextRun',
        },
        {
            header: 'Actions',
            id: 'actions',
            filterable: false,
            sortable: false,
            Cell: ({ row }) => renderButtons(row, onDelete),
        },
    ];

    return <Table data={data} columns={columns} />;
};

AdminProjectCrawlersTable.propTypes = {
    data: PropTypes.array,
    onToggleCrawlerActive: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default AdminProjectCrawlersTable;
