import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import AccordionPanel, { useAccordionPanel } from '../../../../components/AccordionPanel';
import PageWithDynamicName from '../../../../components/Page/PageWithDynamicName';
import RefreshButton from '../../../../components/RefreshButton';
import { getProjectActionCreator } from '../../../../domain/project/ProjectActions';
import AssignUsers from '../AssignUsers/AssignUsers';
import ManageProjectCrawlers from '../Crawlers/Manage/ManageProjectCrawlers';
import EditProject from '../Edit/Description/EditProjectDescription';
import EditProjectKeywords from '../Edit/Keyword/AddProjectKeywords';
import ProjectTextsTable from '../Texts/ProjectTextsTable';

const SECTIONS = {
    EDIT: 'edit',
    ADD_USER: 'addUser',
    PROJECT_TEXTS: 'texts',
    KEYWORDS: 'keywords',
    CRAWLERS: 'crawlers',
};

const ProjectPage = (props) => {
    const { projectId } = useParams();
    const [refresh, setRefresh] = useState({});
    const commonProps = useAccordionPanel('PROJECT_PAGE');
    const dispatch = useDispatch();
    const project = useSelector(({ projectReducer }) => projectReducer.project);
    const basicProps = { ...props, projectId, refresh };

    useEffect(() => {
        dispatch(getProjectActionCreator(projectId));
    }, [dispatch, projectId]);

    return (
        <PageWithDynamicName name="Edytuj projekt" dynamicElement={project}>
            <RefreshButton onClick={() => setRefresh({})} />

            <AccordionPanel text="Edytuj project" icon="fas fa-pencil-alt" id={SECTIONS.EDIT} {...commonProps}>
                <EditProject {...basicProps} />
            </AccordionPanel>

            <AccordionPanel text="Dostęp" icon="fa fa-users" id={SECTIONS.ADD_USER} {...commonProps}>
                <AssignUsers {...basicProps} />
            </AccordionPanel>

            <AccordionPanel
                text={`Pobrane teksty (${project && project.count})`}
                icon="fas fa-file-alt"
                id={SECTIONS.PROJECT_TEXTS}
                {...commonProps}
            >
                <ProjectTextsTable {...basicProps} />
            </AccordionPanel>

            <AccordionPanel text="Crawlery" icon="fa fa-bug" id={SECTIONS.CRAWLERS} {...commonProps}>
                <ManageProjectCrawlers {...basicProps} />
            </AccordionPanel>

            <AccordionPanel text="Edytuj słowa kluczowe" icon="fa fa-key" id={SECTIONS.KEYWORDS} {...commonProps}>
                <EditProjectKeywords parentUrl="/projects/manage" {...basicProps} />
            </AccordionPanel>
        </PageWithDynamicName>
    );
};

export default ProjectPage;
