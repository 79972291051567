import { CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'reactstrap';
import { reduxForm } from 'redux-form';

import { FormButtons } from '../../../../../components/Form/FormButtons/FormButtons';
import EditRowForm from './EditRowForm';

const validate = (values) => {
    const errors = {};

    if (!values.term) {
        errors.term = 'To pole nie może być puste';
    }

    return errors;
};

let EditOneElementPopup = ({ onClose, onSubmit, show, handleSubmit, ...props }) => (
    <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
        <CModal title="Edytuj dane" color="primary" show={show} centered size="xl" onClose={onClose}>
            <CModalHeader>
                <h4>Edytuj dane</h4>
            </CModalHeader>

            <CModalBody>
                <EditRowForm {...props} />
            </CModalBody>

            <CModalFooter>
                <FormButtons onClose={onClose} />
            </CModalFooter>
        </CModal>
    </Form>
);

EditOneElementPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    ...EditRowForm.propTypes,
};

EditOneElementPopup = reduxForm({
    form: 'editOneElementPopupForm',
    enableReinitialize: true,
    validate,
})(EditOneElementPopup);

export default EditOneElementPopup;
