import * as React from 'react';
import { Col, Row } from 'reactstrap';

export const FormContainer = (props) => {
    return (
        <Row>
            <Col>{props.children}</Col>
        </Row>
    );
};
