import { CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import React from 'react';
import { Button, Form, Spinner } from 'reactstrap';
import { reduxForm } from 'redux-form';

import CloseButton from '../../../../components/CloseButton';
import ListSelect from './ListSelect';

let AddListModal = ({ handleSubmit, onSubmit, toggle, showModal, name, data }) => (
    <CModal title="Dodaj listę" color="primary" show={showModal} centered size="xl" onClose={toggle}>
        <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <CModalHeader>
                <h4>Dodaj listę</h4>
            </CModalHeader>

            <CModalBody>
                <div className="mb-30 text-primary text-left">
                    <strong>Uwaga! </strong>Listy specyficzne nadpisują listy generyczne.
                </div>

                {data ? <ListSelect data={data} name={name} /> : <Spinner />}
            </CModalBody>

            <CModalFooter>
                <CloseButton onClose={toggle} />
                <Button color="primary" type="submit">
                    <i className="fa fa-plus mr-2" /> Dodaj
                </Button>
            </CModalFooter>
        </Form>
    </CModal>
);

AddListModal = reduxForm({})(AddListModal);

export default AddListModal;
