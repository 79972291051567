import React from 'react';
import useSWR from 'swr';

import Page from '../../../../../components/Page';
import AddButton from '../../../../../customComponents/AddButton';
import { API_URL } from '../../../../../environment';
import { REFRESH_0, useFetcher } from '../../../../../infrastructure/api/tools';
import ListWithTermTable from './ListWithTermTable';

const ListWithTermAllPage = ({ type, webUrl, onDeleteList, title }) => {
    const fetcher = useFetcher();
    const { data, mutate } = useSWR(`${API_URL}/lists/${type}`, fetcher, REFRESH_0);

    return (
        <Page name={title}>
            <AddButton link={`${webUrl}/add`}>Nowa lista</AddButton>
            <ListWithTermTable data={data} mutate={mutate} type={type} webUrl={webUrl} onDeleteList={onDeleteList} />
        </Page>
    );
};

export default ListWithTermAllPage;
