import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import Page from '../../../../components/Page';
import AddButton from '../../../../customComponents/AddButton';
import {
    deleteUserAndRefreshUsersActionCreator,
    getUsersActionCreator,
    toggleUserEnabledAndRefreshUsersActionCreator,
} from '../../../../domain/user/UserActions';
import UsersTable from './UsersTable';

const ManageUsers = ({ name }) => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.userReducer.users);

    useEffect(() => {
        dispatch(getUsersActionCreator());
    }, [dispatch]);

    const handleDeleteUser = (id, login) => dispatch(deleteUserAndRefreshUsersActionCreator(id, login));

    const handleToggleUserEnabled = (id) => dispatch(toggleUserEnabledAndRefreshUsersActionCreator(id));

    return (
        <Page name={name}>
            <Row className="pb-2">
                <Col>
                    <AddButton link="/users/add">Nowy użytkownik</AddButton>
                </Col>
            </Row>
            <UsersTable data={users} onDeleteUser={handleDeleteUser} onToggleUserEnabled={handleToggleUserEnabled} />
        </Page>
    );
};

export default ManageUsers;
