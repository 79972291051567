import '@fortawesome/fontawesome-free/css/all.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './scss/style.scss';

// Temp fix for reactstrap
// import './scss/core/_dropdown-menu-right.scss';
import jwtDecode from 'jwt-decode';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { LOGIN_SUCCESS, refreshTokenActionCreator } from './domain/security/AuthenticationActions';
import {
    withAnonymous,
    withAuthorize,
} from './domain/security/AutheticationAwareComponentWrapper/AutheticationAwareComponentWrapper';
import { store } from './store';
import Login from './views/Login/Login';
import Main from './views/Main/Main';

const jwt = localStorage.getItem('jwt');

if (jwt) {
    store.dispatch(refreshTokenActionCreator(jwt));
    store.dispatch({ type: LOGIN_SUCCESS, jwt: jwtDecode(jwt), jwtEncoded: jwt });
}

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <Switch>
                <Route exact path="/login" name="Logowanie" component={withAnonymous(Login)} />
                <Route path="/" name="Home" component={withAuthorize(Main)} />
            </Switch>
        </HashRouter>
    </Provider>,
    document.getElementById('root'),
);
