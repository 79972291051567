export const commonFieldValidator = (requiredFields) => (values) => {
    const errors = {};

    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Pole wymagane';
        }
    });

    return errors;
};

export const nameFieldValidator = commonFieldValidator(['name']);

export const nameAndDescriptionValidator = commonFieldValidator(['name', 'description']);
