const GREEN = '#56ac46';
const GREY = '#c2cfd6';
const WHITE = '#fff';
const GREEN_LIGHT = 'rgba(86, 172, 70, 0.25)';

const customStyles = {
    control: (base, state) => ({
        ...base,
        cursor: 'pointer',
        borderRadius: '0',
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(86, 172, 70, 0.25)' : 'none',
        borderColor: state.isFocused ? GREEN : GREY,
        '&:hover': { borderColor: state.isFocused ? '#a4d69b' : GREY },
    }),
    option: (provided, state) => ({
        ...provided,
        padding: '10px 12px',
        cursor: 'pointer',
        '&:hover': { backgroundColor: GREEN, color: WHITE },
        backgroundColor: state.isSelected ? GREEN_LIGHT : WHITE,
        color: '#000',
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
    }),
};

export default customStyles;
