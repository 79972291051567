import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import AllActionButtons from '../../../../components/Table/ActionButtons/AllButtons';
import Table from '../../../../components/Table/Table';

const ClientsTable = ({ data, onDelete }) => {
    const history = useHistory();

    const renderActionCell = ({ original }) => (
        <AllActionButtons
            onEdit={() => history.push(`/clients/edit/${original.id}`)}
            onDelete={() => onDelete(original.id, original.login)}
            withText={false}
        />
    );

    const columns = [
        {
            Header: 'Login',
            accessor: 'login',
        },
        {
            Header: 'Rola',
            accessor: 'roles',
        },
        {
            Header: 'Utworzony',
            accessor: 'created',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: renderActionCell,
        },
    ];

    return <Table data={data} columns={columns} />;
};

ClientsTable.propTypes = {
    onDelete: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
};

export default ClientsTable;
