import { CCard, CCardBody, CCardFooter, CCardHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../components/Form/FormGroup/FormGroup';
import SubmittingAware from '../../../../components/SubmittingAware/SubmittingAware';

const validator = (values) => {
    const errors = {};
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Nieprawidłowy adres email';
    }
    if (values.password && values.repeatPassword && !(values.password === values.repeatPassword)) {
        errors.repeatPassword = 'Hasła muszą być takie same';
    }
    return errors;
};

class ManageProfileForm extends React.Component {
    static contextTypes = {
        router: PropTypes.object,
    };

    UNSAFE_componentWillMount() {
        this.props.fetchData(this.props.resourceId);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.submitting && !nextProps.submitting) {
            this.props.fetchData(this.props.resourceId);
        }
    }

    render() {
        const { handleSubmit, onSubmit, pristine, reset, submitting } = this.props;
        return (
            <Form onSubmit={handleSubmit(onSubmit)} action="" className="form-horizontal">
                <CCard>
                    <CCardHeader>
                        <strong>Zarządzaj profilem</strong>
                    </CCardHeader>
                    <CCardBody>
                        <Field component={TextFormGroup} name="email" placeholder="Email" type="email" />
                        <Field
                            component={TextFormGroup}
                            name="password"
                            placeholder="Zmień hasło"
                            type="password"
                            help="min. 8 znaków"
                        />
                        <Field
                            component={TextFormGroup}
                            name="repeatPassword"
                            placeholder="Powtórz nowe hasło"
                            type="password"
                        />
                    </CCardBody>
                    <CCardFooter>
                        <FormButtons pristine={pristine} submitting={submitting} reset={reset} />
                    </CCardFooter>
                </CCard>
            </Form>
        );
    }
}

ManageProfileForm.propTypes = {
    resourceId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
};

ManageProfileForm = reduxForm({
    form: 'manageProfile',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate: validator,
})(SubmittingAware(ManageProfileForm));

export default connect((state) => ({
    initialValues: state.userReducer.user,
}))(ManageProfileForm);
