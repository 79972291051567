import React, { useEffect } from 'react';
import { success } from 'react-notification-system-redux';
import { useDispatch } from 'react-redux';
import { Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import useSWR from 'swr';

import { FormButtons } from '../../../../components/Form/FormButtons/FormButtons';
import ReactSelect from '../../../../components/Form/ReactSelect';
import { CenteredSpinner } from '../../../../components/Spinner/Spinner';
import { setListAsGeneric } from '../../../../domain/list/ListApiClient';
import { API_URL } from '../../../../environment';
import handleError from '../../../../infrastructure/api/ApiErrorHandler';
import { useFetcher, useToken } from '../../../../infrastructure/api/tools';
import { commonFieldValidator } from '../../../../tools/validatorHelpers';

const DEFAULT_INITIAL_VALUE = { value: '', label: '' };

const validate = commonFieldValidator(['value']);

export const prepareOption = (el) => (el ? { label: el.name, value: `${el.id}` } : DEFAULT_INITIAL_VALUE);

const prepareOptions = (data) => data.map(prepareOption);

let ListSelect = ({ title, icon, type, handleSubmit, placeholder, refresh, initialize, initialValues }) => {
    const fetcher = useFetcher();
    const token = useToken();
    const dispatch = useDispatch();
    const { data } = useSWR(`${API_URL}/lists/${type}`, fetcher, { refreshInterval: 0 });

    const onSubmit = async ({ selectedList: { value: id } }) => {
        try {
            await setListAsGeneric({ token, type, id });
            dispatch(success({ message: 'Ustawiono listę generyczną.' }));
        } catch (e) {
            handleError(e, dispatch);
        }

        await refresh();
    };

    useEffect(() => initialize(initialValues), []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <h4>
                <i className={`${icon} mr-3`} />
                {title}
            </h4>

            {data ? (
                <>
                    <div className="mt-3">
                        <Field
                            component={ReactSelect}
                            options={prepareOptions(data)}
                            name="selectedList"
                            dataKey="value"
                            placeholder={placeholder}
                            withLabel={false}
                        />
                    </div>
                    <FormButtons showClose={false} />
                </>
            ) : (
                <CenteredSpinner />
            )}
        </Form>
    );
};

ListSelect = reduxForm({ validate, enableReinitialize: true })(ListSelect);

export default ListSelect;
