import React from 'react';

import AccordionPanel, { useAccordionPanel } from '../../../../../../components/AccordionPanel';
import { ICONS } from '../../../../../../components/Sidebar/_nav';
import { LIST_TYPES } from '../../../../../../consts/listTypes';
import { commonFieldValidator } from '../../../../../../tools/validatorHelpers';
import ListPanel from './ListPanel';

const listTypesMapped = new Map([
    [LIST_TYPES.ASPECT_SYNONYMS, 'aspectSynonyms'],
    [LIST_TYPES.ASPECTS_TO_IGNORE, 'aspectsToIgnore'],
    [LIST_TYPES.OPINION_SYNONYMS, 'opinionSynonyms'],
    [LIST_TYPES.STOP_LISTS, 'sentimentWordsToIgnore'],
]);

const ListsAccordions = ({ SECTIONS, basicProps, data, mutate }) => {
    const commonProps = useAccordionPanel('USER_REPORT_PAGE');

    const getListProps = (type) => ({
        onSubmit: () => {},
        initialValues: { [type]: '' },
        type,
        validate: commonFieldValidator([type]),
        data: data[listTypesMapped.get(type)],
        mutate,
    });

    return (
        <>
            <AccordionPanel
                text="Synonimy aspektów"
                icon={ICONS.ASPECT_SYNONYMS}
                id={SECTIONS.ASPECT_SYNONYMS}
                {...commonProps}
            >
                <ListPanel {...basicProps} {...getListProps(LIST_TYPES.ASPECT_SYNONYMS)} />
            </AccordionPanel>

            <AccordionPanel
                text="Synonimy opinii"
                icon={ICONS.OPINION_SYNONYMS}
                id={SECTIONS.OPINION_SYNONYMS}
                {...commonProps}
            >
                <ListPanel {...basicProps} {...getListProps(LIST_TYPES.OPINION_SYNONYMS)} />
            </AccordionPanel>

            <AccordionPanel
                text="Aspekty do ignorowania"
                icon={ICONS.ASPECTS_TO_IGNORE}
                id={SECTIONS.ASPECTS_TO_IGNORE}
                {...commonProps}
            >
                <ListPanel {...basicProps} {...getListProps(LIST_TYPES.ASPECTS_TO_IGNORE)} />
            </AccordionPanel>

            <AccordionPanel text="Stoplisty" icon={ICONS.STOP_LISTS} id={SECTIONS.STOP_LISTS} {...commonProps}>
                <ListPanel {...basicProps} type={LIST_TYPES.STOP_LISTS} {...getListProps(LIST_TYPES.STOP_LISTS)} />
            </AccordionPanel>
        </>
    );
};

export default ListsAccordions;
