import React from 'react';
import { useHistory } from 'react-router';

import { FormContainer } from '../../../../../components/Form/FormContainer/FormContainer';
import Page from '../../../../../components/Page';
import * as ListApiClient from '../../../../../domain/list/ListApiClient';
import { useListHandleRequest } from '../../../../../infrastructure/api/hooks';
import AddListPageForm from './AddListPageForm';

const AddListPage = ({ type, title }) => {
    const history = useHistory();

    const onSubmitAction = useListHandleRequest(type, ListApiClient.add, () => {}, 'Lista została dodana');

    const onSubmit = async (values) => {
        values.type = type;
        await onSubmitAction(values);
        history.goBack();
    };

    return (
        <Page name={`Dodaj listę (${title})`}>
            <FormContainer>
                <AddListPageForm onSubmit={onSubmit} />
            </FormContainer>
        </Page>
    );
};

export default AddListPage;
