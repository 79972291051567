import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ManageProfile from './Manage/ManageProfile';

class Profile extends Component {
    render() {
        return (
            <Switch>
                <Route
                    path="/profile/manage"
                    render={(props) => <ManageProfile name="Zarządzaj profilem" {...props} />}
                />
                <Redirect from="/profile" to="/profile/manage" />
            </Switch>
        );
    }
}

export default Profile;
