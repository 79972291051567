import { MANAGE_PROFILE } from './ProfileActions';

function profileReducer(state = {}, action) {
    switch (action.type) {
        case MANAGE_PROFILE:
            return {
                ...state,
            };
        default:
            console.debug('profileReducer :: hit default', action.type);
            return state;
    }
}

export default profileReducer;
