import React from 'react';

import style from './style.module.scss';

const EVENTS_TYPES = { ERROR: 'ERROR', WARNING: 'WARNING', INFO: 'INFO', SUCCESS: 'SUCCESS' };

const TYPE_CLASSES = {
    [EVENTS_TYPES.ERROR]: style.error,
    [EVENTS_TYPES.WARNING]: style.warning,
    [EVENTS_TYPES.INFO]: style.info,
    [EVENTS_TYPES.SUCCESS]: style.success,
};

const ProcessRow = ({ description, occurredOn, level }) => (
    <div key={description} className={`${style.log} ${TYPE_CLASSES[level]}`}>
        <div className={style.date}>{occurredOn}</div>
        <div>{description}</div>
    </div>
);

export default ProcessRow;
