import { GET_ALL_CRAWLERS, GET_PROJECT_CRAWLERS } from './CrawlerActions';

function crawlerReducer(state = {}, action) {
    switch (action.type) {
        case GET_PROJECT_CRAWLERS:
            return {
                ...state,
                projectCrawlers: action.projectCrawlers,
            };
        case GET_ALL_CRAWLERS:
            return {
                ...state,
                crawlers: action.crawlers,
            };
        default:
            console.debug('crawlerReducer :: hit default', action.type);
            return state;
    }
}

export default crawlerReducer;
