import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

import { Switch } from '../../../../components/Switch/Switch';
import Table from '../../../../components/Table/Table';
import { basicFilter, SelectWithAllTrueFalse } from '../../../../tools/tableHelpers';

const AssignUsersTable = ({ onToggleUserAssigned, data }) => {
    const renderAccessCell = (row) => (
        <Switch value={row.value} onToggle={() => onToggleUserAssigned(row.original.id, !row.value)} />
    );

    const columns = [
        {
            Header: 'Dostęp',
            accessor: 'assigned',
            Cell: renderAccessCell,
            filterMethod: basicFilter,
            Filter: SelectWithAllTrueFalse,
        },
        {
            Header: 'Login',
            accessor: 'login',
        },
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Rola',
            accessor: 'role',
        },
        {
            Header: 'Utworzony',
            accessor: 'created',
        },
    ];

    return (
        <Row>
            <Col>
                <Table data={data} columns={columns} />
            </Col>
        </Row>
    );
};

AssignUsersTable.propTypes = {
    onToggleUserAssigned: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    data: PropTypes.object,
};

export default AssignUsersTable;
