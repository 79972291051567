import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useSWR from 'swr';

import { FormContainer } from '../../../../components/Form/FormContainer/FormContainer';
import PageWithDynamicName from '../../../../components/Page/PageWithDynamicName';
import { CenteredSpinner } from '../../../../components/Spinner/Spinner';
import { editClientActionCreator } from '../../../../domain/client/ClientActions';
import { API_URL } from '../../../../environment';
import { useFetcher } from '../../../../infrastructure/api/tools';
import EditClientForm from './EditClientForm';

const prepareInitialValues = (client) => ({ ...client, password: '', repeatPassword: '', role: client.roles[0] });

const EditClient = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const fetcher = useFetcher();
    const history = useHistory();

    const { data: client } = useSWR(`${API_URL}/clients/${id}`, fetcher, {
        refreshInterval: 0,
    });

    const onSubmitEditClientForm = async (data) => {
        const body = {
            login: data.login,
            password: data.password ? data.password : null,
            role: data.roles[0].replace('ROLE_', ''),
        };

        dispatch(editClientActionCreator(body, id));
        history.goBack();
    };

    return (
        <PageWithDynamicName name="Edytuj klienta" field="login" dynamicElement={client}>
            <FormContainer>
                {client ? (
                    <EditClientForm
                        onSubmit={onSubmitEditClientForm}
                        resourceId={id}
                        initialValues={prepareInitialValues(client)}
                    />
                ) : (
                    <CenteredSpinner />
                )}
            </FormContainer>
        </PageWithDynamicName>
    );
};

EditClient.propTypes = {
    router: PropTypes.object,
    loggedInClientId: PropTypes.number.isRequired,
    name: PropTypes.string,
};

export default EditClient;
