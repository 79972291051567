import * as _ from 'lodash';

export class Jwt {
    sub;
    audience;
    created;
    authority;
    id;
    exp;

    constructor(data = {}) {
        Object.assign(this, data);
    }
}

export class Authority {
    authority = [];

    constructor(data = {}) {
        Object.assign(this, data);
    }

    includeAnyOf(userAuthorities) {
        if (!this.authority) {
            return false;
        }
        return (
            _.intersection(
                this.authority.map((it) => it.authority),
                userAuthorities,
            ).length > 0
        );
    }
}
