import './style.scss';

import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Accordion = ({ isOpen, children, onClick, className }) => {
    const classNames = cn('Accordion', 'mt-20', { ' Accordion__children': isOpen }, className);

    return (
        <div onClick={onClick} className={classNames}>
            <div>{children}</div>
            {isOpen ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}
        </div>
    );
};

Accordion.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
};

export default Accordion;
