import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from '../../../../components/Table/ActionButtons/DeleteButton';
import Table from '../../../../components/Table/Table';

const AddedListsTable = ({ data, onDeleteAction }) => {
    const renderActionCell = ({ original }) => (
        <div className="text-right">
            <DeleteButton onDelete={() => onDeleteAction(original)} />
        </div>
    );

    const columns = [
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Typ',
            accessor: 'type',
        },
        {
            Header: 'Utworzono',
            accessor: 'createdAt',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: renderActionCell,
        },
    ];

    return <Table data={data} columns={columns} />;
};

AddedListsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onDeleteAction: PropTypes.func.isRequired,
};

export default AddedListsTable;
