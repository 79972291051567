import { CCard, CCardBody, CCardFooter } from '@coreui/react';
import React from 'react';
import { Form, Spinner } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import useSWR from 'swr';

import { FormButtons } from '../../../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../../../components/Form/FormGroup/FormGroup';
import { API_URL } from '../../../../../../environment';
import { useFetcher } from '../../../../../../infrastructure/api/tools';
import ProjectSelect from './ProjectSelect';

const DATE_REGEXP = /[0-9]{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}/g;

const validator = (values) => {
    const errors = {};

    if (values.publishedFrom && !DATE_REGEXP.test(values.publishedFrom)) {
        errors.publishedFrom = 'Format daty w formacie yyyy-mm-dd';
    }

    if (values.publishedTo && !DATE_REGEXP.test(values.publishedTo)) {
        errors.publishedTo = 'Format daty w formacie yyyy-mm-dd';
    }

    if (!values.projectId) {
        errors.projectId = 'Pole nie może być puste';
    }

    return errors;
};

let SettingsPanel = ({ handleSubmit, onSubmit }) => {
    const fetcher = useFetcher();
    const { data } = useSWR(`${API_URL}/projects`, fetcher);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <CCard>
                <CCardBody>
                    <Field
                        component={TextFormGroup}
                        name="publishedFrom"
                        placeholder="Data publikacji od: yyyy-mm-dd"
                        type="text"
                    />

                    <Field
                        component={TextFormGroup}
                        name="publishedTo"
                        placeholder="Data publikacji do: yyyy-mm-dd"
                        type="text"
                    />

                    {data ? <ProjectSelect data={data} /> : <Spinner />}
                </CCardBody>

                <CCardFooter>
                    <FormButtons showClose={false} />
                </CCardFooter>
            </CCard>
        </Form>
    );
};

SettingsPanel = reduxForm({
    form: 'settingsEdit',
    enableReinitialize: true,
    validate: validator,
})(SettingsPanel);

export default SettingsPanel;
