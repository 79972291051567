import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { success } from '../notifications/Notifications';
import handleError from './ApiErrorHandler';
import { useToken } from './tools';

export const useListHandleRequest = (type, callback, refresh, successMessage) => {
    const { id } = useParams();
    const token = useToken();
    const dispatch = useDispatch();

    const action = async (data, props) => {
        try {
            await callback({ data, token, type, id, ...data, ...props });
            await refresh();
            dispatch(success({ message: successMessage }));
        } catch (e) {
            handleError(e, dispatch);
        }
    };

    return action;
};
