export const SECTIONS = {
    LIST: 'LIST',
    NAME: 'NAME',
};

export const LIST_TYPES = {
    ASPECT_SYNONYMS: 'ASPECT_SYNONYMS',
    STOP_LISTS: 'SENTIMENT_WORDS_TO_IGNORE',
    ASPECTS_TO_IGNORE: 'ASPECTS_TO_IGNORE',
    OPINION_SYNONYMS: 'OPINION_SYNONYMS',
};
