import React from 'react';
import { Field } from 'redux-form';

import { TextFormGroup } from '../../../../../../components/Form/FormGroup/FormGroup';

const ProjectSelect = ({ data }) => (
    <Field component={TextFormGroup} name="projectId" type="select" placeholder="Projekt">
        <option value="">Projekt...</option>
        {data.map((el) => (
            <option key={el.id} value={el.id}>
                {el.name}
            </option>
        ))}
    </Field>
);

export default ProjectSelect;
