import {
    CButton,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
} from '@coreui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import CloseButton from '../../../../../components/CloseButton';
import { rebuildReport } from '../../../../../domain/report/ReportApiClients';
import handleError from '../../../../../infrastructure/api/ApiErrorHandler';
import { useToken } from '../../../../../infrastructure/api/tools';
import { success } from '../../../../../infrastructure/notifications/Notifications';

const ReportModal = ({ show, onClose, actionAfterRebuild, reportId }) => {
    const token = useToken();
    const dispatch = useDispatch();

    const { register, handleSubmit } = useForm();

    const onSubmitAction = async ({ directory }) => {
        try {
            const body = { directory };
            const { data: result } = await rebuildReport(reportId, token, body);
            dispatch(success({ message: 'Rozpoczęcie generowania raportu' }));
            actionAfterRebuild && actionAfterRebuild(result);
        } catch (e) {
            handleError(e, dispatch);
        } finally {
            onClose();
        }
    };

    return (
        <CModal title="Generuj raport" color="primary" show={show} centered size="xl" onClose={onClose}>
            <CModalHeader>
                <h4>Generuj raport</h4>
            </CModalHeader>

            <CForm onSubmit={handleSubmit(onSubmitAction)}>
                <CModalBody>
                    <CFormGroup>
                        <CLabel className="mb-2">Możesz podać ścieżkę katalogu reguł</CLabel>
                        <CInput placeholder="ściezka do katalogu" {...register('directory')} />
                    </CFormGroup>
                </CModalBody>

                <CModalFooter>
                    <CButton color="primary" type="submit">
                        <i className="fas fa-radiation-alt mr-2" /> Generuj
                    </CButton>
                    <CloseButton onClose={onClose} color="primary" />
                </CModalFooter>
            </CForm>
        </CModal>
    );
};

export const useReportModal = () => {
    const [showModal, setShowModal] = useState(false);

    const onCloseModal = () => setShowModal(false);

    const onClickGenerate = () => setShowModal(true);

    return { showModal, onCloseModal, onClickGenerate };
};

export default ReportModal;
