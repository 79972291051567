import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useSWR from 'swr';

import PageWithDynamicName from '../../../../../components/Page/PageWithDynamicName';
import { CenteredSpinner } from '../../../../../components/Spinner/Spinner';
import * as CrawlerApiClient from '../../../../../domain/crawler/CrawlerApiClient';
import { delaysArray } from '../../../../../domain/crawler/Delay';
import { API_URL } from '../../../../../environment';
import handleError from '../../../../../infrastructure/api/ApiErrorHandler';
import { useFetcher } from '../../../../../infrastructure/api/tools';
import ParametersTable from '../Manage/ParametersTable';
import Parameters from './elements/Parameters';
import SingleValueEditBox from './SingleValueEditBox';

const REFRESH_MS = 10;

const parseDate = (date) => {
    const parsed = new Date(date);
    return `${parsed.toLocaleDateString()}, ${parsed.toLocaleTimeString()}`;
};

const EditCrawler = ({ parentUrl }) => {
    const fetcher = useFetcher();
    const { crawlerId, projectId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { jwtEncoded: token } = useSelector(({ authenticationReducer }) => authenticationReducer);
    const { data, error, mutate } = useSWR(`${API_URL}/projects/${projectId}/crawlers/${crawlerId}`, fetcher, {
        refreshInterval: REFRESH_MS,
    });

    if (error) {
        handleError(error, dispatch, {
            history: history,
            parentUrl,
        });
    }

    const editCrawler = async (name, value) => {
        await CrawlerApiClient.editCrawler({ [name]: value }, crawlerId, token);
        await mutate();
    };

    const editCrawlerParameter = async (name, value) => {
        await CrawlerApiClient.editCrawler({ parameter: { key: name, value: value } }, crawlerId, token);
        await mutate();
    };

    return data ? (
        <PageWithDynamicName name="Edytuj Crawler" dynamicElement={data}>
            <CCard>
                <CCardHeader>Informacje</CCardHeader>
                <CCardBody>
                    <div>
                        <span className="mr-2"> Aktywność:</span>
                        {data.active ? (
                            <i className="fas fa-check text-primary" />
                        ) : (
                            <i className="fa fa-times text-danger" />
                        )}
                    </div>
                    <div className="mt-6">
                        Następne uruchomienie: <strong className="ml-2">{parseDate(data.nextRun)}</strong>
                    </div>
                </CCardBody>
            </CCard>

            <CCard>
                <CCardHeader>Ustawienia</CCardHeader>
                <CCardBody>
                    <SingleValueEditBox
                        name="name"
                        label="Nazwa"
                        initialValue={data.name}
                        sendData={editCrawler}
                        type="text"
                    />
                    <SingleValueEditBox
                        name="delay"
                        label="Częstotliwość"
                        initialValue={data.delay}
                        type="select"
                        sendData={editCrawler}
                        options={delaysArray}
                    />
                </CCardBody>
            </CCard>

            <CCard>
                <CCardHeader>Parametry</CCardHeader>
                <CCardBody>
                    <Parameters data={data} editCrawlerParameter={editCrawlerParameter} />
                </CCardBody>
            </CCard>

            <div className="mt-40">
                <ParametersTable crawlerUserParameters={data.crawlerUserParameters} lastRuns={data.lastRuns} />
            </div>
        </PageWithDynamicName>
    ) : (
        <CenteredSpinner />
    );
};

EditCrawler.propTypes = {
    parentUrl: PropTypes.string.isRequired,
};

export default EditCrawler;
