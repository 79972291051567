import PropTypes from 'prop-types';
import * as React from 'react';
import { Col, Row } from 'reactstrap';

import EditButton from '../../../../../components/Table/ActionButtons/EditButton';

const PropertyView = ({ name, value, onEdit }) => (
    <Row className="justify-content-center mt-2">
        <Col md="4">
            <span className="align-middle font-weight-bold">{name} </span>
        </Col>
        <Col md="6">
            <span className="align-middle">{value}</span>
        </Col>
        <Col md="2" className="text-right">
            <EditButton onEdit={onEdit} withText={false} withNoMargin />
        </Col>
    </Row>
);

PropertyView.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default PropertyView;
