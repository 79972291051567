import axios from 'axios';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';

export async function login(credentials) {
    return axios.post(`${API_URL}/auth`, credentials, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export async function refreshToken(token) {
    return axios.get(`${API_URL}/auth/refresh`, {
        headers: createHeaders(token),
    });
}
