import axios from 'axios';
import JsFileDownloader from 'js-file-downloader';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';

export async function add(data, token) {
    return axios.post(`${API_URL}/reports`, data, {
        headers: createHeaders(token),
    });
}

export async function edit(data, id, token) {
    return axios.post(`${API_URL}/reports/${id}`, data, {
        headers: createHeaders(token),
    });
}

export async function getReports(token) {
    return axios.get(`${API_URL}/reports`, {
        headers: createHeaders(token),
    });
}

export async function getReport(id, token) {
    return axios.get(`${API_URL}/reports/${id}`, {
        headers: createHeaders(token),
    });
}

export async function toggleReportActive(id, token) {
    return axios.post(`${API_URL}/reports/${id}/toggleActive`, null, {
        headers: createHeaders(token),
    });
}

export async function assignClient(clientId, reportId, token) {
    return axios.post(
        `${API_URL}/reports/${reportId}/assignedClients`,
        { clientId },
        {
            headers: createHeaders(token),
        },
    );
}

export async function revokeClient(clientId, reportId, token) {
    return axios.delete(`${API_URL}/reports/${reportId}/assignedClients/${clientId}`, {
        headers: createHeaders(token),
    });
}

export async function deleteReport(id, token) {
    return axios.delete(`${API_URL}/reports/${id}`, {
        headers: createHeaders(token),
    });
}

export const rebuildReport = async (reportId, token, body = {}) =>
    axios.post(`${API_URL}/reports/${reportId}/rebuild`, body, { headers: createHeaders(token) });

export const stopRebuildProcess = async (processId, token) =>
    axios.post(`${API_URL}/reports/rebuild/${processId}/cancel`, {}, { headers: createHeaders(token) });

export const saveSettings = async (reportId, token, body) =>
    axios.post(`${API_URL}/reports/${reportId}/settings`, body, { headers: createHeaders(token) });

export const downloadLogsFile = (token, processId) =>
    new JsFileDownloader({
        url: `${API_URL}/reports/rebuild/${processId}/logs`,
        headers: [{ name: 'Authorization', value: `Bearer ${token}` }],
    });

export const addListToReport = async (reportId, token, type, body) =>
    axios.post(`${API_URL}/reports/${reportId}/lists/${type}`, body, { headers: createHeaders(token) });

export const deleteListFromReport = async (reportId, token, type, listId) =>
    axios.delete(`${API_URL}/reports/${reportId}/lists/${type}/${listId}`, { headers: createHeaders(token) });
