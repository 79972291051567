import React from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';

import AccordionPanel, { useAccordionPanel } from '../../../../../components/AccordionPanel';
import Page from '../../../../../components/Page';
import { CenteredSpinner } from '../../../../../components/Spinner/Spinner';
import { API_URL } from '../../../../../environment';
import { REFRESH_0, useFetcher } from '../../../../../infrastructure/api/tools';
import { LIST_TYPES, SECTIONS } from '../../commons/consts';
import EditBasicInfo, { prepareBasicInfo } from '../../commons/EditBasicInfo';
import ListWithWordTable from './ListWithWordTable';

const ListWithWordPage = ({ type }) => {
    const commonProps = useAccordionPanel();
    const fetcher = useFetcher();
    const { id } = useParams();
    const { data, mutate } = useSWR(`${API_URL}/lists/${type}/${id}`, fetcher, REFRESH_0);

    return data ? (
        <Page name={data.name}>
            <AccordionPanel text="Edytuj nazwę" icon="fas fa-signature" id={SECTIONS.NAME} {...commonProps}>
                <EditBasicInfo mutate={mutate} type={LIST_TYPES.STOP_LISTS} initialValues={prepareBasicInfo(data)} />
            </AccordionPanel>

            <AccordionPanel text="Edytuj listę" icon="fas fa-pencil-alt" id={SECTIONS.EDIT} {...commonProps}>
                <ListWithWordTable data={data.words} mutate={mutate} type={type} />
            </AccordionPanel>
        </Page>
    ) : (
        <CenteredSpinner />
    );
};

export default ListWithWordPage;
