import { GET_CRAWLER_TEMPLATES } from './CrawlerTemplateActions';

function crawlerTemplateReducer(state = {}, action) {
    switch (action.type) {
        case GET_CRAWLER_TEMPLATES:
            return {
                ...state,
                templates: action.templates,
            };
        default:
            console.debug('crawlerTemplateReducer :: hit default', action.type);
            return state;
    }
}

export default crawlerTemplateReducer;
