import React from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { API_URL } from '../../environment';
import { useFetcher } from '../../infrastructure/api/tools';
import { CenteredSpinner } from '../Spinner/Spinner';
import { prepareEventMapping } from './helpers';
import SingleEvent from './SingleEvent';
import style from './style.module.scss';

const REFRESH_INTERVAL_MS = 5;

const EventPanel = ({ togglePanel }) => {
    const fetcher = useFetcher();
    const { data } = useSWR(`${API_URL}/events`, fetcher, {
        refreshInterval: REFRESH_INTERVAL_MS,
    });

    const colors = data ? prepareEventMapping(data) : {};

    return (
        <div className={style.panel}>
            <div className={style.close}>
                <svg className="c-icon" width="24" height="24" viewBox="0 0 24 24" onClick={togglePanel}>
                    {
                        /* eslint-disable */
                        <path d="M20.030 5.030l-1.061-1.061-6.97 6.97-6.97-6.97-1.061 1.061 6.97 6.97-6.97 6.97 1.061 1.061 6.97-6.97 6.97 6.97 1.061-1.061-6.97-6.97 6.97-6.97z" />
                        /* eslint-enable */
                    }
                </svg>
            </div>

            {data ? (
                data.map((el) => (
                    <SingleEvent type={el.type} data={el} key={el.eventId} className={colors[el.event.processId]} />
                ))
            ) : (
                <CenteredSpinner />
            )}

            <div className="text-right mr-18 mt-40">
                <Link to="/processes">Zobacz więcej procesów...</Link>
            </div>
        </div>
    );
};

export default EventPanel;
