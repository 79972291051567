function convertSorted(filter) {
    const { sorted } = filter;
    if (Array.isArray(sorted) && sorted.length) {
        const queryString = sorted
            .map((value) => `${value.id},${value.desc === false ? 'asc' : 'desc'}`)
            .reduce((total, current) => `${total}&${current}`);
        return { sort: queryString };
    } else {
        return null;
    }
}

function convertFiltered(filter) {
    const { filtered } = filter;
    if (Array.isArray(filtered) && filtered.length) {
        return filtered.map((value) => Object.assign({}, { [value.id]: value.value }));
    } else {
        return [];
    }
}

export function convertToParams(filter) {
    return Object.assign(
        {},
        { pageNumber: filter.page + 1 },
        { pageSize: filter.pageSize },
        convertSorted(filter),
        ...convertFiltered(filter),
    );
}
