import React from 'react';

import { convertCamelCaseToSpaces } from './helpers';
import style from './style.module.scss';

const Header = ({ isOpen, type, toggleIsOpen, data }) => (
    <>
        <div className={style.title} onClick={toggleIsOpen}>
            <strong>{convertCamelCaseToSpaces(type)}</strong>
            <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'} ml-10`} />
        </div>

        {data.event.sentimentReportName ? (
            <div className={style.info}>
                Raport:
                <strong> {data.event.sentimentReportName}</strong>
            </div>
        ) : null}
    </>
);

export default Header;
