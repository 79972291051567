import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { hasAuthority } from '../../../domain/security/AuthorityService/AuthorityService';
import { NavTile } from './NavTile';

export const NavTiles = (props) => {
    const { navItems, userAuthority } = props;
    const tiles = navItems
        .filter((item) => hasAuthority(userAuthority, item.authority))
        .map((item, index) => <NavTile key={index} navItem={item} title={item.name} description={item.description} />);

    return (
        <Card>
            <CardBody>
                <div className="dashboard-nav-wrapper">{tiles}</div>
            </CardBody>
        </Card>
    );
};
