import PropTypes from 'prop-types';
import React from 'react';

import { CenteredSpinner } from '../Spinner/Spinner';
import PageHeader from './elements/PageHeader';

const PageWithDynamicName = ({ name, children, dynamicElement, field = 'name' }) =>
    dynamicElement ? (
        <>
            {name && <PageHeader name={`${name}: ${dynamicElement[field]}`} />}
            {children}
        </>
    ) : (
        <CenteredSpinner />
    );

PageWithDynamicName.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    dynamicElement: PropTypes.object,
    field: PropTypes.string.isRequired,
};

export default PageWithDynamicName;
