import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { useFormEditPopup } from '../../../../../../components/hooks';
import { CenteredSpinner } from '../../../../../../components/Spinner/Spinner';
import AddButton from '../../../../../../customComponents/AddButton';
import { addListToReport, deleteListFromReport } from '../../../../../../domain/report/ReportApiClients';
import { API_URL } from '../../../../../../environment';
import handleError from '../../../../../../infrastructure/api/ApiErrorHandler';
import { useFetcher, useToken } from '../../../../../../infrastructure/api/tools';
import { success } from '../../../../../../infrastructure/notifications/Notifications';
import AddedListsTable from '../../../commons/AddedListsTable';
import AddListModal from '../../../commons/AddListsModal';

const ListPanel = ({ type, initialValues, validate, mutate, data = [], id }) => {
    const fetcher = useFetcher();
    const token = useToken();
    const { reportId } = useParams();
    const dispatch = useDispatch();
    const { data: availableLists } = useSWR(`${API_URL}/lists/${type}`, fetcher);
    const form = 'addListModalFormForEditReport' + type;

    const onSubmitAdd = async (values) => {
        try {
            await addListToReport(reportId, token, type, { listId: values[type] });
            dispatch(success({ message: 'Dodano listę' }));
            await mutate();
        } catch (e) {
            handleError(e, dispatch);
        }
    };

    const onDeleteAction = async (values) => {
        try {
            await deleteListFromReport(reportId, token, type, values.listId);
            dispatch(success({ message: 'Usunięto listę' }));
            await mutate();
        } catch (e) {
            handleError(e, dispatch);
        }
    };

    const props = useFormEditPopup('', onSubmitAdd, () => {}, form);

    return availableLists ? (
        <>
            <AddListModal
                {...props}
                data={availableLists}
                type={type}
                form={form}
                name={type}
                initialValues={initialValues}
                validate={validate}
                shouldError={() => true}
            />
            <AddButton onClick={props.toggle} />
            <AddedListsTable data={data} onDeleteAction={onDeleteAction} />
        </>
    ) : (
        <CenteredSpinner />
    );
};

export default ListPanel;
