import axios from 'axios';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';

export async function add(data, token) {
    return axios.post(`${API_URL}/users`, data, {
        headers: createHeaders(token),
    });
}

export async function edit(data, id, token) {
    return axios.put(`${API_URL}/users/${id}`, data, {
        headers: createHeaders(token),
    });
}

export async function getUsers(token) {
    return axios.get(`${API_URL}/users`, {
        headers: createHeaders(token),
    });
}

export async function getUser(id, token) {
    return axios.get(`${API_URL}/users/${id}`, {
        headers: createHeaders(token),
    });
}

export async function toggleUserEnabled(id, token) {
    return axios.post(`${API_URL}/users/${id}/toggleEnabled`, null, {
        headers: createHeaders(token),
    });
}

export async function deleteUser(id, token) {
    return axios.delete(`${API_URL}/users/${id}`, {
        headers: createHeaders(token),
    });
}
