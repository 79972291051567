import React from 'react';
import { withBreadcrumbs } from 'react-router-breadcrumbs-hoc';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const userRoutes = [
    { path: '/users', breadcrumb: 'Użytkownicy' },
    { path: '/users/add', breadcrumb: 'Dodaj' },
    { path: '/users/edit', breadcrumb: 'Edytuj' },
    { path: '/users/manage', breadcrumb: 'Zarządzaj' },
];

const eventsRoutes = [
    { path: '/processes', breadcrumb: 'Procesy' },
    { path: '/processes/manage', breadcrumb: 'Proces' },
];

const aspectSynonymsRoutes = [
    { path: '/aspectSynonyms', breadcrumb: 'Synonimy aspektów' },
    { path: '/aspectSynonyms/manage', breadcrumb: 'Lista synonimów aspektów' },
];

const opinionSynonymsRoutes = [
    { path: '/opinionSynonyms', breadcrumb: 'Synonimy opinii' },
    { path: '/opinionSynonyms/manage', breadcrumb: 'Lista synonimów opinii' },
];

const stopListsRoutes = [
    { path: '/stopLists', breadcrumb: 'Stoplisty' },
    { path: '/stopLists/manage', breadcrumb: 'Stoplista' },
];

const aspectsToIgnoreRoutes = [
    { path: '/aspectsToIgnore', breadcrumb: 'Listy aspektów do ignorowania' },
    { path: '/aspectsToIgnore/manage', breadcrumb: 'Lista aspektów do ignorowania' },
];

const clientRoutes = [
    { path: '/clients', breadcrumb: 'Klienci' },
    { path: '/clients/add', breadcrumb: 'Dodaj' },
    { path: '/clients/edit', breadcrumb: 'Edytuj' },
    { path: '/clients/manage', breadcrumb: 'Zarządzaj' },
];

const profileRoutes = [
    { path: '/profile', breadcrumb: 'Twój profil' },
    { path: '/profile/manage', breadcrumb: 'Zarządzaj' },
];

const projectRoutes = [
    { path: '/projects', breadcrumb: 'Projekty' },
    { path: '/projects/add', breadcrumb: 'Dodaj' },
    { path: '/projects/manage', breadcrumb: 'Zarządzaj' },
    { path: '/projects/:projectId', breadcrumb: 'Edytuj' },
    { path: '/projects/user/:projectId', breadcrumb: 'Edytuj' },
];

const reportsRoutes = [
    { path: '/reports', breadcrumb: 'Raporty' },
    { path: '/reports/add', breadcrumb: 'Dodaj' },
    { path: '/reports/manage', breadcrumb: 'Zarządzaj' },
    { path: '/reports/:projectId/edit', breadcrumb: 'Edytuj' },
    { path: '/reports/:projectId/assign', breadcrumb: 'Dodaj użytkowników' },
    { path: '/reports/:projectId/edit/description', breadcrumb: 'Edytuj opis' },
];

const crawlerRoutes = [
    { path: '/crawlers', breadcrumb: 'Crawlery' },
    { path: '/crawlers/manage', breadcrumb: 'Zarządzaj' },
];

const projectCrawlersRoutes = [
    { path: '/projects/:projectId/crawlers', breadcrumb: 'Crawlery' },
    { path: '/projects/:projectId/crawlers/manage', breadcrumb: 'Zarządzaj' },
    { path: '/projects/:projectId/crawlers/add', breadcrumb: 'Dodaj' },
    { path: '/projects/:projectId/crawlers/:crawlerId/edit', breadcrumb: 'Edytuj' },
];

const listsGenericRoutes = [{ path: '/listsGeneric', breadcrumb: 'Listy generyczne' }];

const HomeBreadcrumb = () => <i className="fa fa-home" />;

export const routes = [
    { path: '/', breadcrumb: HomeBreadcrumb },
    { path: '/dashboard', breadcrumb: 'Pulpit' },
    ...userRoutes,
    ...profileRoutes,
    ...projectRoutes,
    ...crawlerRoutes,
    ...projectCrawlersRoutes,
    ...clientRoutes,
    ...reportsRoutes,
    ...eventsRoutes,
    ...aspectSynonymsRoutes,
    ...stopListsRoutes,
    ...aspectsToIgnoreRoutes,
    ...opinionSynonymsRoutes,
    ...listsGenericRoutes,
];

const Breadcrumbs = ({ breadcrumbs }) => (
    <Breadcrumb>
        {breadcrumbs.map(({ breadcrumb, path, match }, index) => (
            <span key={path}>
                {breadcrumbs.length - 1 !== index ? (
                    <BreadcrumbItem>
                        <Link to={match.url || ''}>{breadcrumb}</Link>
                        &nbsp;&rarr;&nbsp;
                    </BreadcrumbItem>
                ) : (
                    <BreadcrumbItem active>{breadcrumb}</BreadcrumbItem>
                )}
            </span>
        ))}
    </Breadcrumb>
);

export default withBreadcrumbs(routes)(Breadcrumbs);
