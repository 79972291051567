import handleError from '../../infrastructure/api/ApiErrorHandler';
import { success } from '../../infrastructure/notifications/Notifications';
import * as CrawlerApiClient from './CrawlerApiClient';

export const ADD_CRAWLER = 'ADD_CRAWLER';
export const GET_PROJECT_CRAWLERS = 'GET_PROJECT_CRAWLERS';
export const GET_ALL_CRAWLERS = 'GET_ALL_CRAWLERS';
export const TOGGLE_CRAWLER_ENABLED = 'TOGGLE_CRAWLER_ENABLED';
export const DELETE_CRAWLER = 'DELETE_CRAWLER';

export function addCrawlerActionCreator(data, projectId, history) {
    return (dispatch, getState) => {
        return CrawlerApiClient.createFromTemplate(data, projectId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                history.push(`/projects/${projectId}/crawlers/manage`);
                dispatch(success({ message: `Dodano crawler: ${response.data.name}` }));
                dispatch({
                    type: ADD_CRAWLER,
                    crawler: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function toggleCrawlerEnabledActionCreator(crawlerId) {
    return (dispatch, getState) => {
        return CrawlerApiClient.toggleCrawlerActive(crawlerId, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch({
                    type: TOGGLE_CRAWLER_ENABLED,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function toggleCrawlerEnabledAndRefreshProjectCrawlersActionCreator(crawlerId, projectId) {
    return (dispatch) => {
        return dispatch(toggleCrawlerEnabledActionCreator(crawlerId)).then(() => {
            return dispatch(getProjectCrawlersActionCreator(projectId));
        });
    };
}

export function deleteCrawlerActionCreator(crawlerId) {
    return (dispatch, getState) => {
        return CrawlerApiClient.deleteCrawler(crawlerId, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch({
                    type: DELETE_CRAWLER,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteCrawlerAndRefreshAllCrawlersActionCreator(crawlerId) {
    return (dispatch) => {
        return dispatch(deleteCrawlerActionCreator(crawlerId)).then(() => {
            return dispatch(getAllCrawlersActionCreator());
        });
    };
}

export function deleteCrawlerAndRefreshProjectCrawlersActionCreator(crawlerId, projectId) {
    return (dispatch) => {
        return dispatch(deleteCrawlerActionCreator(crawlerId)).then(() => {
            return dispatch(getProjectCrawlersActionCreator(projectId));
        });
    };
}

export function getProjectCrawlersActionCreator(projectId) {
    return (dispatch, getState) => {
        return CrawlerApiClient.getProjectCrawlers(projectId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_PROJECT_CRAWLERS,
                    projectCrawlers: new Map().set(projectId, response.data),
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function getAllCrawlersActionCreator() {
    return (dispatch, getState) => {
        return CrawlerApiClient.getAllCrawlers(getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_ALL_CRAWLERS,
                    crawlers: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function toggleCrawlerEnabledAndRefreshAllCrawlersActionCreator(crawlerId) {
    return (dispatch) => {
        return dispatch(toggleCrawlerEnabledActionCreator(crawlerId)).then(() => {
            return dispatch(getAllCrawlersActionCreator());
        });
    };
}
