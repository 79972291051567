import { GET_USER, GET_USERS } from './UserActions';

function userReducer(state = {}, action) {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                user: action.user,
            };
        case GET_USERS:
            return {
                ...state,
                users: action.users,
            };
        default:
            console.debug('userReducer :: hit default', action.type);
            return state;
    }
}

export default userReducer;
