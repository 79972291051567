import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClientsActionCreator } from '../../../../domain/client/ClientActions';
import { toggleClientAssignedAndRefreshClientsActionCreator } from '../../../../domain/report/ReportActions';
import AssignClientsTable from './AssignClientsTable';

const AssignClients = ({ reportId, refresh }) => {
    const { clients } = useSelector((state) => state.clientReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getClientsActionCreator());

        /* eslint-disable */
    }, [refresh]);
    /* eslint-enable */

    const handleToggleClientAssigned = (userId, value) =>
        dispatch(toggleClientAssignedAndRefreshClientsActionCreator(userId, reportId, value));

    const data =
        clients &&
        clients.map((user) => {
            const assigned = user.reports.map((el) => el.id).includes(Number.parseInt(reportId));
            return { ...user, assigned };
        });

    return <AssignClientsTable data={data} onToggleClientAssigned={handleToggleClientAssigned} />;
};

AssignClients.propTypes = {
    reportId: PropTypes.string.isRequired,
    refresh: PropTypes.object,
};

export default AssignClients;
