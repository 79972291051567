import PropTypes from 'prop-types';
import React from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Field } from 'redux-form';

import { ValidationBlock } from '../../../../../components/Form/ValidationBlock/ValidationBlock';
import style from './style.module.scss';

const SynonymInput = ({ input, name, type, placeholder, meta: { touched, error }, value, addons }) => (
    <>
        <Input
            name={name}
            id={name}
            placeholder={placeholder}
            type={type}
            value={value}
            {...input}
            invalid={touched && error}
        />
        {addons}

        <div className="w-100">
            <ValidationBlock error={touched && error} />
        </div>
    </>
);

const RemoveButton = ({ index, onRemoveSynonym }) => (
    <InputGroupAddon addonType="append">
        <Button onClick={onRemoveSynonym(index)} className={style.removeBtn}>
            <i className="fa fa-times" />
        </Button>
    </InputGroupAddon>
);

const Synonyms = ({ fields, placeholder, meta: { error } }) => {
    const onAddSynonym = () => fields.push('');
    const onRemoveSynonym = (index) => () => fields.remove(index);

    return (
        <>
            {fields.map((synonym, index) => (
                <div key={synonym} className={style.synonymInput}>
                    <InputGroup>
                        <Field
                            name={synonym}
                            type="text"
                            component={SynonymInput}
                            placeholder={placeholder}
                            addons={<RemoveButton index={index} onRemoveSynonym={onRemoveSynonym} />}
                        />
                    </InputGroup>
                </div>
            ))}

            {error && <div className="text-danger">{error}</div>}

            <div className={style.addBtnWrapper}>
                <Button onClick={onAddSynonym} color="primary">
                    Dodaj <i className="fa fa-plus ml-2" />
                </Button>
            </div>
        </>
    );
};

Synonyms.propTypes = {
    fields: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default Synonyms;
