import './style.scss';

import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Collapse } from 'reactstrap';

import Accordion from '../Accordion';

const AccordionPanel = ({ toggle, areOpen, id, text, icon, children }) => {
    const iconClassName = cn(icon, 'mr-3');

    return (
        <div className="mb-3">
            <Accordion onClick={() => toggle(id)} isOpen={areOpen[id]}>
                <i className={iconClassName} /> {text}
            </Accordion>
            <Collapse isOpen={areOpen[id]}>{children}</Collapse>
        </div>
    );
};

AccordionPanel.propTypes = {
    icon: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    toggle: PropTypes.func.isRequired,
    areOpen: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
};

export const useAccordionPanel = (id) => {
    const [areOpen, setAreOpen] = useState({});
    const key = `accordion_${id}`;

    const toggle = (id) => {
        const data = {};
        data[id] = !areOpen[id];
        setAreOpen({ ...data });

        if (id) {
            const url = window.location.href;
            const result = { url, areOpen: data };
            window.sessionStorage.setItem(key, JSON.stringify(result));
        }
    };

    const commonAccordionProps = {
        toggle,
        areOpen,
    };

    useEffect(() => {
        if (id) {
            const url = window.location.href;
            const data = window.sessionStorage.getItem(key);
            const json = data ? JSON.parse(data) : {};
            json && url === json.url ? setAreOpen(json.areOpen) : setAreOpen({});
        }
    }, [id, key]);

    return commonAccordionProps;
};

export default AccordionPanel;
