import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../components/Form/FormGroup/FormGroup';
import { nameFieldValidator } from '../../../../tools/validatorHelpers';

let AddReportForm = ({ handleSubmit, onSubmit, pristine, reset }) => (
    <>
        <Form onSubmit={handleSubmit(onSubmit)} action="" className="form-horizontal">
            <CardHeader>
                <strong>Dodaj raport</strong>
            </CardHeader>
            <Card>
                <CardBody>
                    <Field component={TextFormGroup} name="name" placeholder="Nazwa" type="text" />
                </CardBody>
                <CardFooter>
                    <FormButtons pristine={pristine} reset={reset} showClose={false} />
                </CardFooter>
            </Card>
        </Form>
    </>
);

AddReportForm = reduxForm({
    form: 'addReportForm',
    validate: nameFieldValidator,
})(AddReportForm);

export default AddReportForm;
