import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

const EditButton = ({ onEdit, withText = true, text = 'Edytuj', withNoMargin = false }) => (
    <Button color="primary" onClick={onEdit} className={withNoMargin ? '' : 'mr-3'} size="sm">
        <i className="fas fa-pencil-alt" /> {withText && <span className="ml-2">{text}</span>}
    </Button>
);

EditButton.propTypes = {
    onEdit: PropTypes.func.isRequired,
    withText: PropTypes.bool,
    text: PropTypes.string,
    withNoMargin: PropTypes.bool,
};

export default EditButton;
