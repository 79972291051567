import { GET_PROJECT, GET_PROJECT_KEYWORDS, GET_PROJECTS } from './ProjectActions';

function projectReducer(state = {}, action) {
    switch (action.type) {
        case GET_PROJECT:
            return {
                ...state,
                project: action.project,
            };
        case GET_PROJECTS:
            return {
                ...state,
                projects: action.projects,
            };
        case GET_PROJECT_KEYWORDS:
            return {
                ...state,
                keywords: action.keywords,
            };
        default:
            console.debug('reducer :: hit default', action.type);
            return state;
    }
}

export default projectReducer;
