import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../../components/Form/FormGroup/FormGroup';
import { nameAndDescriptionValidator } from '../../../../../tools/validatorHelpers';

let AddListPageForm = ({ handleSubmit, onSubmit, pristine, reset, submitting }) => (
    <Form onSubmit={handleSubmit(onSubmit)} action="" className="form-horizontal">
        <CardHeader>
            <strong>Dodaj listę</strong>
        </CardHeader>
        <Card>
            <CardBody>
                <Field component={TextFormGroup} name="name" placeholder="Nazwa" type="text" />
                <Field component={TextFormGroup} name="description" placeholder="Opis" type="textarea" rows="10" />
            </CardBody>
            <CardFooter>
                <FormButtons pristine={pristine} submitting={submitting} reset={reset} />
            </CardFooter>
        </Card>
    </Form>
);

AddListPageForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

AddListPageForm = reduxForm({
    form: 'addListPageformxxxxxx',
    validate: nameAndDescriptionValidator,
})(AddListPageForm);

export default AddListPageForm;
