import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AddListPage from '../commons/AddListPage';
import { LIST_TYPES } from '../commons/consts';
import ListWithTermAllPage from '../commons/ListWithTermAllPage';
import ListWithTermPage from '../commons/ListWithTermPage';

const props = {
    title: 'Synonimy opinii',
    type: LIST_TYPES.OPINION_SYNONYMS,
};

const AddOpinionSynonyms = () => <AddListPage {...props} />;

const OpinionSynonymsAll = () => <ListWithTermAllPage {...props} webUrl="opinionSynonyms" />;

const OpinionSynonymsPage = () => <ListWithTermPage {...props} />;

const OpinionSynonyms = () => (
    <Switch>
        <Route path="/opinionSynonyms/manage/:id" component={OpinionSynonymsPage} />
        <Route path="/opinionSynonyms/add" component={AddOpinionSynonyms} />
        <Route path="/opinionSynonyms" component={OpinionSynonymsAll} />
    </Switch>
);

export default OpinionSynonyms;
