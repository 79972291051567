import { GET_REPORT, GET_REPORTS } from './ReportActions';

function projectReducer(state = {}, action) {
    switch (action.type) {
        case GET_REPORT:
            return {
                ...state,
                report: action.report,
            };
        case GET_REPORTS:
            return {
                ...state,
                reports: action.reports,
            };
        default:
            console.debug('reducer :: hit default', action.type);
            return state;
    }
}

export default projectReducer;
