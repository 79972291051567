import * as _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';
import useSWR from 'swr';

import AccordionPanel, { useAccordionPanel } from '../../../../../components/AccordionPanel';
import PageWithDynamicName from '../../../../../components/Page/PageWithDynamicName';
import RefreshButton from '../../../../../components/RefreshButton';
import { CenteredSpinner } from '../../../../../components/Spinner/Spinner';
import { LIST_TYPES } from '../../../../../consts/listTypes';
import { saveSettings } from '../../../../../domain/report/ReportApiClients';
import { API_URL } from '../../../../../environment';
import handleError from '../../../../../infrastructure/api/ApiErrorHandler';
import { REFRESH_0, useFetcher, useToken } from '../../../../../infrastructure/api/tools';
import { success } from '../../../../../infrastructure/notifications/Notifications';
import AssignClients from '../../AssignClients';
import ListAccordionTitle from '../../commons/ListAccordionTitle';
import ListsAccordion from './ListsAccordions';
import ReportModal, { useReportModal } from './ReportModal';
import SettingsPanel from './SettingsPanel';

const SECTIONS = {
    SETTINGS: 'settings',
    ADD_CLIENT: 'addClient',
    ...LIST_TYPES,
};

const ReportPage = (props) => {
    const { reportId } = useParams();
    const [refresh, setRefresh] = useState({});
    const { showModal, onCloseModal, onClickGenerate } = useReportModal();
    const fetcher = useFetcher();
    const dispatch = useDispatch();
    const token = useToken();
    const commonProps = useAccordionPanel('REPORT_PAGE');
    const { data, mutate } = useSWR(`${API_URL}/reports/${reportId}`, fetcher, REFRESH_0);

    const basicProps = { ...props, reportId, refresh };

    const onSubmitSettings = async (values) => {
        try {
            await saveSettings(reportId, token, JSON.stringify(values));
            await mutate();
            dispatch(success({ message: 'Zapisano ustawienia raportu' }));
        } catch (e) {
            handleError(e, dispatch);
        }
    };

    const getSettingsInitialValues = (settings) => ({
        publishedFrom: _.get(settings, 'publishedFrom'),
        publishedTo: _.get(settings, 'publishedTo'),
        projectId: _.get(settings, 'project.id'),
    });

    return (
        <PageWithDynamicName name="Edytuj raport" dynamicElement={data}>
            <ReportModal show={showModal} onClose={onCloseModal} reportId={reportId} />

            <div className="text-right">
                <div className="d-inline-block">
                    <RefreshButton onClick={() => setRefresh({})} />
                </div>

                <Button onClick={onClickGenerate} color="primary" className="ml-3">
                    <i className="fas fa-radiation-alt mr-2" /> Generuj
                </Button>
            </div>

            <AccordionPanel text="Dostęp" icon="fa fa-users" id={SECTIONS.ADD_CLIENT} {...commonProps}>
                <AssignClients {...basicProps} />
            </AccordionPanel>

            <AccordionPanel text="Ustawienia" icon="fas fa-cog" id={SECTIONS.SETTINGS} {...commonProps}>
                {data ? (
                    <SettingsPanel
                        {...basicProps}
                        onSubmit={onSubmitSettings}
                        initialValues={getSettingsInitialValues(data.reportSettings)}
                    />
                ) : (
                    <CenteredSpinner />
                )}
            </AccordionPanel>

            <ListAccordionTitle />
            {data ? (
                <ListsAccordion SECTIONS={SECTIONS} basicProps={basicProps} data={data} mutate={mutate} />
            ) : (
                <CenteredSpinner />
            )}
        </PageWithDynamicName>
    );
};

export default ReportPage;
