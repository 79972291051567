import PropTypes from 'prop-types';
import React from 'react';
import { Field, FieldArray } from 'redux-form';

import CleanInput from '../../../../../components/Form/FormGroup/CleanInput';
import style from './style.module.scss';
import Synonyms from './Synonyms';

const EditRowForm = ({ title, titleName, arrayTitle, arrayName, placeholder }) => (
    <>
        <h5>{title}</h5>
        <Field component={CleanInput} name={titleName} type="text" />

        <h5 className={style.header}>{arrayTitle}</h5>
        <FieldArray name={arrayName} component={Synonyms} placeholder={placeholder} />
    </>
);

EditRowForm.propTypes = {
    title: PropTypes.string.isRequired,
    titleName: PropTypes.string.isRequired,
    arrayTitle: PropTypes.string.isRequired,
    arrayName: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
};

export default EditRowForm;
