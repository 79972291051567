import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import Table from '../../../../components/Table/Table';
import { getProjectTexts } from '../../../../domain/text/TextApiClient';
import handleError from '../../../../infrastructure/api/ApiErrorHandler';
import { useToken } from '../../../../infrastructure/api/tools';
import SingleDatePickerWrapper from './SingleDatePickerWrapper';

const ParametersTable = (row) => <div className="text-center p-3">{row.original.content}</div>;

const renderPublishDateFilter = ({ onChange }) => <SingleDatePickerWrapper onDateChange={(date) => onChange(date)} />;

const renderDownloadFilter = ({ onChange }) => <SingleDatePickerWrapper onDateChange={(date) => onChange(date)} />;

const ProjectTextsTable = ({ projectId }) => {
    const [pages, setPages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [texts, setTexts] = useState([]);
    const dispatch = useDispatch();
    const token = useToken();

    const requestData = async (state) => {
        const params = { pageSize: state.pageSize, page: state.page, sorted: state.sorted, filtered: state.filtered };
        setLoading(true);

        try {
            const { data } = await getProjectTexts(projectId, token, params);
            if (data) {
                setTexts(data.content);
                setPages(data.totalPages);
            }
        } catch (error) {
            handleError(error, dispatch);
        } finally {
            setLoading(false);
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Data pobrania',
                accessor: 'added',
                Filter: renderDownloadFilter,
                id: 'addedFrom',
            },
            {
                Header: 'Crawler',
                accessor: 'crawlerName',
            },
            {
                Header: 'Identyfikator uruchomienia',
                accessor: 'crawlerRunId',
            },
            {
                Header: 'Data opublikowania',
                accessor: 'published',
                Filter: renderPublishDateFilter,
                id: 'publishedFrom',
            },
            {
                Header: 'Hash',
                accessor: 'hash',
                resizable: true,
            },
            {
                Header: 'Treść',
                accessor: 'content',
                filterable: false,
                sortable: false,
            },
        ],
        [],
    );

    return (
        <Table
            data={texts}
            columns={columns}
            manual
            resizable
            getTheadFilterThProps={() => ({ style: { position: 'inherit', overflow: 'inherit' } })}
            pages={pages}
            loading={loading}
            onFetchData={requestData}
            SubComponent={ParametersTable}
        />
    );
};

ProjectTextsTable.propTypes = {
    projectId: PropTypes.string,
};

export default ProjectTextsTable;
