import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    startLoadingActionCreator,
    stopLoadingActionCreator,
} from '../../../../../components/GlobalSpinner/GlobalSpinnerActions';
import Page from '../../../../../components/Page';
import AddButton from '../../../../../customComponents/AddButton';
import {
    deleteCrawlerAndRefreshProjectCrawlersActionCreator,
    getProjectCrawlersActionCreator,
    toggleCrawlerEnabledAndRefreshProjectCrawlersActionCreator,
} from '../../../../../domain/crawler/CrawlerActions';
import { hasAuthority } from '../../../../../domain/security/AuthorityService/AuthorityService';
import AdminProjectCrawlersTable from './AdminProjectCrawlersTable';
import ProjectCrawlersTable from './ProjectCrawlersTable';

const ManageProjectCrawlers = ({ name, refresh }) => {
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const crawlers = useSelector((state) => state.crawlerReducer.projectCrawlers);
    const jwt = useSelector((state) => state.authenticationReducer.jwt);

    const handleDeleteCrawler = (crawlerId) =>
        dispatch(startLoadingActionCreator())
            .then(() => dispatch(deleteCrawlerAndRefreshProjectCrawlersActionCreator(crawlerId, projectId)))
            .then(() => dispatch(stopLoadingActionCreator()));

    const handleToggleCrawlerActive = (crawlerId) =>
        dispatch(toggleCrawlerEnabledAndRefreshProjectCrawlersActionCreator(crawlerId, projectId));

    useEffect(() => {
        dispatch(getProjectCrawlersActionCreator(projectId));
    }, [dispatch, projectId, refresh]);

    const crawlersData = crawlers && crawlers.get(projectId);

    return (
        <Page name={name}>
            <div className="pb-2">
                <AddButton link={`/projects/${projectId}/crawlers/add`}>Nowy crawler</AddButton>
            </div>
            {hasAuthority(jwt.authority, ['ROLE_ADMIN']) ? (
                <AdminProjectCrawlersTable
                    data={crawlersData}
                    onToggleCrawlerActive={handleToggleCrawlerActive}
                    onDelete={handleDeleteCrawler}
                />
            ) : (
                <ProjectCrawlersTable data={crawlersData} />
            )}
        </Page>
    );
};

ManageProjectCrawlers.propTypes = {
    name: PropTypes.string.isRequired,
    refresh: PropTypes.object,
};

export default ManageProjectCrawlers;
