import { CCard, CCardBody, CCardFooter } from '@coreui/react';
import React from 'react';
import { Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../../components/Form/FormGroup/FormGroup';
import * as ListApiClient from '../../../../../domain/list/ListApiClient';
import { useListHandleRequest } from '../../../../../infrastructure/api/hooks';
import { nameAndDescriptionValidator } from '../../../../../tools/validatorHelpers';

export const prepareBasicInfo = (data) => ({ name: data.name, description: data.description });

let EditBasicInfo = ({ handleSubmit, type, mutate }) => {
    const onSubmit = useListHandleRequest(
        type,
        ListApiClient.editBasicInfo,
        mutate,
        'Nazwa i opis zostały zaktualizowane.',
    );

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <CCard>
                <CCardBody>
                    <Field component={TextFormGroup} name="name" placeholder="Nazwa" type="text" />
                    <Field component={TextFormGroup} name="description" placeholder="Opis" type="textarea" rows="10" />
                </CCardBody>

                <CCardFooter>
                    <FormButtons showClose={false} />
                </CCardFooter>
            </CCard>
        </Form>
    );
};

EditBasicInfo = reduxForm({
    form: 'editBasicInfoAspectSynonymsList',
    validate: nameAndDescriptionValidator,
})(EditBasicInfo);

export default EditBasicInfo;
