import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../components/Form/FormGroup/FormGroup';

const validator = (values) => {
    const errors = {};
    const requiredFields = ['name', 'description'];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Pole wymagane';
        }
    });
    return errors;
};

let AddProjectForm = ({ handleSubmit, onSubmit, pristine, reset, submitting }) => (
    <Form onSubmit={handleSubmit(onSubmit)} action="" className="form-horizontal">
        <CardHeader>
            <strong>Dodaj projekt</strong>
        </CardHeader>
        <Card>
            <CardBody>
                <Field component={TextFormGroup} name="name" placeholder="Nazwa" type="text" />
                <Field component={TextFormGroup} name="description" placeholder="Opis" type="textarea" rows="10" />
            </CardBody>
            <CardFooter>
                <FormButtons pristine={pristine} submitting={submitting} reset={reset} />
            </CardFooter>
        </Card>
    </Form>
);

AddProjectForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

AddProjectForm = reduxForm({
    form: 'addProject',
    validate: validator,
})(AddProjectForm);

export default AddProjectForm;
