import handleError from '../../infrastructure/api/ApiErrorHandler';
import * as CrawlerTemplateApiClient from './CrawlerTemplateApiClient';

export const GET_CRAWLER_TEMPLATES = 'GET_CRAWLER_TEMPLATES';
export const TOGGLE_CRAWLER_TEMPLATE_ENABLED = 'TOGGLE_CRAWLER_TEMPLATE_ENABLED';

export function getCrawlerTemplatesActionCreator() {
    return (dispatch, getState) => {
        return CrawlerTemplateApiClient.getTemplates(getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_CRAWLER_TEMPLATES,
                    templates: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function toggleCrawlerEnabledActionCreator(crawlerTemplateId) {
    return (dispatch, getState) => {
        return CrawlerTemplateApiClient.toggleCrawlerTemplateActive(
            crawlerTemplateId,
            getState().authenticationReducer.jwtEncoded,
        )
            .then(() => {
                dispatch({
                    type: TOGGLE_CRAWLER_TEMPLATE_ENABLED,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function toggleCrawlerTemplateEnabledAndRefreshCrawlerTemplatesActionCreator(crawlerId) {
    return (dispatch) => {
        return dispatch(toggleCrawlerEnabledActionCreator(crawlerId)).then(() => {
            return dispatch(getCrawlerTemplatesActionCreator());
        });
    };
}
