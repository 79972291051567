import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import Card from '../../../../../components/ClassicCard/ClassicCard';
import { SingleInputFormGroup } from '../../../../../components/Form/FormGroup/SingleInputFormGroup';
import SubmittingAware from '../../../../../components/SubmittingAware/SubmittingAware';

const validator = (values) => {
    const errors = {};
    const requiredFields = ['keyword'];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Pole wymagane';
        }
    });
    return errors;
};

class AddProjectKeywordsForm extends React.Component {
    renderFormBody = (pristine, submitting) => {
        return (
            <Field
                component={SingleInputFormGroup}
                name="keyword"
                placeholder="Słowo kluczowe"
                buttonText="dodaj"
                formPristine={pristine}
                formSubmitting={submitting}
                type="text"
            />
        );
    };

    render() {
        const { handleSubmit, onSubmit, pristine, submitting } = this.props;
        return (
            <Form onSubmit={handleSubmit(onSubmit)} action="" className="form-horizontal">
                <Card header={'Dodaj słowo kluczowe'} body={this.renderFormBody(pristine, submitting)} />
            </Form>
        );
    }
}

AddProjectKeywordsForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

AddProjectKeywordsForm = reduxForm({
    form: 'addProjectKeyword',
    validate: validator,
})(SubmittingAware(AddProjectKeywordsForm));

export default AddProjectKeywordsForm;
