import React from 'react';

export const basicFilter = (filter, row) => {
    if (filter.value === 'all') {
        return true;
    }

    if (filter.value === 'true') {
        return row[filter.id] === true;
    }

    return row[filter.id] === false;
};

export const filterWithAllAndCustom = (filter, row) => {
    if (filter.value === 'all') {
        return true;
    }

    return row[filter.id] === filter.value;
};

export const SelectWithAllTrueFalse = ({ filter, onChange }) => (
    <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
    >
        <option value="all">Wszystkie</option>
        <option value="true">Aktywne</option>
        <option value="false">Nieaktywne</option>
    </select>
);

export const renderCellWithArrayData = ({ value }) => <span>{value.join(' ')}</span>;
