import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

const RefreshButton = ({ onClick }) => (
    <div className="text-right mb-60">
        <Button onClick={onClick} color="primary" outline>
            <i className="fas fa-sync-alt mr-2" /> Odśwież
        </Button>
    </div>
);

RefreshButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default RefreshButton;
