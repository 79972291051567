import * as React from 'react';
import { Input, Label } from 'reactstrap';

export const Switch = (props) => {
    const labelStyles = props.disabled ? { cursor: 'default' } : null;
    return (
        <Label style={labelStyles} className="switch switch-default switch-pill switch-success">
            <Input
                type="checkbox"
                className="switch-input"
                checked={props.value}
                onChange={() => props.onToggle()}
                disabled={props.disabled}
            />
            <span className="switch-label" />
            <span className="switch-handle" />
        </Label>
    );
};
