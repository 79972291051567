import './Dashboard.scss';

import React from 'react';
import { connect } from 'react-redux';
import { CardDeck } from 'reactstrap';

import AnimatedContainer from '../../../components/AnimatedContainer/AnimatedContainer';
import { getDashboardElements } from '../../../components/Sidebar/navsHelper';
import { refreshTokenActionCreator } from '../../../domain/security/AuthenticationActions';
import FacebookRateLimitInfo from './FacebookRateLimitInfo';
import Runs from './LastCrawlers/Runs';
import RunsTime from './LastCrawlers/RunsTime';
import { NavTiles } from './NavTiles';

const CRAWLER_STATUS = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    FAILURE: 'FAILURE',
    COMPLETED: 'COMPLETED',
};

const SAMPLE_DATA = [
    {
        id: '1',
        status: CRAWLER_STATUS.PENDING,
        crawlerName: 'Mairon',
        projectName: 'The Admirable',
        started: new Date(),
        ended: new Date(),
        plannedStartTime: new Date(),
    },
    {
        id: '2',
        status: CRAWLER_STATUS.PENDING,
        crawlerName: 'Mairon',
        projectName: 'The Admirable',
        started: new Date(),
        ended: new Date(),
        plannedStartTime: new Date(),
        error:
            'The admirable error. The admirable error. The admirable error. The admirable error. The admirable error',
    },
    {
        id: '3',
        status: CRAWLER_STATUS.IN_PROGRESS,
        crawlerName: 'Mairon',
        projectName: 'The Admirable',
        started: new Date(),
        ended: new Date(),
        plannedStartTime: new Date(),
    },
    {
        id: '4',
        status: CRAWLER_STATUS.FAILURE,
        crawlerName: 'Mairon',
        projectName: 'The Admirable',
        started: new Date(),
        ended: new Date(),
        plannedStartTime: new Date(),
    },
];

const FB_RATE_LIMIT = { id: 'FB', percentage: 81, lastUpdated: '2021-05-11T22:30:43' };

SAMPLE_DATA.forEach((el, index) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    el.number = index + 1;
    el.started = el.started.toLocaleDateString('pl-PL', options);
    el.ended = el.ended.toLocaleDateString('pl-PL', options);
    el.plannedStartTime = el.plannedStartTime.toLocaleDateString('pl-PL', options);
});

const Dashboard = ({ authority }) => (
    <AnimatedContainer>
        <div className="section">
            <NavTiles userAuthority={authority} navItems={getDashboardElements()} />
        </div>

        <div className="section-bottom">
            <CardDeck>
                <FacebookRateLimitInfo rawData={FB_RATE_LIMIT} />
                <Runs rawData={SAMPLE_DATA} />
            </CardDeck>
        </div>

        <div className="section-bottom">
            <RunsTime data={SAMPLE_DATA} />
        </div>
    </AnimatedContainer>
);

const mapStateToProps = (state) => ({
    authority: state.authenticationReducer.jwt.authority,
});

export default connect(mapStateToProps, { refreshTokenActionCreator })(Dashboard);
