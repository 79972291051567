import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';

import style from './style.module.scss';

const CRAWLER_STATUS = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    FAILURE: 'FAILURE',
    COMPLETED: 'COMPLETED',
};

const LABELS = new Map([
    [CRAWLER_STATUS.COMPLETED, 'Zakończone'],
    [CRAWLER_STATUS.IN_PROGRESS, 'W trakcie'],
    [CRAWLER_STATUS.FAILURE, 'Nieudane'],
    [CRAWLER_STATUS.PENDING, 'Trwające'],
]);

const COLORS = new Map([
    [CRAWLER_STATUS.COMPLETED, 'rgba(86, 172, 70, 0.15)'],
    [CRAWLER_STATUS.IN_PROGRESS, 'rgba(86, 172, 70, 0.55)'],
    [CRAWLER_STATUS.FAILURE, 'rgba(236, 32, 40, 0.7)'],
    [CRAWLER_STATUS.PENDING, 'rgba(86, 172, 70, 1)'],
]);

const getLabels = () => Object.keys(CRAWLER_STATUS).map((key) => LABELS.get(CRAWLER_STATUS[key]));

const getBgColors = () => Object.keys(CRAWLER_STATUS).map((key) => COLORS.get(CRAWLER_STATUS[key]));

const getData = (rawData) =>
    Object.keys(CRAWLER_STATUS).map((key) => {
        const status = CRAWLER_STATUS[key];
        const dataWithStatus = rawData.filter((el) => el.status === status);
        return dataWithStatus.length;
    });

const Runs = ({ rawData }) => {
    const data = {
        labels: getLabels(),
        datasets: [
            {
                data: getData(rawData),
                backgroundColor: getBgColors(),
            },
        ],
    };

    return (
        <Card>
            <CardBody>
                <CardTitle>
                    <h2>Uruchomienia</h2>
                </CardTitle>
                <CardSubtitle className="mb-3">Dane z ostanich 10 uruchomień crawlerów</CardSubtitle>
                <div className={style['runs-chart-wrapper']}>
                    <Doughnut data={data} />
                </div>
            </CardBody>
        </Card>
    );
};

export default Runs;
