import React, { Component } from 'react';
import { connect } from 'react-redux';

import ErrorPage from '../../../components/ErrorPage/ErrorPage';
import * as AuthorityService from '../AuthorityService/AuthorityService';
import { Jwt } from '../model/Jwt';

const AuthorityAwareComponentWrapper = (allowedAuthorities) => (ProtectedComponent) => {
    class AuthorityAwareComponent extends Component {
        render() {
            if (AuthorityService.hasAuthority(this.props.jwt.authority, allowedAuthorities)) {
                return <ProtectedComponent {...this.props} />;
            } else {
                return (
                    <ErrorPage
                        title={'403'}
                        subtitle={'forbidden'}
                        comment={'Nie masz uprawnień do podglądu tej strony'}
                        {...this.props}
                    />
                );
            }
        }
    }

    function mapStateToProps(state) {
        return { jwt: new Jwt(state.authenticationReducer.jwt) };
    }

    return connect(mapStateToProps)(AuthorityAwareComponent);
};

export const User = AuthorityAwareComponentWrapper(['ROLE_USER', 'ROLE_ADMIN']);
export const Admin = AuthorityAwareComponentWrapper(['ROLE_ADMIN']);

export default AuthorityAwareComponentWrapper;
