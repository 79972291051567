import { CBadge, CCard, CCardBody, CCardHeader } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Progress } from 'reactstrap';
import useSWR from 'swr';

import Page from '../../../../components/Page';
import { CenteredSpinner } from '../../../../components/Spinner/Spinner';
import { downloadLogsFile, stopRebuildProcess } from '../../../../domain/report/ReportApiClients';
import { API_URL } from '../../../../environment';
import handleError from '../../../../infrastructure/api/ApiErrorHandler';
import { useFetcher, useToken } from '../../../../infrastructure/api/tools';
import { success } from '../../../../infrastructure/notifications/Notifications';
import ReportModal, { useReportModal } from '../../Reports/Manage/ReportPage/ReportModal';
import ProcessRow from './ProcessRow';
import style from './style.module.scss';

const REFRESH_INTERVAL_MS = 5;

const STATUS_COLORS = {
    FAILED: 'danger',
    INITIAL: 'info',
    ONGOING: 'warning',
    COMPLETED_WITH_SUCCESS: 'primary',
    CANCELED: 'danger',
};

const isCompleted = (status) =>
    (status === 'FAILED') | (status === 'SUCCESS') || status === 'CANCELLED' || status.includes('COMPLETED');

const ProcessPage = () => {
    const { id } = useParams();
    const fetcher = useFetcher();
    const token = useToken();
    const history = useHistory();
    const dispatch = useDispatch();
    const { showModal, onCloseModal, onClickGenerate } = useReportModal();
    const { data } = useSWR(`${API_URL}/reports/rebuild/${id}`, fetcher, { refreshInterval: REFRESH_INTERVAL_MS });
    const onClickDownloadLogs = () => downloadLogsFile(token, id);

    const actionAfterRebuild = (result) => history.push(`/processes/manage/${result.id}`);

    const onClickClose = async () => {
        try {
            await stopRebuildProcess(data.processId, token);
            dispatch(success({ message: 'Przerwanie generowania raportu' }));
        } catch (e) {
            handleError(e, dispatch);
        }
    };

    return data && data.report ? (
        <Page name={`${data.report.sentimentProjectName} - ${data.processId}`}>
            <ReportModal
                show={showModal}
                onClose={onCloseModal}
                reportId={data.report.databaseId}
                actionAfterRebuild={actionAfterRebuild}
            />

            <div className="mt-20 text-right">
                {isCompleted(data.status) && (
                    <Button color="primary" size="lg" className="mr-10" onClick={onClickDownloadLogs}>
                        <i className="fas fa-download mr-2" /> Pobierz pełne logi
                    </Button>
                )}

                {!isCompleted(data.status) && (
                    <Button color="danger" size="lg" onClick={onClickClose} className="mr-10">
                        <i className="fas fa-power-off mr-2" /> Przerwij
                    </Button>
                )}

                <Button color="warning" size="lg" onClick={onClickGenerate}>
                    <i className="fas fa-redo-alt mr-2" /> Ponów
                </Button>
            </div>

            <CCard className="mt-40">
                <CCardHeader>Informacje</CCardHeader>
                <CCardBody>
                    <div>
                        Status:
                        <CBadge color={STATUS_COLORS[data.status]} className="ml-2">
                            {data.status}
                        </CBadge>
                    </div>
                    <div className="mt-6">
                        Raport: <strong>{data.report.sentimentProjectName}</strong>
                    </div>
                    <div className="mt-6">
                        Ostatnia aktualizacja: <strong>{data.lastUpdated}</strong>
                    </div>
                    <div className="mt-6">
                        Etap: <strong>{data.stage}</strong>
                    </div>
                </CCardBody>
            </CCard>

            {!isCompleted(data.status) && <Progress striped animated={true} value={100} />}

            <div className={style.logs}>
                {data.outputEntries.map((el, index) => (
                    <ProcessRow key={index} {...el} />
                ))}
            </div>
        </Page>
    ) : (
        <CenteredSpinner />
    );
};

export default ProcessPage;
