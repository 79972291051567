import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../components/Form/FormGroup/FormGroup';

const validator = (values) => {
    const errors = {};
    if (values.password && values.repeatPassword && !(values.password === values.repeatPassword)) {
        errors.repeatPassword = 'Hasła muszą być takie same';
    }
    return errors;
};

let EditClientForm = ({ handleSubmit, onSubmit, pristine, reset, submitting }) => (
    <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
        <Card>
            <CardHeader>
                <strong>Edytuj klienta</strong>
            </CardHeader>
            <CardBody>
                <Field component={TextFormGroup} name="login" placeholder="Login" type="text" />
                <Field
                    component={TextFormGroup}
                    name="password"
                    placeholder="Hasło"
                    type="password"
                    help="min. 8 znaków"
                />
                <Field component={TextFormGroup} name="repeatPassword" placeholder="Powtórz hasło" type="password" />
                <Field component={TextFormGroup} name="role" type="select" placeholder="Uprawnienia">
                    <option value="">Wybierz role...</option>
                    <option value="ROLE_USER">Użytkownik</option>
                    <option value="ROLE_ADMIN">Administrator</option>
                </Field>
            </CardBody>
            <CardFooter>
                <FormButtons pristine={pristine} submitting={submitting} reset={reset} />
            </CardFooter>
        </Card>
    </Form>
);

EditClientForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    reset: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
};

EditClientForm = reduxForm({
    form: 'editClient',
    enableReinitialize: true,
    validate: validator,
})(EditClientForm);

export default EditClientForm;
