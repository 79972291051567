import axios from 'axios';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';

export async function createFromTemplate(data, projectId, token) {
    return axios.post(`${API_URL}/projects/${projectId}/crawlers`, data, {
        headers: createHeaders(token),
    });
}

export async function edit(data, id, token) {
    return axios.put(`${API_URL}/crawlers/${id}`, data, {
        headers: createHeaders(token),
    });
}

export async function getProjectCrawlers(projectId, token) {
    return axios.get(`${API_URL}/projects/${projectId}/crawlers`, {
        headers: createHeaders(token),
    });
}

export async function editCrawler(data, crawlerId, token) {
    return axios.patch(`${API_URL}/crawlers/${crawlerId}`, data, {
        headers: createHeaders(token),
    });
}

export async function getAllCrawlers(token) {
    return axios.get(`${API_URL}/crawlers`, {
        headers: createHeaders(token),
    });
}

export async function toggleCrawlerActive(id, token) {
    return axios.post(`${API_URL}/crawlers/${id}/toggleActive`, null, {
        headers: createHeaders(token),
    });
}

export async function deleteCrawler(id, token) {
    return axios.delete(`${API_URL}/crawlers/${id}`, {
        headers: createHeaders(token),
    });
}
