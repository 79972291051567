import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AddListPage from '../commons/AddListPage';
import { LIST_TYPES } from '../commons/consts';
import ListWithWordAllPage from '../commons/ListWithWordAllPage';
import ListWithWordPage from '../commons/ListWithWordPage';

const props = {
    title: 'Aspekty do ignorowania',
    type: LIST_TYPES.ASPECTS_TO_IGNORE,
};

const AddAspectSynonyms = () => <AddListPage {...props} />;

const AspectSynonymsAll = () => <ListWithWordAllPage {...props} webUrl="aspectsToIgnore" />;

const AspectSynonymsPage = () => <ListWithWordPage {...props} />;

const AspectSynonyms = () => (
    <Switch>
        <Route path="/aspectsToIgnore/manage/:id" component={AspectSynonymsPage} />
        <Route path="/aspectsToIgnore/add" component={AddAspectSynonyms} />
        <Route path="/aspectsToIgnore" component={AspectSynonymsAll} />
    </Switch>
);

export default AspectSynonyms;
