import omit from 'lodash/omit';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isInclusivelyAfterDay, SingleDatePicker } from 'react-dates';
import momentPropTypes from 'react-moment-proptypes';

import { ANCHOR_LEFT, HORIZONTAL_ORIENTATION } from './constants';
import { SingleDatePickerPhrases } from './defaultPhrases';

const propTypes = {
    // example props for the demo
    autoFocus: PropTypes.bool,
    initialDate: momentPropTypes.momentObj,
    onDateChange: PropTypes.func,
};

const defaultProps = {
    // example props for the demo
    autoFocus: false,
    initialDate: null,

    // input related props
    id: 'date',
    placeholder: 'od',
    disabled: false,
    required: false,
    screenReaderInputMessage: '',
    showClearDate: false,
    showDefaultInputIcon: false,
    customInputIcon: null,
    block: false,
    small: true,
    regular: false,
    verticalSpacing: undefined,
    keepFocusOnInput: false,

    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: HORIZONTAL_ORIENTATION,
    anchorDirection: ANCHOR_LEFT,
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDate: false,
    isRTL: false,

    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
    onClose() {},

    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
    isDayHighlighted: () => {},

    // internationalization props
    displayFormat: () => moment.localeData('PL').longDateFormat('L'),
    monthFormat: 'MMMM YYYY',
    phrases: SingleDatePickerPhrases,
};

class SingleDatePickerWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focused: props.autoFocus,
            date: props.initialDate,
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    onDateChange(date) {
        this.setState({ date });
        this.props.onDateChange(date ? date.utc().format('YYYY-MM-DD') : undefined);
    }

    onFocusChange({ focused }) {
        this.setState({ focused });
    }

    render() {
        const { focused, date } = this.state;

        // autoFocus and initialDate are helper props for the example wrapper but are not
        // props on the SingleDatePicker itself and thus, have to be omitted.
        const props = omit(this.props, ['autoFocus', 'initialDate']);

        return (
            <SingleDatePicker
                {...props}
                id="date_input"
                date={date}
                focused={focused}
                onDateChange={this.onDateChange}
                onFocusChange={this.onFocusChange}
            />
        );
    }
}

SingleDatePickerWrapper.propTypes = propTypes;
SingleDatePickerWrapper.defaultProps = defaultProps;

export default SingleDatePickerWrapper;
