import handleError from '../../infrastructure/api/ApiErrorHandler';
import { success } from '../../infrastructure/notifications/Notifications';
import * as ProfileApiClient from './ProfileApiClient';

export const MANAGE_PROFILE = 'MANAGE_PROFILE';

export function manageProfileActionCreator(data, userId) {
    return (dispatch, getState) => {
        return ProfileApiClient.editProfile(data, userId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch(success({ message: 'Zaktualizowano dane profilowe' }));
                dispatch({
                    type: MANAGE_PROFILE,
                    user: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}
