import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';

import style from './style.module.scss';

const FULL_PERCENTAGE = 100;

const FacebookRateLimitInfo = ({ rawData }) => {
    const used = FULL_PERCENTAGE - rawData.percentage;

    const data = {
        labels: ['% do wykorzystania', '% wykorzystane'],
        datasets: [
            {
                data: [rawData.percentage, used],
                backgroundColor: ['#56ac46', 'rgba(86, 172, 70, 0.15)'],
            },
        ],
    };

    return (
        <Card>
            <CardBody>
                <CardTitle>
                    <h2>Fb Rate Limit</h2>
                </CardTitle>
                <CardSubtitle className="mb-3">Informacje o Facebook Rate Limit</CardSubtitle>
                <div className={style.wrapper}>
                    <Doughnut data={data} />
                </div>
            </CardBody>
        </Card>
    );
};

export default FacebookRateLimitInfo;
