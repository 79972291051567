import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProcessesAll from './ProcessesAll';
import ProcessPage from './ProcessPage';

const Processes = () => (
    <Switch>
        <Route path="/processes/manage/:id" component={ProcessPage} />
        <Route path="/processes" component={ProcessesAll} />
    </Switch>
);

export default Processes;
