import React from 'react';

import style from './style.module.scss';

const DEFAULT_TAB = 2;

const JsonElement = ({ data }) => {
    const json = JSON.stringify(data, null, DEFAULT_TAB);

    return (
        <div className={style.json}>
            <pre>{json}</pre>
        </div>
    );
};

export default JsonElement;
