import PropTypes from 'prop-types';
import * as React from 'react';
import { Component } from 'react';
import { SubmissionError } from 'redux-form';

import handleError from '../../../../../infrastructure/api/ApiErrorHandler';
import EditPropertyForm from './EditPropertyForm';
import PropertyView from './PropertyView';

class SingleValueEditBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            value: props.initialValue,
        };
    }

    handleEditingStart = () => {
        this.setState({
            editing: true,
        });
    };

    showInFormIfSubmissionError = (e, actions) => {
        if (e instanceof SubmissionError) {
            actions.setFieldError(this.props.name, e.errors[this.props.name]);
        } else {
            throw e;
        }
    };

    handleSaving = (value, actions) => {
        this.props
            .sendData(this.props.name, value)
            .then(() => {
                actions.setSubmitting(false);
                this.setState({
                    editing: false,
                    value: value,
                });
            })
            .catch((error) => {
                actions.setSubmitting(false);
                try {
                    handleError(error, this.props.dispatch);
                } catch (e) {
                    this.showInFormIfSubmissionError(e, actions);
                }
            });
    };

    handleCancelling = () => {
        this.setState({
            editing: false,
        });
    };

    render() {
        return this.state.editing ? (
            <EditPropertyForm
                name={this.props.name}
                label={this.props.label}
                type={this.props.type}
                options={this.props.options}
                initialValue={this.state.value}
                onSave={this.handleSaving}
                onCancel={this.handleCancelling}
            />
        ) : (
            <PropertyView name={this.props.label} value={this.state.value} onEdit={this.handleEditingStart} />
        );
    }
}

SingleValueEditBox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    options: PropTypes.array,
    initialValue: PropTypes.string.isRequired,
    sendData: PropTypes.func.isRequired,
};

export default SingleValueEditBox;
