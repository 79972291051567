import React, { useState } from 'react';
import { useHistory } from 'react-router';

import Header from './Header';
import JsonElement from './JsonElement';
import style from './style.module.scss';

const SingleEvent = ({ type, data, className }) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const { event } = data;

    const onClick = () => {
        if (data.streamId.includes('ReportRebuildingProcess')) {
            history.push(`/processes/manage/${event.processId}`);
        }
    };

    const toggleIsOpen = () => setIsOpen(!isOpen);

    return (
        <div className={`${style.singleEvent} ${className}`}>
            <Header toggleIsOpen={toggleIsOpen} isOpen={isOpen} data={data} type={type} />

            <div>
                {isOpen ? (
                    <>
                        <JsonElement data={data} />
                        <div className={style.link}>
                            <div onClick={onClick}>Zobacz więcej...</div>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default SingleEvent;
