import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    startLoadingActionCreator,
    stopLoadingActionCreator,
} from '../../../../components/GlobalSpinner/GlobalSpinnerActions';
import Page from '../../../../components/Page';
import AddButton from '../../../../customComponents/AddButton';
import {
    deleteReportAndRefreshReportsActionCreator,
    getReportsActionCreator,
} from '../../../../domain/report/ReportActions';
import { hasAuthority } from '../../../../domain/security/AuthorityService/AuthorityService';
import AdminProjectsTable from './AdminReportsTable';

const ManageReports = ({ name }) => {
    const dispatch = useDispatch();
    const reports = useSelector((state) => state.reportsReducer.reports);
    const jwt = useSelector((state) => state.authenticationReducer.jwt);

    useEffect(() => {
        dispatch(getReportsActionCreator());
    }, [dispatch]);

    const handleDeleteReport = (id, name) => {
        dispatch(startLoadingActionCreator());
        dispatch(deleteReportAndRefreshReportsActionCreator(id, name));
        dispatch(stopLoadingActionCreator());
    };

    const isAdmin = () => hasAuthority(jwt.authority, ['ROLE_ADMIN']);

    return (
        <Page name={name}>
            {isAdmin ? (
                <div className="mb-2">
                    <AddButton link="/reports/add">Nowy raport</AddButton>
                </div>
            ) : null}

            {isAdmin() ? <AdminProjectsTable data={reports} onDeleteReport={handleDeleteReport} /> : null}
        </Page>
    );
};

ManageReports.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ManageReports;
