import PropTypes from 'prop-types';
import * as React from 'react';
import { FormText } from 'reactstrap';

export const ValidationBlock = ({ error }) =>
    error ? (
        <FormText>
            <span className="text-danger">
                <i className="fas fa-exclamation-circle mr-2" />
                {error}
            </span>
        </FormText>
    ) : null;

ValidationBlock.propTypes = {
    error: PropTypes.string,
};
