import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

class ErrorPage extends Component {
    render() {
        return (
            <div className="c-app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <div className="clearfix">
                                <h1 className="float-left display-3 mr-4">{this.props.title}</h1>
                                <h4 className="pt-3">{this.props.subtitle}</h4>
                                <p className="text-muted float-left">{this.props.comment}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

ErrorPage.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
};

export default ErrorPage;
