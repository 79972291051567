import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FormContainer } from '../../../../components/Form/FormContainer/FormContainer';
import Page from '../../../../components/Page';
import { addUserActionCreator } from '../../../../domain/user/UserActions';
import { apiErrorActionCreator } from '../../../../infrastructure/api/ErrorActions';
import AddUserForm from './AddUserForm';

class AddUser extends Component {
    onSubmitAddUserForm = (data) => {
        const body = {
            login: data.login,
            role: data.role,
            password: data.password,
            name: data.name ? data.name : null,
            email: data.email ? data.email : null,
        };
        return this.props.addUserActionCreator(body, this.props.history);
    };

    render() {
        return (
            <Page name={this.props.name}>
                <FormContainer>
                    <AddUserForm onSubmit={this.onSubmitAddUserForm} />
                </FormContainer>
            </Page>
        );
    }
}

AddUser.propTypes = {
    history: PropTypes.object.isRequired,
    name: PropTypes.string,
};

export default connect(null, { addUserActionCreator, apiErrorActionCreator })(AddUser);
