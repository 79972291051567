import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import GlobalSpinner from '../../../../components/GlobalSpinner/GlobalSpinner';
import {
    startLoadingActionCreator,
    stopLoadingActionCreator,
} from '../../../../components/GlobalSpinner/GlobalSpinnerActions';
import Page from '../../../../components/Page';
import {
    deleteCrawlerAndRefreshAllCrawlersActionCreator,
    getAllCrawlersActionCreator,
    toggleCrawlerEnabledAndRefreshAllCrawlersActionCreator,
} from '../../../../domain/crawler/CrawlerActions';
import {
    getCrawlerTemplatesActionCreator,
    toggleCrawlerTemplateEnabledAndRefreshCrawlerTemplatesActionCreator,
} from '../../../../domain/crawlerTemplate/CrawlerTemplateActions';
import CrawlersTable from './CrawlersTable';
import CrawlerTemplatesTable from './CrawlerTemplatesTable';

const ManageCrawlers = ({ name }) => {
    const templates = useSelector((state) => state.crawlerTemplateReducer.templates);
    const dispatch = useDispatch();

    //TODO: only for selected project if possible
    const crawlers = useSelector((state) => state.crawlerReducer.crawlers);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [toggleText, setToggleText] = useState('Crawlery');
    const [templatesVisible, setTemplatesVisible] = useState(false);
    const [crawlersVisible, setCrawlersVisible] = useState(true);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    const handleToggleCrawlerTemplateActive = (templateId) =>
        dispatch(toggleCrawlerTemplateEnabledAndRefreshCrawlerTemplatesActionCreator(templateId));

    const handleToggleCrawlerActive = (crawlerId) =>
        dispatch(toggleCrawlerEnabledAndRefreshAllCrawlersActionCreator(crawlerId));

    const handleDeleteCrawler = (crawlerId) => {
        dispatch(startLoadingActionCreator())
            .then(() => dispatch(deleteCrawlerAndRefreshAllCrawlersActionCreator(crawlerId)))
            .then(() => dispatch(stopLoadingActionCreator()));
    };

    const onSetCrawlersVisible = () => {
        dispatch(getAllCrawlersActionCreator());
        setCrawlersVisible(true);
        setToggleText('Crawlery');
        setTemplatesVisible(false);
    };

    const onSetTemplatesVisible = () => {
        dispatch(getCrawlerTemplatesActionCreator());
        setCrawlersVisible(false);
        setToggleText('Szablony');
        setTemplatesVisible(true);
    };

    useEffect(() => {
        templatesVisible && dispatch(getCrawlerTemplatesActionCreator());
        crawlersVisible && dispatch(getAllCrawlersActionCreator());
    }, [crawlersVisible, dispatch, templatesVisible]);

    return (
        <Page name={name}>
            <GlobalSpinner />
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>{toggleText}</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={onSetTemplatesVisible}>Szablony</DropdownItem>
                    <DropdownItem onClick={onSetCrawlersVisible}>Crawlery</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            {templatesVisible && (
                <CrawlerTemplatesTable
                    data={templates}
                    onToggleCrawlerTemplateActive={handleToggleCrawlerTemplateActive}
                />
            )}
            {crawlersVisible && (
                <CrawlersTable
                    data={crawlers}
                    onToggleCrawlerActive={handleToggleCrawlerActive}
                    onDeleteItem={handleDeleteCrawler}
                />
            )}
        </Page>
    );
};

export default ManageCrawlers;
