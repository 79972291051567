import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddCrawler from './Add/AddCrawler';
import EditCrawler from './Edit/EditCrawler';
import ManageProjectCrawlers from './Manage/ManageProjectCrawlers';

const ProjectCrawlers = () => (
    <Switch>
        <Route
            path="/projects/:projectId/crawlers/manage"
            exact
            render={(props) => <ManageProjectCrawlers name="Zarządzaj crawlerami" {...props} />}
        />
        <Route
            path="/projects/:projectId/crawlers/add"
            exact
            render={(props) => <AddCrawler name="Dodaj crawler" {...props} />}
        />
        <Route
            path="/projects/:projectId/crawlers/:crawlerId/edit"
            exact
            render={(props) => <EditCrawler parentUrl="/projects/manage" {...props} />}
        />
        <Route
            exact
            path="/projects/:projectId/crawlers"
            render={({ match }) => <Redirect to={`/projects/${match.params.projectId}/crawlers/manage`} />}
        />
    </Switch>
);

export default ProjectCrawlers;
