import { LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, REFRESH_ERROR, REFRESH_SUCCESS } from './AuthenticationActions';

function authenticationReducer(state = {}, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                jwt: action.jwt,
                jwtEncoded: action.jwtEncoded,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                jwt: null,
                jwtEncoded: null,
                error: action.error,
            };
        case REFRESH_SUCCESS:
            return {
                ...state,
                jwt: action.jwt,
                jwtEncoded: action.jwtEncoded,
            };
        case REFRESH_ERROR:
            return {
                ...state,
                jwt: null,
                jwtEncoded: null,
            };
        case LOGOUT:
            return {
                ...state,
                jwt: null,
                jwtEncoded: null,
            };
        default:
            console.debug('authenticationReducer :: hit default', action.type);
            return state;
    }
}

export default authenticationReducer;
