import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from '../../../../../components/Table/ActionButtons/DeleteButton';
import Table from '../../../../../components/Table/Table';

const ProjectKeywordsTable = ({ onDeleteItem, data }) => {
    const columns = [
        {
            Header: 'Słowo kluczowe',
            accessor: 'keyword',
        },
        {
            Header: 'Utworzono',
            accessor: 'created',
        },
        {
            header: '',
            id: 'delete',
            Cell: ({ row }) => <DeleteButton onDelete={() => onDeleteItem(row._original.projectSearchKeywordId)} />,
        },
    ];

    return <Table data={data} columns={columns} />;
};

ProjectKeywordsTable.propTypes = {
    data: PropTypes.array.isRequired,
    onDeleteItem: PropTypes.func.isRequired,
};

export default ProjectKeywordsTable;
