import { reset } from 'redux-form';

import handleError from '../../infrastructure/api/ApiErrorHandler';
import { success } from '../../infrastructure/notifications/Notifications';
import { getClientsActionCreator } from '../client/ClientActions';
import * as ReportApiClient from './ReportApiClients';

export const ADD_REPORT = 'ADD_REPORT';
export const ADD_KEYWORD = 'ADD_KEYWORD';
export const TOGGLE_REPORT_ENABLED = 'TOGGLE_REPORT_ENABLED';
export const EDIT_REPORT = 'EDIT_REPORT';
export const GET_REPORT = 'GET_REPORT';
export const GET_REPORTS = 'GET_REPORTS';
export const DELETE_REPORT = 'DELETE_REPORT';
export const ASSIGN_USER_TO_REPORT = 'ASSIGN_USER_TO_REPORT';
export const REVOKE_USER_FROM_REPORT = 'REVOKE_USER_FROM_REPORT';

export function assignClientToReport(clientId, reportId) {
    return (dispatch, getState) => {
        return ReportApiClient.assignClient(clientId, reportId, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch({
                    type: ASSIGN_USER_TO_REPORT,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function revokeClientFromReport(clientId, reportId) {
    return (dispatch, getState) => {
        return ReportApiClient.revokeClient(clientId, reportId, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch({
                    type: REVOKE_USER_FROM_REPORT,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function toggleClientAssignedAndRefreshClientsActionCreator(clientId, reportId, value) {
    return (dispatch) => {
        return value === true
            ? dispatch(assignClientToReport(clientId, reportId)).then(() => {
                  return dispatch(getClientsActionCreator());
              })
            : dispatch(revokeClientFromReport(clientId, reportId)).then(() => {
                  return dispatch(getClientsActionCreator());
              });
    };
}

export function addReportActionCreator(data, history) {
    return (dispatch, getState) => {
        return ReportApiClient.add(data, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                history.push('/reports');
                dispatch(success({ message: `Dodano report: ${response.data.name}` }));
                dispatch({
                    type: ADD_REPORT,
                    report: response.data,
                });
            })
            .catch((error) => {
                handleError(error, dispatch);
            });
    };
}

export function editReportActionCreator(data, reportId) {
    return (dispatch, getState) => {
        return ReportApiClient.edit(data, reportId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch(success({ message: `Zaktualizowano report: ${response.data.name}` }));
                dispatch(reset('editReport'));
                dispatch({
                    type: EDIT_REPORT,
                    report: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function getReportActionCreator(reportId) {
    return (dispatch, getState) => {
        ReportApiClient.getReport(reportId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_REPORT,
                    report: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteReportActionCreator(id, name) {
    return (dispatch, getState) => {
        return ReportApiClient.deleteReport(id, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch(success({ message: `Usunięto raport ${name} o id ${id}` }));
                dispatch({
                    type: DELETE_REPORT,
                    deletedReportId: id,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteReportAndRefreshReportsActionCreator(id, login) {
    return (dispatch) => {
        return dispatch(deleteReportActionCreator(id, login)).then(() => {
            return dispatch(getReportsActionCreator());
        });
    };
}

export function getReportsActionCreator() {
    return (dispatch, getState) => {
        return ReportApiClient.getReports(getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_REPORTS,
                    reports: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}
