import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Switch } from '../../../../../components/Switch/Switch';
import EditButton from '../../../../../components/Table/ActionButtons/EditButton';
import Table from '../../../../../components/Table/Table';
import { delaysArray } from '../../../../../domain/crawler/Delay';
import { basicFilter, filterWithAllAndCustom, SelectWithAllTrueFalse } from '../../../../../tools/tableHelpers';

const ProjectCrawlersTable = ({ data }) => {
    const history = useHistory();

    const renderEditButton = (projectId, crawlerId) => (
        <div className="text-right">
            <EditButton onEdit={() => history.push(`/projects/${projectId}/crawlers/${crawlerId}/edit`)} />
        </div>
    );

    const renderActiveCell = (row) => <Switch value={row.value} disabled={true} />;

    const renderDelayFilter = ({ filter, onChange }) => (
        <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
        >
            <option value="all">Wszystkie</option>
            {delaysArray.map((delay, index) => (
                <option key={index} value={delay.key}>
                    {delay.value}
                </option>
            ))}
        </select>
    );

    const columns = [
        {
            Header: 'Typ',
            accessor: 'crawlerTypeName',
        },
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Aktywny',
            accessor: 'active',
            Cell: renderActiveCell,
            filterMethod: basicFilter,
            Filter: SelectWithAllTrueFalse,
        },
        {
            Header: 'Częstotliwość',
            accessor: 'delay',
            filterMethod: filterWithAllAndCustom,
            Filter: renderDelayFilter,
        },
        {
            Header: 'Ostatnie uruchomienie',
            accessor: 'lastRun',
        },
        {
            Header: 'Kolejne uruchomienie',
            accessor: 'nextRun',
        },
        {
            header: 'Actions',
            id: 'actions',
            filterable: false,
            sortable: false,
            Cell: ({ row }) => renderEditButton(row._original.projectId, row._original.id),
        },
    ];

    return <Table data={data} columns={columns} />;
};

ProjectCrawlersTable.propTypes = {
    data: PropTypes.array,
};

export default ProjectCrawlersTable;
