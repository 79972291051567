import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import CleanInput from '../../../../../components/Form/FormGroup/CleanInput';

const EditRowForm = ({ title, titleName }) => (
    <>
        <h5>{title}</h5>
        <Field component={CleanInput} name={titleName} type="text" />
    </>
);

EditRowForm.propTypes = {
    title: PropTypes.string.isRequired,
    titleName: PropTypes.string.isRequired,
};

export default EditRowForm;
