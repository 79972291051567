import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const withAnonymous = (WrappedComponent, props) => {
    const AnonymousComponent = () => {
        const history = useHistory();
        const jwt = useSelector((state) => state.authenticationReducer.jwt);

        useEffect(() => {
            if (jwt) {
                history.push('/dashboard');
            }
        }, [history, jwt]);

        return <WrappedComponent {...props} />;
    };

    return AnonymousComponent;
};

export const withAuthorize = (WrappedComponent, props) => {
    const AuthorizedComponent = () => {
        const history = useHistory();
        const jwt = useSelector((state) => state.authenticationReducer.jwt);

        useEffect(() => {
            if (!jwt) {
                history.push('/login');
            }
        }, [history, jwt]);

        return <WrappedComponent {...props} />;
    };

    return AuthorizedComponent;
};
