import './BackButton.scss';

import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

const BackButton = () => {
    const history = useHistory();

    return (
        <Button onClick={history.goBack} type="submit" color="primary" className="back-button flex-center">
            <i className="fa fa-arrow-left" />
        </Button>
    );
};

export default BackButton;
