import PropTypes from 'prop-types';
import React from 'react';
import { CardDeck } from 'reactstrap';

import Template from '../Template';
import style from './style.module.scss';

const Templates = ({ selectedTemplate, templates, handleSelected }) => (
    <CardDeck className={style.wrapper}>
        {templates.map((template) => (
            <div key={template.crawlerTemplateId} className={`mt-3 ${style.item}`}>
                <Template
                    template={template}
                    selected={selectedTemplate && selectedTemplate.crawlerTemplateId === template.crawlerTemplateId}
                    handleSelected={handleSelected}
                />
            </div>
        ))}
    </CardDeck>
);

Templates.propTypes = {
    templates: PropTypes.array.isRequired,
    selectedTemplate: PropTypes.object,
    handleSelected: PropTypes.func.isRequired,
};

export default Templates;
