import * as React from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';

import { HelpBlock } from '../HelpBlock/HelpBlock';
import { Required } from '../Required/Required';
import { ValidationBlock } from '../ValidationBlock/ValidationBlock';

export const TextFormGroup = ({
    input,
    type,
    placeholder,
    meta: { touched, error },
    help,
    value,
    required,
    ...custom
}) => {
    const { name } = input;

    return (
        <FormGroup row>
            <Col xs="12" sm="4" md="4" lg="2">
                <Label htmlFor={name}>
                    {placeholder} <Required required={required} />
                </Label>
            </Col>
            <Col>
                <Input
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    type={type}
                    value={value}
                    {...input}
                    {...custom}
                    invalid={touched && error}
                />
                <HelpBlock help={help} />
                <ValidationBlock error={touched && error} />
            </Col>
        </FormGroup>
    );
};
