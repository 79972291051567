import axios from 'axios';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';

export async function add(data, token) {
    return axios.post(`${API_URL}/projects`, data, {
        headers: createHeaders(token),
    });
}

export async function edit(data, id, token) {
    return axios.put(`${API_URL}/projects/${id}/description`, data, {
        headers: createHeaders(token),
    });
}

export async function addKeyword(data, projectId, token) {
    return axios.post(`${API_URL}/projects/${projectId}/keywords`, data, {
        headers: createHeaders(token),
    });
}

export async function deleteKeyword(projectId, keywordId, token) {
    return axios.delete(`${API_URL}/projects/${projectId}/keywords/${keywordId}`, {
        headers: createHeaders(token),
    });
}

export async function getProjects(token) {
    return axios.get(`${API_URL}/projects`, {
        headers: createHeaders(token),
    });
}

export async function getProject(id, token) {
    return axios.get(`${API_URL}/projects/${id}`, {
        headers: createHeaders(token),
    });
}

export async function getProjectKeywords(id, token) {
    return axios.get(`${API_URL}/projects/${id}/keywords`, {
        headers: createHeaders(token),
    });
}

export async function toggleProjectActive(id, token) {
    return axios.post(`${API_URL}/projects/${id}/toggleActive`, null, {
        headers: createHeaders(token),
    });
}

export async function assignUser(userId, projectId, token) {
    return axios.post(
        `${API_URL}/projects/${projectId}/assignedUsers`,
        { userId: userId },
        {
            headers: createHeaders(token),
        },
    );
}

export async function revokeUser(userId, projectId, token) {
    return axios.delete(`${API_URL}/projects/${projectId}/assignedUsers/${userId}`, {
        headers: createHeaders(token),
    });
}

export async function deleteProject(id, token) {
    return axios.delete(`${API_URL}/projects/${id}`, {
        headers: createHeaders(token),
    });
}
