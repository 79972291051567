import * as Notifications from 'react-notification-system-redux';
import { reset } from 'redux-form';

import handleError from '../../infrastructure/api/ApiErrorHandler';
import * as ClientsApiClient from './ClientApiClient';

export const ADD_CLIENT = 'ADD_CLIENT';
export const TOGGLE_CLIENT_ENABLED = 'TOGGLE_CLIENT_ENABLED';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const GET_CLIENT = 'GET_CLIENT';
export const GET_CLIENTS = 'GET_CLIENTS';
export const DELETE_CLIENT = 'DELETE_CLIENT';

export function addClientActionCreator(data, history) {
    return (dispatch, getState) => {
        return ClientsApiClient.add(data, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                history.push('/clients');
                dispatch(Notifications.success({ message: `Dodano klienta: ${response.data.login}` }));
                dispatch({
                    type: ADD_CLIENT,
                    user: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function editClientActionCreator(data, userId) {
    return (dispatch, getState) => {
        return ClientsApiClient.edit(data, userId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch(Notifications.success({ message: `Zaktualizowano dane klienta: ${response.data.login}` }));
                dispatch(reset('editClient'));
                dispatch({
                    type: EDIT_CLIENT,
                    user: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function getClientsActionCreator() {
    return (dispatch, getState) => {
        return ClientsApiClient.getClients(getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_CLIENTS,
                    clients: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteClientActionCreator(id, login) {
    return (dispatch, getState) => {
        return ClientsApiClient.deleteClient(id, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch(Notifications.success({ message: `Usunięto klienta ${login} o id ${id}` }));
                dispatch({
                    type: DELETE_CLIENT,
                    deletedClientId: id,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteClientAndRefreshClientsActionCreator(id, login) {
    return (dispatch) => {
        return dispatch(deleteClientActionCreator(id, login)).then(() => {
            return dispatch(getClientsActionCreator());
        });
    };
}
