import PropTypes from 'prop-types';
import React from 'react';

import PageHeader from './elements/PageHeader';

const Page = ({ name, children }) => (
    <>
        {name && <PageHeader name={name} />}
        {children}
    </>
);

Page.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default Page;
