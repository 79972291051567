import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../components/Form/FormGroup/FormGroup';
import SubmittingAware from '../../../../components/SubmittingAware/SubmittingAware';

const validator = (values) => {
    const errors = {};
    const requiredFields = ['login', 'password', 'repeatPassword', 'role'];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Pole wymagane';
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Nieprawidłowy adres email';
    }
    if (values.password && values.repeatPassword && !(values.password === values.repeatPassword)) {
        errors.repeatPassword = 'Hasła muszą być takie same';
    }
    return errors;
};

class AddUserForm extends React.Component {
    render() {
        const { handleSubmit, onSubmit, pristine, reset, submitting } = this.props;
        return (
            <Form onSubmit={handleSubmit(onSubmit)} action="" className="form-horizontal">
                <CardHeader>
                    <strong>Dodaj użytkownika</strong>
                </CardHeader>
                <Card>
                    <CardBody>
                        <Field component={TextFormGroup} name="login" placeholder="Login" type="text" />
                        <Field
                            component={TextFormGroup}
                            name="password"
                            placeholder="Hasło"
                            type="password"
                            help="min. 8 znaków"
                        />
                        <Field
                            component={TextFormGroup}
                            name="repeatPassword"
                            placeholder="Powtórz hasło"
                            type="password"
                        />
                        <Field
                            component={TextFormGroup}
                            name="name"
                            placeholder="Nazwa"
                            type="text"
                            help="Imię lub nazwa firmy"
                        />
                        <Field component={TextFormGroup} name="email" placeholder="Email" type="email" />
                        <Field component={TextFormGroup} name="role" type="select" placeholder="Uprawnienia">
                            <option value="USER">Użytkownik</option>
                            <option value="ADMIN">Administrator</option>
                        </Field>
                    </CardBody>
                    <CardFooter>
                        <FormButtons pristine={pristine} submitting={submitting} reset={reset} />
                    </CardFooter>
                </Card>
            </Form>
        );
    }
}

AddUserForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

AddUserForm = reduxForm({
    form: 'addUser',
    validate: validator,
    initialValues: { role: 'USER' },
})(SubmittingAware(AddUserForm));

export default AddUserForm;
