export const delaysMap = new Map([
    ['TEN_MINUTES', '10 minut'],
    ['HALF_HOUR', '30 minut'],
    ['HOUR', '1 godzina'],
    ['SIX_HOURS', '6 godzin'],
    ['DAY', '1 dzień'],
    ['TWO_DAYS', '2 dni'],
    ['WEEK', '1 tydzień'],
    ['TWO_WEEKS', '2 tygodnie'],
    ['FOUR_WEEKS', '4 tygodnie'],
]);

export const delaysArray = Array.from(delaysMap).map((entry) => Object.assign({}, { key: entry[0], value: entry[1] }));
