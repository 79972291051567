import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FormContainer } from '../../../../components/Form/FormContainer/FormContainer';
import Page from '../../../../components/Page';
import { addProjectActionCreator } from '../../../../domain/project/ProjectActions';
import { apiErrorActionCreator } from '../../../../infrastructure/api/ErrorActions';
import AddProjectForm from './AddProjectForm';

class AddProject extends Component {
    onSubmitAddProjectForm = (data) => {
        const body = {
            name: data.name,
            description: data.description ? data.description : null,
        };
        return this.props.addProjectActionCreator(body, this.props.history);
    };

    render() {
        return (
            <Page name={this.props.name}>
                <FormContainer>
                    <AddProjectForm onSubmit={this.onSubmitAddProjectForm} />
                </FormContainer>
            </Page>
        );
    }
}

AddProject.propTypes = {
    history: PropTypes.object.isRequired,
    name: PropTypes.string,
};

export default connect(null, { addProjectActionCreator, apiErrorActionCreator })(AddProject);
