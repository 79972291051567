import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useSWR from 'swr';

import { FormContainer } from '../../../../components/Form/FormContainer/FormContainer';
import PageWithDynamicName from '../../../../components/Page/PageWithDynamicName';
import { editUserActionCreator } from '../../../../domain/user/UserActions';
import { API_URL } from '../../../../environment';
import { REFRESH_0, useFetcher } from '../../../../infrastructure/api/tools';
import EditUserForm from './EditUserForm';

const EditUser = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const fetcher = useFetcher();
    const history = useHistory();

    const { data } = useSWR(`${API_URL}/users/${id}`, fetcher, REFRESH_0);

    const onSubmitEditForm = (data) => {
        const body = {
            login: data.login,
            password: data.password ? data.password : null,
            name: data.name ? data.name : null,
            email: data.email ? data.email : null,
            role: data.role,
            enabled: data.enabled,
        };

        dispatch(editUserActionCreator(body, id));
        history.goBack();
    };

    return (
        <PageWithDynamicName name="Edytuj użytkownika" dynamicElement={data} field="login">
            <FormContainer>
                <EditUserForm onSubmit={onSubmitEditForm} initialValues={data} />
            </FormContainer>
        </PageWithDynamicName>
    );
};

EditUser.propTypes = {
    name: PropTypes.string.isRequired,
};

export default EditUser;
