function mapToRequestBody(data) {
    const classParams = Object.keys(data.formData).filter((property) =>
        isTemplateParameter(property, data.template.templateParameters),
    );
    return classParams.map((prop) => Object.assign({}, { key: prop, value: data.formData[prop] }));
}

function isTemplateParameter(property, templateParameters) {
    return templateParameters.filter((templateParam) => templateParam.name === property).length > 0;
}

export default mapToRequestBody;
