import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import { FormContainer } from '../../../../components/Form/FormContainer/FormContainer';
import Page from '../../../../components/Page';
import { CenteredSpinner } from '../../../../components/Spinner/Spinner';
import { LIST_TYPES } from '../../../../consts/listTypes';
import { addReportActionCreator } from '../../../../domain/report/ReportActions';
import { API_URL } from '../../../../environment';
import { useFetcher } from '../../../../infrastructure/api/tools';
import ListAccordionTitle from '../commons/ListAccordionTitle';
import AddReportForm from './AddReportForm';
import ListsAccordion from './ListAccordions';

const INITIAL_VALUES = { name: '' };

const INITIAL_DATA = {
    [LIST_TYPES.ASPECT_SYNONYMS]: [],
    [LIST_TYPES.OPINION_SYNONYMS]: [],
    [LIST_TYPES.STOP_LISTS]: [],
    [LIST_TYPES.ASPECTS_TO_IGNORE]: [],
};

const getListId = (el) => el.id;

const prepareValue = ({ mapping }, type) => ({
    [type]: mapping && mapping[type] ? [mapping[type]] : [],
});

const AddReport = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const fetcher = useFetcher();

    const { data: aspectSynonyms } = useSWR(`${API_URL}/lists/${LIST_TYPES.ASPECT_SYNONYMS}`, fetcher);
    const { data: opinionSynonyms } = useSWR(`${API_URL}/lists/${LIST_TYPES.OPINION_SYNONYMS}`, fetcher);
    const { data: stopLists } = useSWR(`${API_URL}/lists/${LIST_TYPES.STOP_LISTS}`, fetcher);
    const { data: aspectsToIgnore } = useSWR(`${API_URL}/lists/${LIST_TYPES.ASPECTS_TO_IGNORE}`, fetcher);
    const { data: generic } = useSWR(`${API_URL}/lists/generic`, fetcher, { refreshInterval: 0 });

    const fullListsMap = new Map([
        [LIST_TYPES.ASPECT_SYNONYMS, aspectSynonyms],
        [LIST_TYPES.OPINION_SYNONYMS, opinionSynonyms],
        [LIST_TYPES.STOP_LISTS, stopLists],
        [LIST_TYPES.ASPECTS_TO_IGNORE, aspectsToIgnore],
    ]);

    const [lists, setLists] = useState(INITIAL_DATA);

    useEffect(() => {
        if (generic) {
            const newData = {
                ...prepareValue(generic, LIST_TYPES.ASPECT_SYNONYMS),
                ...prepareValue(generic, LIST_TYPES.ASPECTS_TO_IGNORE),
                ...prepareValue(generic, LIST_TYPES.OPINION_SYNONYMS),
                ...prepareValue(generic, LIST_TYPES.STOP_LISTS),
            };

            setLists({ ...lists, ...newData });
        }
        /* eslint-disable */
    }, [generic]);
    /* eslint-enable */

    const onSubmitList = (type) => (values) => {
        const selectedId = parseInt(values[type]);
        const fullListsForType = fullListsMap.get(type);

        const fullValue = fullListsForType.find((el) => el.id === selectedId);
        const newData = [...lists[type], fullValue];
        setLists({ ...lists, [type]: newData });
    };

    const onDeleteList = (type) => (value) => {
        const newData = lists[type].filter((el) => el.id !== value.id);
        setLists({ ...lists, [type]: newData });
    };

    const onSubmitAddReportForm = (data) => {
        const body = {
            name: data.name,
            aspectSynonyms: lists[LIST_TYPES.ASPECT_SYNONYMS].map(getListId),
            opinionSynonyms: lists[LIST_TYPES.OPINION_SYNONYMS].map(getListId),
            aspectsToIgnore: lists[LIST_TYPES.ASPECTS_TO_IGNORE].map(getListId),
            sentimentWordsToIgnore: lists[LIST_TYPES.STOP_LISTS].map(getListId),
        };

        return dispatch(addReportActionCreator(body, history));
    };

    const areFullListReady = aspectSynonyms && aspectsToIgnore && stopLists && opinionSynonyms && generic;

    return (
        <Page name="Dodaj raport">
            <FormContainer>
                <AddReportForm onSubmit={onSubmitAddReportForm} initialValues={INITIAL_VALUES} />
                <ListAccordionTitle />
                {areFullListReady ? (
                    <ListsAccordion onSubmit={onSubmitList} onDelete={onDeleteList} data={lists} />
                ) : (
                    <CenteredSpinner />
                )}
            </FormContainer>
        </Page>
    );
};

export default AddReport;
