import { CSidebar, CSidebarClose, CSidebarNav } from '@coreui/react';
import React from 'react';
import { useSelector } from 'react-redux';

import * as AuthorityService from '../../domain/security/AuthorityService/AuthorityService';
import { Jwt } from '../../domain/security/model/Jwt';
import NAVS from './_nav';
import DropdownChild from './elements/DropdownChild';
import DropdownParent from './elements/DropdownParent';
import NavLink from './elements/NavLink';

const buildNavs = (navs, pathname, jwt, isDropdownChild) => {
    let elements = [];

    for (let element of navs) {
        const hasAuthority = AuthorityService.hasAuthority(jwt.authority, element.authority);

        if (hasAuthority) {
            if (isDropdownChild && !element.children) {
                elements.push(<DropdownChild {...element} pathname={pathname} key={element.url} />);
            } else if (element.children) {
                const children = buildNavs(element.children, pathname, jwt, true);
                const tmp = (
                    <DropdownParent parent={element} pathname={pathname} key={element.name}>
                        {children}
                    </DropdownParent>
                );

                elements = [...elements, tmp];
            } else {
                elements.push(<NavLink {...element} pathname={pathname} key={element.url} />);
            }
        }
    }

    return elements;
};

const Sidebar = ({ toggleSidebar, showSidebar }) => {
    const jwt = useSelector((state) => new Jwt(state.authenticationReducer.jwt));

    return (
        <CSidebar overlaid={false} show={showSidebar}>
            <CSidebarClose onClick={toggleSidebar} />
            <CSidebarNav>{buildNavs(NAVS.items, window.location.href, jwt, false)}</CSidebarNav>
        </CSidebar>
    );
};
export default Sidebar;
