import * as React from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import { Required } from '../Required/Required';
import { ValidationBlock } from '../ValidationBlock/ValidationBlock';
import customStyles from './customStyles';

// EXAMPLE OPTIONS, but 'value' could be custom - it's 'name' from props
// const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
// ];

const ReactSelect = ({
    input,
    placeholder,
    meta: { touched, error },
    options,
    required,
    withLabel = true,
    dataKey,
    ...custom
}) => {
    const { name, value } = input;

    const getSelectedOption = () => (value[dataKey] ? value : null);

    const onChange = (data) => input.onChange(data);

    const onBlur = () => input.onBlur(input.value);

    return (
        <FormGroup>
            {withLabel ? (
                <Label htmlFor={name} className="mb-2">
                    <span className="text-gray">{placeholder}</span>
                    <Required required={required} />
                </Label>
            ) : null}

            <Select
                styles={customStyles}
                options={options}
                name={name}
                id={name}
                placeholder={placeholder}
                {...input}
                value={getSelectedOption()}
                onChange={onChange}
                onBlur={onBlur}
                {...custom}
            />

            <ValidationBlock error={touched && error} />
        </FormGroup>
    );
};

export default ReactSelect;
