import * as React from 'react';
import { Input } from 'reactstrap';

import { HelpBlock } from '../HelpBlock/HelpBlock';
import { ValidationBlock } from '../ValidationBlock/ValidationBlock';

const CleanInput = ({ input, name, type, placeholder, meta: { touched, error }, help, value, required, ...custom }) => (
    <>
        <Input
            name={name}
            id={name}
            placeholder={placeholder}
            type={type}
            value={value}
            {...input}
            {...custom}
            invalid={touched && error}
        />
        <HelpBlock help={help} />
        <ValidationBlock error={touched && error} />
    </>
);

export default CleanInput;
