import axios from 'axios';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';
import { convertToParams } from './FilterConverter';

export const getProjectTexts = async (id, token, filter) => {
    const params = convertToParams(filter);

    return axios.get(`${API_URL}/projects/${id}/texts`, {
        headers: createHeaders(token),
        params: params,
    });
};
