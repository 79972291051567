import PropTypes from 'prop-types';
import React from 'react';

import AllActionButtons from '../../../../../components/Table/ActionButtons/AllButtons';
import Table from '../../../../../components/Table/Table';
import AddButton from '../../../../../customComponents/AddButton';
import * as ListApiClient from '../../../../../domain/list/ListApiClient';
import { useListHandleRequest } from '../../../../../infrastructure/api/hooks';
import EditOneElementPopup from '../EditOneElementPopup';
import { prepareCurrentEditData } from '../helpers';
import { useListPopup } from '../hooks';

const ListWithWordTable = ({ data, mutate, type }) => {
    const onSubmitAdd = useListHandleRequest(type, ListApiClient.addWord, mutate, 'Słowo zostało dodane.');

    const onSubmitEditAction = useListHandleRequest(
        type,
        ListApiClient.editWord,
        mutate,
        'Słowo zostało zaktualizowane.',
    );

    const onDelete = useListHandleRequest(type, ListApiClient.deleteWord, mutate, 'Słowo została usunięte.');

    const onSubmitEdit = async ({ word, wordId }) => await onSubmitEditAction({ newValue: word }, { wordId });

    const { onSubmit, onAdd, onEdit, showModal, currentEditData, toggle } = useListPopup(
        '',
        onSubmitAdd,
        onSubmitEdit,
        'editOneElementPopupForm',
    );

    const renderActionCell = ({ original }) => (
        <AllActionButtons onEdit={onEdit(original)} onDelete={() => onDelete(original)} />
    );

    const columns = [
        {
            Header: 'Słowo',
            accessor: 'value',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: renderActionCell,
        },
    ];

    return (
        <>
            <EditOneElementPopup
                onClose={toggle}
                show={showModal}
                initialValues={prepareCurrentEditData(currentEditData, 'word')}
                onSubmit={onSubmit}
                title="Słowo"
                titleName="word"
            />
            <AddButton onClick={onAdd}>Nowy</AddButton>
            <Table data={data} columns={columns} />
        </>
    );
};

ListWithWordTable.propTypes = {
    data: PropTypes.array.isRequired,
    mutate: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default ListWithWordTable;
