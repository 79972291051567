import NAVS from './_nav';

export const getDashboardElements = (navs = NAVS.items) => {
    let elements = [];

    for (let element of navs) {
        if (element.showOnDashboard) {
            elements.push(element);
        } else if (element.children) {
            const tmp = getDashboardElements(element.children);
            elements = [...elements, ...tmp];
        }
    }

    return elements;
};
