import './Tile.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const Tile = ({ title, description, navItem }) => {
    const tileClass = classNames('tile', 'text-center');
    const iconClass = classNames(navItem.icon, 'mr-2');

    return (
        <div className={tileClass}>
            <div className="icon-wrapper">
                <i className={iconClass} />
            </div>
            <h2 className="title">{title}</h2>
            <p className="mt-3">{description}</p>
        </div>
    );
};

Tile.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    navItem: PropTypes.object.isRequired,
};
