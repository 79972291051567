import style from './style.module.scss';

export const convertCamelCaseToSpaces = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    const final = result.charAt(0).toUpperCase() + result.slice(1);
    return final;
};

const COLORS = [style.yellow, style.cyan, style.blue, style.pink, style.green, style.orange, style.teal, style.indigo];

export const prepareEventMapping = (data) => {
    const colorsEventMapping = {};
    const maxIndex = COLORS.length - 1;
    let lastUsedIndex = 0;

    for (const { event } of data) {
        const { processId } = event;

        if (!colorsEventMapping[processId]) {
            colorsEventMapping[processId] = COLORS[lastUsedIndex];
            lastUsedIndex++;
            console.log(event.processId, lastUsedIndex);
            lastUsedIndex = lastUsedIndex > maxIndex ? 0 : lastUsedIndex;
        }
    }

    return colorsEventMapping;
};
