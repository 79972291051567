import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FormContainer } from '../../../../components/Form/FormContainer/FormContainer';
import Page from '../../../../components/Page';
import { manageProfileActionCreator } from '../../../../domain/profile/ProfileActions';
import { getUserActionCreator } from '../../../../domain/user/UserActions';
import ManageProfileForm from './ManageProfileForm';

const ManageProfile = ({ name }) => {
    const loggedInUserId = useSelector((state) => state.authenticationReducer.jwt.id);
    const dispatch = useDispatch();

    const onSubmitManageProfileForm = (data) => {
        const body = {
            password: data.password ? data.password : null,
            email: data.email ? data.email : null,
        };
        return dispatch(manageProfileActionCreator(body, loggedInUserId));
    };

    return (
        <Page name={name}>
            <FormContainer>
                <ManageProfileForm
                    onSubmit={onSubmitManageProfileForm}
                    resourceId={loggedInUserId}
                    fetchData={getUserActionCreator}
                />
            </FormContainer>
        </Page>
    );
};

ManageProfile.propTypes = {
    name: PropTypes.string,
};

export default ManageProfile;
