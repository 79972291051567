import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddReport from './Add';
import ManageReports from './Manage';
import ReportPage from './Manage/ReportPage';

const Reports = () => (
    <Switch>
        <Route path="/reports/manage" render={(props) => <ManageReports name="Zarządzaj raportami" {...props} />} />
        <Route path="/reports/add" component={AddReport} />
        <Route path="/reports/:reportId" exact component={ReportPage} />
        <Redirect from="/reports" to="/reports/manage" />
    </Switch>
);

export default Reports;
