import { useSelector } from 'react-redux';

import { createHeaders } from './Headers';

export const REFRESH_0 = { refreshInterval: 0 };

export const fetcher = (token) => (url) => fetch(url, { headers: createHeaders(token) }).then((r) => r.json());

export const useToken = () => {
    const token = useSelector((state) => state.authenticationReducer.jwtEncoded);
    return token;
};

export const useFetcher = () => {
    const token = useSelector((state) => state.authenticationReducer.jwtEncoded);
    return fetcher(token);
};
