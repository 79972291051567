import axios from 'axios';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';

export async function getTemplates(token) {
    return axios.get(`${API_URL}/crawlers/templates`, {
        headers: createHeaders(token),
    });
}

export async function toggleCrawlerTemplateActive(id, token) {
    return axios.post(`${API_URL}/crawlers/templates/${id}/toggleActive`, null, {
        headers: createHeaders(token),
    });
}
