function mapString(parameter) {
    return { [parameter.name]: parameter.defaultValue };
}

function mapBoolean(parameter) {
    return { [parameter.name]: parameter.defaultValue === 'true' };
}

function toDefaultObject(parameter) {
    switch (parameter.parameterType) {
        case 'BOOLEAN':
            return mapBoolean(parameter);
        default:
            return mapString(parameter);
    }
}

function mapToInitialValues(template) {
    const parametersHavingDefaultValue = template.templateParameters.filter((parameter) => parameter.defaultValue);
    return parametersHavingDefaultValue
        .map((parameter) => toDefaultObject(parameter))
        .reduce((accumulator, currentValue) => Object.assign(accumulator, currentValue), {});
}

export default mapToInitialValues;
