import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    Row,
} from 'reactstrap';

import { loginActionCreator } from '../../domain/security/AuthenticationActions';

class Login extends Component {
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.loginActionCreator(
            { username: this.state.login, password: this.state.password },
            this.props.history,
        );
    };
    errorMessage = () => {
        if (this.props.errorMessage) {
            return <Alert color="danger">{this.props.errorMessage}</Alert>;
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
        };
    }

    validateForm() {
        return this.state.login.length > 0 && this.state.password.length > 0;
    }

    render() {
        return (
            <div className="c-app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="4">
                            <Card className="p-4 text-center">
                                <CardBody>
                                    <h1>Login</h1>
                                    <p className="text-muted">Zaloguj się</p>
                                    <Form onSubmit={this.handleSubmit}>
                                        <InputGroup className="mb-3 align-center">
                                            <InputGroupAddon>
                                                <i className="icon-user mr-3"> </i>
                                            </InputGroupAddon>
                                            <Input
                                                name="login"
                                                type="text"
                                                autoFocus
                                                value={this.state.login}
                                                onChange={this.handleInputChange}
                                                placeholder="login"
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-4 align-center">
                                            <InputGroupAddon>
                                                <i className="icon-lock mr-3"> </i>
                                            </InputGroupAddon>
                                            <Input
                                                name="password"
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.handleInputChange}
                                                placeholder="hasło"
                                            />
                                        </InputGroup>

                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="px-4"
                                            disabled={!this.validateForm()}
                                        >
                                            Login
                                        </Button>
                                    </Form>
                                </CardBody>
                                <Row className="mt-1">
                                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                                        {this.errorMessage()}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { errorMessage: state.authenticationReducer.error };
}

export default connect(mapStateToProps, { loginActionCreator })(withRouter(Login));
