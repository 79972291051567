import React from 'react';

import Table from '../../../../../components/Table/Table';

const ParametersTable = ({ lastRuns = [] }) => {
    const columns = [
        {
            Header: 'Id',
            accessor: 'id',
        },
        {
            Header: 'Planowane rozpoczęcie',
            accessor: 'plannedStartTime',
        },
        {
            Header: 'Rozpoczęcie',
            accessor: 'started',
        },
        {
            Header: 'Koniec',
            accessor: 'ended',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Ostatnia aktywność',
            accessor: 'lastActivity',
        },
        {
            Header: 'Błąd',
            accessor: 'error',
        },
    ];

    return (
        <>
            <h3 className="text-center mb-20">Ostatnie uruchomienia</h3>
            <Table data={lastRuns} columns={columns} />
        </>
    );
};

export default ParametersTable;
