import * as Notifications from 'react-notification-system-redux';

const defaultSettings = { autoDismiss: 10 };

export function success(payload) {
    const notification = Object.assign(defaultSettings, payload);
    return Notifications.success(notification);
}

export function error({ error, message }) {
    const { data } = error.response;
    const notification = {
        ...defaultSettings,
        message,
        title: data.error,
        children: (
            <>
                <div>{data.path}</div>
                <div>{data.exception}</div>
            </>
        ),
    };

    return Notifications.error(notification);
}
