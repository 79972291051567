import axios from 'axios';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';

export async function editProfile(data, id, token) {
    return axios.patch(`${API_URL}/users/${id}`, data, {
        headers: createHeaders(token),
    });
}
