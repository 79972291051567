import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AddListPage from '../commons/AddListPage';
import { LIST_TYPES } from '../commons/consts';
import ListWithTermAllPage from '../commons/ListWithTermAllPage';
import ListWithTermPage from '../commons/ListWithTermPage';

const props = {
    title: 'Synonimy aspektów',
    type: LIST_TYPES.ASPECT_SYNONYMS,
};

const AddAspectSynonyms = () => <AddListPage {...props} />;

const AspectSynonymsAll = () => <ListWithTermAllPage {...props} webUrl="aspectSynonyms" />;

const AspectSynonymsPage = () => <ListWithTermPage {...props} />;

const AspectSynonyms = () => (
    <Switch>
        <Route path="/aspectSynonyms/manage/:id" component={AspectSynonymsPage} />
        <Route path="/aspectSynonyms/add" component={AddAspectSynonyms} />
        <Route path="/aspectSynonyms" component={AspectSynonymsAll} />
    </Switch>
);

export default AspectSynonyms;
