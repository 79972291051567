import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Card, CardBody, Progress } from 'reactstrap';

import Page from '../../../../../components/Page';
import { addCrawlerActionCreator } from '../../../../../domain/crawler/CrawlerActions';
import { getCrawlerTemplatesActionCreator } from '../../../../../domain/crawlerTemplate/CrawlerTemplateActions';
import AddCrawlerForm from './AddCrawlerForm';
import mapToRequestBody from './RequestBodyMapper';
import Templates from './Templates';

const MAX_STEP = 2;
const START_STEP = 1;

const Step1 = ({ templates, selectedTemplate, onTemplateSelected }) => (
    <div className="mt-3">
        <h3>1. Crawler </h3>
        {templates && (
            <Templates templates={templates} selectedTemplate={selectedTemplate} handleSelected={onTemplateSelected} />
        )}
    </div>
);

Step1.propTypes = {
    templates: PropTypes.array.isRequired,
    selectedTemplate: PropTypes.object.isRequired,
    onTemplateSelected: PropTypes.func.isRequired,
};

const Step2 = ({ onSubmitAddCrawlerForm, selectedTemplate }) => (
    <div className="mt-30">
        <h3>2. Parametry</h3>
        <Card className="mt-3">
            <CardBody>
                <AddCrawlerForm
                    onSubmit={onSubmitAddCrawlerForm}
                    template={selectedTemplate}
                    parameters={selectedTemplate.templateParameters}
                />
            </CardBody>
        </Card>
    </div>
);

Step2.propTypes = {
    selectedTemplate: PropTypes.object.isRequired,
    onSubmitAddCrawlerForm: PropTypes.func.isRequired,
};

const AddCrawler = ({ getCrawlerTemplatesActionCreator, addCrawlerActionCreator, name }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [projectId, setProjectId] = useState();
    const [step, setStep] = useState(START_STEP);

    const store = useStore();
    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        setProjectId(parseInt(params.projectId));
        getCrawlerTemplatesActionCreator();
    }, [getCrawlerTemplatesActionCreator, params.projectId]);

    const onSubmitAddCrawlerForm = (data) => {
        const body = {
            crawlerTypeName: data.template.name,
            crawlerName: data.formData.name,
            delay: data.formData.delay,
            parameters: mapToRequestBody(data),
        };

        return addCrawlerActionCreator(body, projectId, history);
    };

    const onTemplateSelected = (template) => {
        setSelectedTemplate(template);
        setStep(MAX_STEP);
    };

    return (
        <Page name={name}>
            <Progress striped animated={true} value={step * 50} />
            <div className="text-right mt-3 text-green">
                Krok {step} / {MAX_STEP}
            </div>

            <Step1
                templates={store.getState().crawlerTemplateReducer.templates}
                selectedTemplate={selectedTemplate}
                onTemplateSelected={onTemplateSelected}
            />

            {selectedTemplate && (
                <Step2 onSubmitAddCrawlerForm={onSubmitAddCrawlerForm} selectedTemplate={selectedTemplate} />
            )}
        </Page>
    );
};

AddCrawler.propTypes = {
    name: PropTypes.string,
    addCrawlerActionCreator: PropTypes.func.isRequired,
    getCrawlerTemplatesActionCreator: PropTypes.func.isRequired,
};

export default connect(null, {
    addCrawlerActionCreator,
    getCrawlerTemplatesActionCreator,
})(AddCrawler);
