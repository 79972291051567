import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import AccordionPanel, { useAccordionPanel } from '../../../../components/AccordionPanel';
import PageWithDynamicName from '../../../../components/Page/PageWithDynamicName';
import RefreshButton from '../../../../components/RefreshButton';
import { getProjectActionCreator } from '../../../../domain/project/ProjectActions';
import ManageProjectCrawlers from '../Crawlers/Manage/ManageProjectCrawlers';
import EditProjectKeywords from '../Edit/Keyword/AddProjectKeywords';

const SECTIONS = {
    CRAWLERS: 'crawlers',
    KEYWORDS: 'keywords',
};

const UserProjectPage = (props) => {
    const { projectId } = useParams();
    const [refresh, setRefresh] = useState({});
    const commonProps = useAccordionPanel('USER_PROJECT_PAGE');
    const dispatch = useDispatch();
    const project = useSelector(({ projectReducer }) => projectReducer.project);

    const basicProps = { ...props, projectId, refresh };

    useEffect(() => {
        dispatch(getProjectActionCreator(projectId));
    }, [dispatch, projectId, refresh]);

    return (
        <PageWithDynamicName name="Edytuj projekt" dynamicElement={project}>
            <RefreshButton onClick={() => setRefresh({})} />
            <AccordionPanel text="Edytuj słowa kluczowe" icon="fa fa-key" id={SECTIONS.KEYWORDS} {...commonProps}>
                <EditProjectKeywords parentUrl="/projects/manage" {...basicProps} />
            </AccordionPanel>

            <AccordionPanel text="Crawlery" icon="fa fa-bug" id={SECTIONS.CRAWLERS} {...commonProps}>
                <ManageProjectCrawlers {...basicProps} />
            </AccordionPanel>
        </PageWithDynamicName>
    );
};

export default UserProjectPage;
