import { error as errorNotification } from '../notifications/Notifications';

export const API_ERROR = 'API_ERROR';

export function apiErrorActionCreator(error, message) {
    return (dispatch) => {
        dispatch(
            errorNotification({
                message,
                error,
            }),
        );
        dispatch({
            type: API_ERROR,
            error,
        });
    };
}

export function redirectActionCreator(history, redirectDestination) {
    return (dispatch) => {
        history.push(redirectDestination);
        dispatch({
            type: API_ERROR,
        });
    };
}
