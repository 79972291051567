import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

import Table from '../../../../components/Table/Table';

const ProcessesTable = ({ data }) => {
    const history = useHistory();

    const renderActionCell = ({ original }) => (
        <div className="text-right">
            <Button onClick={() => history.push(`/processes/manage/${original.processId}`)} color="primary">
                <i className="far fa-eye mr-2" /> Zobacz
            </Button>
        </div>
    );

    const columns = [
        {
            Header: 'Stage',
            accessor: 'stage',
        },
        {
            Header: 'Last updated',
            accessor: 'lastUpdated',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Raport',
            accessor: 'report.sentimentProjectName',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: renderActionCell,
        },
    ];

    return <Table data={data} columns={columns} />;
};

ProcessesTable.propTypes = {
    onDelete: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
};

export default ProcessesTable;
