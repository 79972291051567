import { reducer as notifications } from 'react-notification-system-redux';
import { pendingTasksReducer } from 'react-redux-spinner';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import clientReducer from './domain/client/ClientReducer';
import crawlerReducer from './domain/crawler/CrawlerReducer';
import crawlerTemplateReducer from './domain/crawlerTemplate/CrawlerTemplateReducer';
import profileReducer from './domain/profile/ProfileReducer';
import projectReducer from './domain/project/ProjectReducer';
import reportsReducer from './domain/report/ReportReducer';
import { LOGOUT } from './domain/security/AuthenticationActions';
import authenticationReducer from './domain/security/AuthenticationReducer';
import userReducer from './domain/user/UserReducer';

const reducers = combineReducers({
    authenticationReducer,
    notifications,
    userReducer,
    profileReducer,
    projectReducer,
    crawlerReducer,
    crawlerTemplateReducer,
    clientReducer,
    reportsReducer,
    pendingTasks: pendingTasksReducer,
    form: formReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return reducers(state, action);
};

export default rootReducer;
