import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddProject from './Add/AddProject';
import ProjectCrawlers from './Crawlers/ProjectCrawlers';
import ManageProjects from './Manage/ManageProjects';
import ProjectPage from './Manage/ProjectPage';
import UserProjectPage from './Manage/UserProjectPage';

const Projects = () => (
    <Switch>
        <Route path="/projects/manage" render={(props) => <ManageProjects name="Zarządzaj projektami" {...props} />} />
        <Route path="/projects/add" render={(props) => <AddProject name="Dodaj projekt" {...props} />} />
        <Route path="/projects/:projectId" exact render={(props) => <ProjectPage {...props} />} />
        <Route path="/projects/user/:projectId" exact render={(props) => <UserProjectPage {...props} />} />

        <Route path="/projects/:projectId/crawlers" component={ProjectCrawlers} />
        <Redirect from="/projects" to="/projects/manage" />
    </Switch>
);

export default Projects;
