import PropTypes from 'prop-types';
import React from 'react';

import { Switch } from '../../../../components/Switch/Switch';
import Table from '../../../../components/Table/Table';
import { basicFilter, renderCellWithArrayData, SelectWithAllTrueFalse } from '../../../../tools/tableHelpers';

const AssignClientsTable = ({ onToggleClientAssigned, data }) => {
    const renderAccessCell = (row) => (
        <>
            <Switch
                value={row.original.assigned}
                onToggle={() => onToggleClientAssigned(row.original.id, !row.original.assigned)}
            />
        </>
    );

    const columns = [
        {
            Header: 'Dostęp',
            accessor: 'assigned',
            Cell: renderAccessCell,
            filterMethod: basicFilter,
            Filter: SelectWithAllTrueFalse,
        },
        {
            Header: 'Login',
            accessor: 'login',
        },
        {
            Header: 'Rola',
            accessor: 'roles',
            Cell: renderCellWithArrayData,
        },
        {
            Header: 'Utworzony',
            accessor: 'created',
        },
    ];

    return <Table data={data} columns={columns} />;
};

AssignClientsTable.propTypes = {
    onToggleClientAssigned: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    data: PropTypes.object,
};

export default AssignClientsTable;
