import React from 'react';
import useSWR from 'swr';

import Page from '../../../../components/Page';
import { ICONS } from '../../../../components/Sidebar/_nav';
import { CenteredSpinner } from '../../../../components/Spinner/Spinner';
import { LIST_TYPES } from '../../../../consts/listTypes';
import { API_URL } from '../../../../environment';
import { useFetcher } from '../../../../infrastructure/api/tools';
import ListSelect, { prepareOption } from './ListSelect';

const GenericListsPage = () => {
    const fetcher = useFetcher();
    const { data: generic, mutate } = useSWR(`${API_URL}/lists/generic`, fetcher, { refreshInterval: 0 });

    const prepareProps = (type) => ({
        form: `${type}_ListSelect`,
        type,
        refresh: mutate,
        initialValues: { selectedList: prepareOption(generic.mapping[type]) },
        placeholder: 'Wybierz listę generyczną...',
    });

    return (
        <Page name="Listy generyczne">
            {generic ? (
                <>
                    <ListSelect
                        title="Synonimy aspektów"
                        icon={ICONS.ASPECT_SYNONYMS}
                        {...prepareProps(LIST_TYPES.ASPECT_SYNONYMS)}
                    />
                    <ListSelect
                        title="Synonimy opinii"
                        icon={ICONS.OPINION_SYNONYMS}
                        {...prepareProps(LIST_TYPES.OPINION_SYNONYMS)}
                    />
                    <ListSelect
                        title="Aspekty do ignorowania"
                        icon={ICONS.ASPECTS_TO_IGNORE}
                        {...prepareProps(LIST_TYPES.ASPECTS_TO_IGNORE)}
                    />
                    <ListSelect title="Stoplisty" icon={ICONS.STOP_LISTS} {...prepareProps(LIST_TYPES.STOP_LISTS)} />
                </>
            ) : (
                <CenteredSpinner />
            )}
        </Page>
    );
};

export default GenericListsPage;
