import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import { Switch } from '../../../../components/Switch/Switch';
import AllActionButtons from '../../../../components/Table/ActionButtons/AllButtons';
import Table from '../../../../components/Table/Table';
import { basicFilter, SelectWithAllTrueFalse } from '../../../../tools/tableHelpers';

const AdminProjectsTable = ({ onToggleProjectActive, data, onDeleteProject }) => {
    const history = useHistory();

    const columns = [
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Opis',
            accessor: 'description',
        },
        {
            Header: 'Aktywny',
            accessor: 'active',
            // eslint-disable-next-line react/display-name
            Cell: (row) => <Switch value={row.value} onToggle={() => onToggleProjectActive(row.original.id)} />,
            filterMethod: basicFilter,
            Filter: SelectWithAllTrueFalse,
        },
        {
            Header: 'Utworzony',
            accessor: 'created',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            // eslint-disable-next-line react/display-name
            Cell: ({ original }) => (
                <AllActionButtons
                    onEdit={() => history.push(`/projects/${original.id}`)}
                    onDelete={() => onDeleteProject(original.id, original.name)}
                />
            ),
        },
    ];

    return <Table data={data} columns={columns} />;
};

AdminProjectsTable.propTypes = {
    data: PropTypes.array,
    onDeleteProject: PropTypes.func.isRequired,
    onToggleProjectActive: PropTypes.func.isRequired,
};

export default AdminProjectsTable;
