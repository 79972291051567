import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { FormContainer } from '../../../../components/Form/FormContainer/FormContainer';
import Page from '../../../../components/Page';
import { addClientActionCreator } from '../../../../domain/client/ClientActions';
import AddClientForm from './AddClientForm';

const AddClient = ({ name }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onSubmitAddClientForm = (data) => {
        const body = {
            login: data.login,
            role: data.role,
            password: data.password,
        };

        dispatch(addClientActionCreator(body, history));
    };

    return (
        <Page name={name}>
            <FormContainer>
                <AddClientForm onSubmit={onSubmitAddClientForm} />
            </FormContainer>
        </Page>
    );
};

AddClient.propTypes = {
    name: PropTypes.string,
};

export default AddClient;
