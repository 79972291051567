import React from 'react';

import DeleteButton from './DeleteButton';
import EditButton from './EditButton';

const AllActionButtons = ({ onDelete, onEdit, withText = true }) => {
    return (
        <>
            <div className="text-right">
                <DeleteButton onDelete={onDelete} withText={withText} />
                <EditButton onEdit={onEdit} withText={withText} />
            </div>
        </>
    );
};

AllActionButtons.propTypes = {
    ...EditButton.propTypes,
    ...DeleteButton.propTypes,
};

export default AllActionButtons;
