import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { FormContainer } from '../../../../../components/Form/FormContainer/FormContainer';
import {
    addKeywordToProjectAndRefreshProjectsActionCreator,
    deleteKeywordAndRefreshProjectsActionCreator,
    getProjectKeywordsActionCreator,
} from '../../../../../domain/project/ProjectActions';
import AddProjectKeywordsForm from './AddProjectKeywordsForm';
import ProjectKeywordsTable from './ProjectKeywordsTable';

const AddProjectKeywords = ({ projectId, parentUrl, refresh }) => {
    const keywords = useSelector((state) => state.projectReducer.keywords);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleAddProjectKeywordsForm = (data) => {
        const body = { keyword: data.keyword };
        return dispatch(addKeywordToProjectAndRefreshProjectsActionCreator(body, projectId));
    };

    const handleDeleteKeyword = (keywordId) =>
        dispatch(deleteKeywordAndRefreshProjectsActionCreator(projectId, keywordId));

    useEffect(() => {
        dispatch(getProjectKeywordsActionCreator(projectId, history, parentUrl));
    }, [dispatch, history, parentUrl, projectId, refresh]);

    return (
        <>
            <FormContainer>
                <AddProjectKeywordsForm onSubmit={handleAddProjectKeywordsForm} />
            </FormContainer>
            <ProjectKeywordsTable data={keywords} onDeleteItem={handleDeleteKeyword} />
        </>
    );
};

AddProjectKeywords.propTypes = {
    projectId: PropTypes.string.isRequired,
    parentUrl: PropTypes.string.isRequired,
    refresh: PropTypes.object,
};

export default AddProjectKeywords;
