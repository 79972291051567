import { reset } from 'redux-form';

import handleError from '../../infrastructure/api/ApiErrorHandler';
import { success } from '../../infrastructure/notifications/Notifications';
import { getUsersActionCreator } from '../user/UserActions';
import * as ProjectApiClient from './ProjectApiClient';

export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_KEYWORD = 'ADD_KEYWORD';
export const TOGGLE_PROJECT_ENABLED = 'TOGGLE_PROJECT_ENABLED';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_KEYWORDS = 'GET_PROJECT_KEYWORDS';
export const GET_PROJECTS = 'GET_PROJECTS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_KEYWORD = 'DELETE_KEYWORD';
export const ASSIGN_USER_TO_PROJECT = 'ASSIGN_USER_TO_PROJECT';
export const REVOKE_USER_FROM_PROJECT = 'REVOKE_USER_FROM_PROJECT';

export function assignUserToProject(userId, projectId) {
    return (dispatch, getState) => {
        return ProjectApiClient.assignUser(userId, projectId, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch({
                    type: ASSIGN_USER_TO_PROJECT,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function revokeUserFromProject(userId, projectId) {
    return (dispatch, getState) => {
        return ProjectApiClient.revokeUser(userId, projectId, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch({
                    type: REVOKE_USER_FROM_PROJECT,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function toggleUserAssignedAndRefreshUsersActionCreator(userId, projectId, value) {
    return (dispatch) => {
        return value === true
            ? dispatch(assignUserToProject(userId, projectId)).then(() => {
                  return dispatch(getUsersActionCreator());
              })
            : dispatch(revokeUserFromProject(userId, projectId)).then(() => {
                  return dispatch(getUsersActionCreator());
              });
    };
}

export function addProjectActionCreator(data, history) {
    return (dispatch, getState) => {
        return ProjectApiClient.add(data, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                history.push('/projects');
                dispatch(success({ message: `Dodano projekt: ${response.data.name}` }));
                dispatch({
                    type: ADD_PROJECT,
                    project: response.data,
                });
            })
            .catch((error) => {
                handleError(error, dispatch);
            });
    };
}

export function editProjectActionCreator(data, projectId) {
    return (dispatch, getState) => {
        return ProjectApiClient.edit(data, projectId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch(success({ message: `Zaktualizowano projekt: ${response.data.name}` }));
                dispatch(reset('editProject'));
                dispatch({
                    type: EDIT_PROJECT,
                    project: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function addKeywordToProjectActionCreator(data, projectId) {
    return (dispatch, getState) => {
        return ProjectApiClient.addKeyword(data, projectId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch(success({ message: `Dodano słowo kluczowe: ${data.keyword}` }));
                dispatch(reset('addProjectKeyword'));
                dispatch({
                    type: ADD_KEYWORD,
                    keyword: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function getProjectKeywordsActionCreator(projectId, history, parentUrl) {
    return (dispatch, getState) => {
        ProjectApiClient.getProjectKeywords(projectId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_PROJECT_KEYWORDS,
                    keywords: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch, { history: history, parentUrl: parentUrl }));
    };
}

export function addKeywordToProjectAndRefreshProjectsActionCreator(data, projectId) {
    return (dispatch) => {
        return dispatch(addKeywordToProjectActionCreator(data, projectId)).then(() => {
            return dispatch(getProjectKeywordsActionCreator(projectId));
        });
    };
}

export function deleteKeywordActionCreator(projectId, keywordId) {
    return (dispatch, getState) => {
        return ProjectApiClient.deleteKeyword(projectId, keywordId, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch(success({ message: 'Usunięto słowo kluczowe' }));
                dispatch({
                    type: DELETE_KEYWORD,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteKeywordAndRefreshProjectsActionCreator(projectId, keywordId) {
    return (dispatch) => {
        return dispatch(deleteKeywordActionCreator(projectId, keywordId)).then(() => {
            return dispatch(getProjectKeywordsActionCreator(projectId));
        });
    };
}

export function getProjectActionCreator(projectId) {
    return (dispatch, getState) => {
        ProjectApiClient.getProject(projectId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_PROJECT,
                    project: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteProjectActionCreator(id, name) {
    return (dispatch, getState) => {
        return ProjectApiClient.deleteProject(id, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch(success({ message: `Usunięto projekt ${name} o id ${id}` }));
                dispatch({
                    type: DELETE_PROJECT,
                    deletedProjectId: id,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteProjectAndRefreshProjectsActionCreator(id, login) {
    return (dispatch) => {
        return dispatch(deleteProjectActionCreator(id, login)).then(() => {
            return dispatch(getProjectsActionCreator());
        });
    };
}

export function toggleProjectEnabledAndRefreshProjectsActionCreator(id) {
    return (dispatch) => {
        return dispatch(toggleProjectEnabledActionCreator(id)).then(() => {
            return dispatch(getProjectsActionCreator());
        });
    };
}

export function toggleProjectEnabledActionCreator(projectId) {
    return (dispatch, getState) => {
        return ProjectApiClient.toggleProjectActive(projectId, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch({
                    type: TOGGLE_PROJECT_ENABLED,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function getProjectsActionCreator() {
    return (dispatch, getState) => {
        return ProjectApiClient.getProjects(getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_PROJECTS,
                    projects: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}
