import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CloseButton from '../../CloseButton';

const DeletePopup = ({ show, onClose, onDelete }) => (
    <CModal title="Usuń" color="primary" show={show} centered size="xl" onClose={onClose}>
        <CModalHeader>
            <h4>Usuń dane</h4>
        </CModalHeader>

        <CModalBody className="text-left">Czy chcesz trwale usunąć dane?</CModalBody>

        <CModalFooter>
            <CloseButton onClose={onClose} color="primary" />
            <CButton onClick={onDelete} color="danger">
                <i className="far fa-trash-alt mr-2" /> Usuń
            </CButton>
        </CModalFooter>
    </CModal>
);

const DeleteButton = ({ onDelete, withText = true }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const onClickDelete = async () => {
        toggle();
        await onDelete();
    };

    return (
        <>
            <DeletePopup show={isOpen} onDelete={onClickDelete} onClose={toggle} />
            <CButton color="danger" onClick={toggle} className="d-inline-block mr-3" size="sm">
                <i className="far fa-trash-alt" /> {withText && <span className="ml-2">Usuń</span>}
            </CButton>
        </>
    );
};

DeleteButton.propTypes = {
    onDelete: PropTypes.func.isRequired,
    withText: PropTypes.bool,
};

export default DeleteButton;
