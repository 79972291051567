import 'react-redux-spinner/dist/react-redux-spinner.css';
import './GlobalSpinner.scss';

import React from 'react';
import { Spinner as RRS } from 'react-redux-spinner';

function GlobalSpinner() {
    return <RRS />;
}

export default GlobalSpinner;
