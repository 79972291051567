import React, { Component } from 'react';

import ErrorPage from '../components/ErrorPage/ErrorPage';

const ErrorBoundaryComponentWrapper = (WrappedComponent) => {
    return class Wrapper extends Component {
        constructor(props, context) {
            super(props, context);
            this.state = {
                error: null,
            };
        }

        componentDidCatch(error, errorInfo) {
            this.setState(() => ({ error: error }));
        }

        renderError = () => {
            return (
                <ErrorPage
                    title={'Oops!'}
                    subtitle={'Nieoczekiwany błąd'}
                    comment={'Skontaktuj się z administratorem systemu'}
                />
            );
        };

        render() {
            return this.state.error ? this.renderError() : <WrappedComponent {...this.props} />;
        }
    };
};

export default ErrorBoundaryComponentWrapper;
