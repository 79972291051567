import './Dashboard.scss';

import * as React from 'react';
import { Link } from 'react-router-dom';

import { Tile } from './Tile';

export const NavTile = (props) => (
    <Link to={props.navItem.url} className="dashboard-item no-text-underline">
        <Tile {...props} />
    </Link>
);
