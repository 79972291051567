import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import { Switch } from '../../../../components/Switch/Switch';
import AllActionButtons from '../../../../components/Table/ActionButtons/AllButtons';
import Table from '../../../../components/Table/Table';
import { basicFilter } from '../../../../tools/tableHelpers';

const ActiveFilter = ({ filter, onChange }) => (
    <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
    >
        <option value="all">Wszyscy</option>
        <option value="true">Aktywni</option>
        <option value="false">Nieaktywni</option>
    </select>
);

const UsersTable = ({ data, onToggleUserEnabled, onDeleteUser }) => {
    const history = useHistory();
    const renderActionCell = ({ original }) => (
        <AllActionButtons
            onEdit={() => history.push(`/users/edit/${original.id}`)}
            onDelete={() => onDeleteUser(original.id, original.login)}
            withText={false}
        />
    );

    const Filter = ({ filter, onChange }) => <ActiveFilter filter={filter} onChange={onChange} />;

    const columns = [
        {
            Header: 'Login',
            accessor: 'login',
        },
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Rola',
            accessor: 'role',
        },
        {
            Header: 'Aktywny',
            accessor: 'enabled',
            Cell: (row) => <Switch value={row.value} onToggle={() => onToggleUserEnabled(row.original.id)} />,
            filterMethod: basicFilter,
            Filter,
        },
        {
            Header: 'Utworzony',
            accessor: 'created',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: renderActionCell,
        },
    ];

    return <Table data={data} columns={columns} />;
};

UsersTable.propTypes = {
    onDeleteUser: PropTypes.func.isRequired,
    onToggleUserEnabled: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
};

export default UsersTable;
