import PropTypes from 'prop-types';
import React from 'react';

import { Switch } from '../../../../components/Switch/Switch';
import DeleteButton from '../../../../components/Table/ActionButtons/DeleteButton';
import Table from '../../../../components/Table/Table';
import { delaysArray } from '../../../../domain/crawler/Delay';
import { basicFilter, filterWithAllAndCustom, SelectWithAllTrueFalse } from '../../../../tools/tableHelpers';

const CrawlersTable = ({ onToggleCrawlerActive, onDeleteItem, data }) => {
    const columns = [
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Aktywny',
            accessor: 'active',
            // eslint-disable-next-line react/display-name
            Cell: (row) => (
                <Switch
                    value={row.value}
                    onToggle={() => onToggleCrawlerActive(row.original.id)}
                    disabled={row.original.crawlerType.crawlerClass.active === false}
                />
            ),

            filterMethod: basicFilter,
            Filter: SelectWithAllTrueFalse,
        },
        {
            Header: 'Częstotliwość',
            accessor: 'delay',
            filterMethod: filterWithAllAndCustom,
            // eslint-disable-next-line react/display-name
            Filter: ({ filter, onChange }) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: '100%' }}
                    value={filter ? filter.value : 'all'}
                >
                    <option value="all">Wszystkie</option>
                    {delaysArray.map((delay, index) => (
                        <option key={index} value={delay.key}>
                            {delay.value}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            Header: 'Ostatnie uruchomienie',
            accessor: 'lastRun',
        },
        {
            Header: 'Kolejne uruchomienie',
            accessor: 'nextRun',
        },
        {
            header: '',
            id: 'delete',
            // eslint-disable-next-line react/display-name
            Cell: ({ row }) => (
                <div className="text-right">
                    <DeleteButton onDelete={() => onDeleteItem(row._original.id)} />
                </div>
            ),
        },
    ];

    return <Table data={data} columns={columns} />;
};

CrawlersTable.propTypes = {
    data: PropTypes.array,
    onToggleCrawlerActive: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired,
};

export default CrawlersTable;
