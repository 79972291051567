import PropTypes from 'prop-types';
import React from 'react';
import Notifications from 'react-notification-system-redux';
import { connect } from 'react-redux';

class NotificationContainer extends React.Component {
    render() {
        const { notifications } = this.props;
        return <Notifications notifications={notifications} />;
    }
}

NotificationContainer.contextTypes = {
    store: PropTypes.object,
};

NotificationContainer.propTypes = {
    notifications: PropTypes.array,
};

function mapStateToProps(state) {
    return { notifications: state.notifications };
}

export default connect(mapStateToProps)(NotificationContainer);
