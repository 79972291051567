import jwtDecode from 'jwt-decode';

import * as AuthenticationApiClient from './AuthenticationApiClient';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_ERROR = 'REFRESH_ERROR';
export const LOGOUT = 'LOGOUT';

export function loginActionCreator(credentials, history) {
    return async (dispatch) => {
        try {
            const response = await AuthenticationApiClient.login(credentials);
            const { token } = response.data;
            dispatch({
                type: LOGIN_SUCCESS,
                jwt: jwtDecode(token),
                jwtEncoded: token,
            });
            localStorage.setItem('jwt', token);
            history.push('/dashboard');
        } catch (error) {
            dispatch({
                type: LOGIN_ERROR,
                error: 'Nieprawidłowy login lub hasło',
            });
        }
    };
}

export function refreshTokenActionCreator(jwt) {
    return async (dispatch) => {
        AuthenticationApiClient.refreshToken(jwt)
            .then((response) => {
                const { token } = response.data;
                dispatch({
                    type: REFRESH_SUCCESS,
                    jwt: jwtDecode(token),
                    jwtEncoded: token,
                });
                localStorage.setItem('jwt', token);
            })
            .catch((error) => {
                dispatch({
                    type: REFRESH_ERROR,
                });
                dispatch(logoutActionCreator());
            });
    };
}

export function logoutActionCreator() {
    localStorage.clear();
    return { type: LOGOUT };
}
