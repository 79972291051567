import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import AllActionButtons from '../../../../components/Table/ActionButtons/AllButtons';
import Table from '../../../../components/Table/Table';

const AdminReportsTable = ({ data, onDeleteReport }) => {
    const history = useHistory();

    const renderActionCell = ({ original }) => (
        <AllActionButtons
            onEdit={() => history.push(`/reports/${original.id}`)}
            onDelete={() => onDeleteReport(original.id, original.name)}
        />
    );

    const columns = [
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Utworzony',
            accessor: 'created',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: renderActionCell,
        },
    ];

    return <Table data={data} columns={columns} />;
};

AdminReportsTable.propTypes = {
    data: PropTypes.array,
    onDeleteReport: PropTypes.func.isRequired,
};

export default AdminReportsTable;
