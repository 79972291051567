import * as React from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';

import { HelpBlock } from '../HelpBlock/HelpBlock';
import { ValidationBlock } from '../ValidationBlock/ValidationBlock';

export function SingleInputFormGroup({
    input,
    name,
    type,
    formPristine,
    formSubmitting,
    placeholder,
    meta: { touched, error },
    help,
    buttonText,
    ...custom
}) {
    return (
        <FormGroup row>
            <Col md="3">
                <Label htmlFor={name}>{placeholder}</Label>
            </Col>
            <Col xs="12" md="9">
                <InputGroup>
                    <Input name={name} id={name} placeholder={placeholder} type={type} {...input} {...custom} />

                    <InputGroupAddon addonType="append">
                        <Button color="primary" disabled={formPristine || formSubmitting}>
                            {buttonText}
                        </Button>
                    </InputGroupAddon>
                </InputGroup>

                <HelpBlock help={help} />
                <ValidationBlock error={touched && error} />
            </Col>
        </FormGroup>
    );
}
