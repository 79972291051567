import React from 'react';

const Footer = () => (
    <div>
        <span>
            <a href="http://aspekty.com">aspekty.com</a> &copy; {new Date().getFullYear()}
        </span>
        <span className="ml-auto">
            Powered by <a href="http://coreui.io">CoreUI</a>
        </span>
    </div>
);

export default Footer;
