import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import SingleValueEditBox from '../SingleValueEditBox';

const BOOLEAN_OPTIONS = [
    { key: 'true', value: 'true' },
    { key: 'false', value: 'false' },
];

const Parameters = ({ data, editCrawlerParameter }) => {
    const dispatch = useDispatch();

    return data.crawlerUserParameters.map(({ crawlerClassParameter, value }, index) => {
        const props = {
            initialValue: value,
            sendData: editCrawlerParameter,
            name: crawlerClassParameter.name,
            label: crawlerClassParameter.description,
            dispatch,
        };

        if (crawlerClassParameter.parameterType.type === 'BOOLEAN') {
            return <SingleValueEditBox key={index} type="select" options={BOOLEAN_OPTIONS} {...props} />;
        }

        return <SingleValueEditBox key={index} type="text" {...props} />;
    });
};

Parameters.propTypes = {
    data: PropTypes.array.isRequired,
    editCrawlerParameter: PropTypes.func.isRequired,
};

export default Parameters;
