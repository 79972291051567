import React from 'react';

import Spinner from '../Spinner/Spinner';

export const Loadable = ({ submitting, WrappedComponent }) => (submitting ? <Spinner /> : <WrappedComponent />);

export function SubmittingAware(WrappedComponent) {
    return class Loadable extends WrappedComponent {
        render() {
            if (this.props.submitting || this.props.isSubmitting) {
                return <Spinner />;
            }
            return super.render();
        }
    };
}

export function withSubmittingAware(wrappedRenderFunction) {
    return (props) => {
        if (props.submitting || props.isSubmitting) {
            return <Spinner />;
        }
        return wrappedRenderFunction(props);
    };
}

export default SubmittingAware;
