import './PageHeader.scss';

import PropTypes from 'prop-types';
import React from 'react';

import BackButton from '../BackButton';

const PageHeader = ({ name }) => (
    <div className="mt-20 header mb-34">
        <BackButton />
        <h2 className="ml-3">{name}</h2>
    </div>
);

PageHeader.propTypes = {
    name: PropTypes.string.isRequired,
};

export default PageHeader;
