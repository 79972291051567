import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ManageCrawlers from './Manage/ManageCrawlers';

const Crawlers = () => (
    <Switch>
        <Route path="/crawlers/manage" render={(props) => <ManageCrawlers name="Zarządzaj crawlerami" {...props} />} />
        <Redirect from="/crawlers" to="/crawlers/manage" />
    </Switch>
);

export default Crawlers;
