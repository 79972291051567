import * as Notifications from 'react-notification-system-redux';
import { reset } from 'redux-form';

import handleError from '../../infrastructure/api/ApiErrorHandler';
import * as UsersApiClient from './UserApiClient';

export const ADD_USER = 'ADD_USER';
export const TOGGLE_USER_ENABLED = 'TOGGLE_USER_ENABLED';
export const EDIT_USER = 'EDIT_USER';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';

export function addUserActionCreator(data, history) {
    return (dispatch, getState) => {
        return UsersApiClient.add(data, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                history.push('/users');
                dispatch(Notifications.success({ message: `Dodano użytkownika: ${response.data.login}` }));
                dispatch({
                    type: ADD_USER,
                    user: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function editUserActionCreator(data, userId) {
    return (dispatch, getState) => {
        return UsersApiClient.edit(data, userId, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch(Notifications.success({ message: `Zaktualizowano dane użytkownika: ${response.data.login}` }));
                dispatch(reset('editUser'));
                dispatch({
                    type: EDIT_USER,
                    user: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function getUserActionCreator(id) {
    return (dispatch, getState) => {
        return UsersApiClient.getUser(id, getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_USER,
                    user: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

function toggleUserEnabledActionCreator(id) {
    return (dispatch, getState) => {
        return UsersApiClient.toggleUserEnabled(id, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch({
                    type: TOGGLE_USER_ENABLED,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function getUsersActionCreator() {
    return (dispatch, getState) => {
        return UsersApiClient.getUsers(getState().authenticationReducer.jwtEncoded)
            .then((response) => {
                dispatch({
                    type: GET_USERS,
                    users: response.data,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

function deleteUserActionCreator(id, login) {
    return (dispatch, getState) => {
        return UsersApiClient.deleteUser(id, getState().authenticationReducer.jwtEncoded)
            .then(() => {
                dispatch(Notifications.success({ message: `Usunięto użytkownika ${login} o id ${id}` }));
                dispatch({
                    type: DELETE_USER,
                    deletedUserId: id,
                });
            })
            .catch((error) => handleError(error, dispatch));
    };
}

export function deleteUserAndRefreshUsersActionCreator(id, login) {
    return (dispatch) => {
        return dispatch(deleteUserActionCreator(id, login)).then(() => {
            return dispatch(getUsersActionCreator());
        });
    };
}

export function toggleUserEnabledAndRefreshUsersActionCreator(id) {
    return (dispatch) => {
        return dispatch(toggleUserEnabledActionCreator(id)).then(() => {
            return dispatch(getUsersActionCreator());
        });
    };
}
