import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';

export const useListPopup = (empty, onSubmitAdd, onSubmitEdit, idToReset) => {
    const [showModal, setShowModal] = useState(false);
    const [currentEditData, setCurrentEditData] = useState(empty);
    const dispatch = useDispatch();

    const resetForms = () => idToReset && dispatch(reset(idToReset));

    const toggle = () => {
        setShowModal(!showModal);
        resetForms();
    };

    const onEdit = (original) => () => {
        setCurrentEditData(original);
        toggle();
    };

    const onAdd = () => {
        setCurrentEditData(empty);
        toggle();
    };

    const onSubmit = (values) => {
        if (!Object.keys(currentEditData).length) {
            onSubmitAdd(values);
        } else {
            onSubmitEdit(values);
        }
        setCurrentEditData(empty);
        toggle();
    };

    return { onSubmit, onAdd, onEdit, showModal, currentEditData, toggle };
};
