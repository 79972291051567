import axios from 'axios';

import { API_URL } from '../../environment';
import { createHeaders } from '../../infrastructure/api/Headers';

export const add = async ({ data, token }) =>
    axios.post(`${API_URL}/lists`, data, {
        headers: createHeaders(token),
    });

export const editBasicInfo = async ({ data, token, type, id }) =>
    axios.put(`${API_URL}/lists/${type}/${id}`, data, {
        headers: createHeaders(token),
    });

export const deleteList = async ({ token, type, id }) =>
    axios.delete(`${API_URL}/lists/${type}/${id}`, {
        headers: createHeaders(token),
    });

export const addTerm = async ({ data, token, type, id }) =>
    axios.post(`${API_URL}/lists/${type}/${id}/term`, data, {
        headers: createHeaders(token),
    });

export const editTerm = async ({ data, token, type, termId }) =>
    axios.put(`${API_URL}/lists/${type}/term/${termId}`, data, {
        headers: createHeaders(token),
    });

export const deleteTerm = async ({ token, type, termId, id }) =>
    axios.delete(`${API_URL}/lists/${type}/${id}/term/${termId}`, {
        headers: createHeaders(token),
    });

export const addWord = async ({ data, token, type, id }) =>
    axios.post(`${API_URL}/lists/${type}/${id}/word`, data, {
        headers: createHeaders(token),
    });

export const editWord = async ({ data, token, type, wordId }) =>
    axios.put(`${API_URL}/lists/${type}/word/${wordId}`, data, {
        headers: createHeaders(token),
    });

export const deleteWord = async ({ token, type, wordId, id }) =>
    axios.delete(`${API_URL}/lists/${type}/${id}/word/${wordId}`, {
        headers: createHeaders(token),
    });

export const setListAsGeneric = async ({ token, type, id }) =>
    axios.post(
        `${API_URL}/lists/${type}/${id}/generic`,
        {},
        {
            headers: createHeaders(token),
        },
    );
