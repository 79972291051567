import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import GlobalSpinner from '../../../../components/GlobalSpinner/GlobalSpinner';
import {
    startLoadingActionCreator,
    stopLoadingActionCreator,
} from '../../../../components/GlobalSpinner/GlobalSpinnerActions';
import Page from '../../../../components/Page';
import AddButton from '../../../../customComponents/AddButton';
import {
    deleteProjectAndRefreshProjectsActionCreator,
    getProjectsActionCreator,
    toggleProjectEnabledAndRefreshProjectsActionCreator,
} from '../../../../domain/project/ProjectActions';
import { hasAuthority } from '../../../../domain/security/AuthorityService/AuthorityService';
import AdminProjectsTable from './AdminProjectsTable';
import UserProjectsTable from './UserProjectsTable';

const ManageProjects = ({
    getProjectsActionCreator,
    deleteProjectAndRefreshProjectsActionCreator,
    stopLoadingActionCreator,
    startLoadingActionCreator,
    toggleProjectEnabledAndRefreshProjectsActionCreator,
    name,
    jwt,
    projects,
}) => {
    useEffect(() => {
        getProjectsActionCreator();
    }, [getProjectsActionCreator]);

    const handleDeleteProject = (id, name) => {
        startLoadingActionCreator()
            .then(() => deleteProjectAndRefreshProjectsActionCreator(id, name))
            .then(() => stopLoadingActionCreator());
    };

    const handleToggleProjectActive = (id) => toggleProjectEnabledAndRefreshProjectsActionCreator(id);

    const isAdmin = () => hasAuthority(jwt.authority, ['ROLE_ADMIN']);

    return (
        <Page name={name}>
            {isAdmin ? (
                <div className="mb-2">
                    <AddButton link="/projects/add">Nowy project</AddButton>
                </div>
            ) : null}

            <GlobalSpinner />

            {isAdmin() ? (
                <AdminProjectsTable
                    data={projects}
                    onDeleteProject={handleDeleteProject}
                    onToggleProjectActive={handleToggleProjectActive}
                />
            ) : (
                <UserProjectsTable data={projects} />
            )}
        </Page>
    );
};

ManageProjects.propTypes = {
    getProjectsActionCreator: PropTypes.func.isRequired,
    deleteProjectAndRefreshProjectsActionCreator: PropTypes.func.isRequired,
    stopLoadingActionCreator: PropTypes.func.isRequired,
    startLoadingActionCreator: PropTypes.func.isRequired,
    toggleProjectEnabledAndRefreshProjectsActionCreator: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    jwt: PropTypes.object.isRequired,
    projects: PropTypes.array,
};

const mapStateToProps = (state) => ({
    projects: state.projectReducer.projects,
    jwt: state.authenticationReducer.jwt,
});

export default connect(mapStateToProps, {
    getProjectsActionCreator,
    deleteProjectAndRefreshProjectsActionCreator,
    toggleProjectEnabledAndRefreshProjectsActionCreator,
    startLoadingActionCreator,
    stopLoadingActionCreator,
})(ManageProjects);
