import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';

import EditButton from '../../../../components/Table/ActionButtons/EditButton';
import Table from '../../../../components/Table/Table';

const UserProjectsTable = ({ data }) => {
    const history = useHistory();

    const columns = [
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Opis',
            accessor: 'description',
        },
        {
            Header: 'Utworzony',
            accessor: 'created',
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: ({ original }) => (
                <div className="text-right">
                    <EditButton onEdit={() => history.push(`/projects/user/${original.id}`)} />
                </div>
            ),
        },
    ];

    return <Table data={data} columns={columns} />;
};

UserProjectsTable.propTypes = {
    data: PropTypes.array,
};

export default UserProjectsTable;
