import PropTypes from 'prop-types';
import React from 'react';

import AllActionButtons from '../../../../../components/Table/ActionButtons/AllButtons';
import Table from '../../../../../components/Table/Table';
import AddButton from '../../../../../customComponents/AddButton';
import * as ListApiClient from '../../../../../domain/list/ListApiClient';
import { useListHandleRequest } from '../../../../../infrastructure/api/hooks';
import EditPopup from '../EditPopup';
import { prepareCurrentEditData } from '../helpers';
import { useListPopup } from '../hooks';

const ListWithTermTable = ({ data, mutate, type }) => {
    const onSubmitAdd = useListHandleRequest(type, ListApiClient.addTerm, mutate, 'Termin został dodany.');

    const onSubmitEditAction = useListHandleRequest(type, ListApiClient.editTerm, mutate, 'Termin został zmieniony.');

    const onDelete = useListHandleRequest(type, ListApiClient.deleteTerm, mutate, 'Termin został usunięty.');

    const onSubmitEdit = ({ synonyms, term, termId }) => onSubmitEditAction({ synonyms, term }, { termId });

    const { onSubmit, onAdd, onEdit, showModal, currentEditData, toggle } = useListPopup(
        '',
        onSubmitAdd,
        onSubmitEdit,
        'genericTermForm',
    );

    const renderActionCell = ({ original }) => (
        <AllActionButtons onEdit={onEdit(original)} onDelete={() => onDelete(original)} />
    );

    const renderSynonymsCell = ({ original }) => <div>{original.synonyms.join(', ')}</div>;

    const columns = [
        {
            Header: 'Termin',
            accessor: 'value',
        },
        {
            Header: 'Synonimy',
            Cell: renderSynonymsCell,
        },
        {
            Header: 'Akcje',
            sortable: false,
            filterable: false,
            Cell: renderActionCell,
        },
    ];

    return (
        <>
            <EditPopup
                onClose={toggle}
                show={showModal}
                initialValues={prepareCurrentEditData(currentEditData, 'term')}
                onSubmit={onSubmit}
                title="Termin"
                titleName="term"
                arrayTitle="Synonimy"
                arrayName="synonyms"
                placeholder="Synonim"
            />
            <AddButton onClick={onAdd}>Nowy</AddButton>
            <Table data={data} columns={columns} />
        </>
    );
};

ListWithTermTable.propTypes = {
    data: PropTypes.array.isRequired,
    mutate: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default ListWithTermTable;
