import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'reactstrap';

import { Switch } from '../../../../components/Switch/Switch';
import Table from '../../../../components/Table/Table';
import { basicFilter, SelectWithAllTrueFalse } from '../../../../tools/tableHelpers';

const CrawlerTemplatesTable = ({ data }) => {
    const columns = [
        {
            Header: 'Nazwa',
            accessor: 'name',
        },
        {
            Header: 'Opis',
            accessor: 'description',
        },
        {
            Header: 'Aktywny',
            accessor: 'active',
            // eslint-disable-next-line react/display-name
            Cell: (row) => (
                <Switch
                    value={row.value}
                    onToggle={() => this.props.onToggleCrawlerTemplateActive(row.original.crawlerTemplateId)}
                />
            ),
            filterMethod: basicFilter,
            Filter: SelectWithAllTrueFalse,
        },
    ];

    return (
        <Row>
            <Col>
                <Table data={data} columns={columns} />
            </Col>
        </Row>
    );
};

CrawlerTemplatesTable.propTypes = {
    data: PropTypes.array,
    onToggleCrawlerTemplateActive: PropTypes.func.isRequired,
};

export default CrawlerTemplatesTable;
