import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardFooter, CardText, CardTitle } from 'reactstrap';

import style from './style.module.scss';

const ActiveText = () => (
    <span className="text-green">
        <strong>aktywny</strong>
    </span>
);

const InactiveText = () => (
    <span className="text-muted text-italic">
        <strong>nieaktywny</strong>
    </span>
);

const Template = ({ template, handleSelected, selected }) => {
    const cardClass = classNames(
        {
            [style.inactive]: !template.active,
            [style.selected]: selected,
        },
        'cursor-pointer',
        style.crawlerTemplate,
    );

    return (
        <Card className={cardClass} onClick={() => handleSelected(template)}>
            <CardBody>
                <CardTitle className="h5">
                    <strong>{template.name}</strong>
                </CardTitle>
                <CardText>{template.description}</CardText>
            </CardBody>
            <CardFooter className="text-right">{template.active ? <ActiveText /> : <InactiveText />}</CardFooter>
        </Card>
    );
};

Template.propTypes = {
    template: PropTypes.object.isRequired,
    handleSelected: PropTypes.func.isRequired,
    selected: PropTypes.bool,
};

export default Template;
