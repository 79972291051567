import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleUserAssignedAndRefreshUsersActionCreator } from '../../../../domain/project/ProjectActions';
import { getUsersActionCreator } from '../../../../domain/user/UserActions';
import AssignUsersTable from './AssignUsersTable';

const AssignUsers = ({ projectId, refresh }) => {
    const { users } = useSelector((state) => state.userReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsersActionCreator());
    }, [dispatch, refresh]);

    const handleToggleUserAssigned = (userId, value) =>
        dispatch(toggleUserAssignedAndRefreshUsersActionCreator(userId, projectId, value));

    const data =
        users &&
        users.map((user) => {
            const userIsAssignedToThisProject = user.projects.includes(Number(projectId));
            return Object.assign({}, user, { assigned: userIsAssignedToThisProject });
        });

    return <AssignUsersTable data={data} onToggleUserAssigned={handleToggleUserAssigned} />;
};

AssignUsers.propTypes = {
    projectId: PropTypes.string.isRequired,
    refresh: PropTypes.object,
};

export default AssignUsers;
