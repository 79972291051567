import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AddListPage from '../commons/AddListPage';
import { LIST_TYPES } from '../commons/consts';
import ListWithWordAllPage from '../commons/ListWithWordAllPage';
import ListWithWordPage from '../commons/ListWithWordPage';

const props = {
    title: 'Synonimy aspektów',
    type: LIST_TYPES.STOP_LISTS,
};

const AddStopListPage = () => <AddListPage title="synonimy aspektów" type={LIST_TYPES.STOP_LISTS} />;

const StopListPage = () => <ListWithWordPage {...props} />;

const StopListPageAll = () => <ListWithWordAllPage {...props} webUrl="stopLists" />;

const StopLists = () => (
    <Switch>
        <Route path="/stopLists/manage/:id" component={StopListPage} />
        <Route path="/stopLists/add" component={AddStopListPage} />
        <Route path="/stopLists" component={StopListPageAll} />
    </Switch>
);

export default StopLists;
