import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../../components/Form/FormGroup/FormGroup';
import SubmittingAware from '../../../../../components/SubmittingAware/SubmittingAware';

const validator = (values) => {
    const errors = {};
    const requiredFields = ['name', 'description'];
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Pole wymagane';
        }
    });
    return errors;
};

class EditProjectForm extends React.Component {
    componentDidMount() {
        this.props.fetchData(this.props.resourceId);
    }

    componentDidUpdate(nextProps) {
        if (this.props.submitting && !nextProps.submitting) {
            this.props.fetchData(this.props.resourceId);
        }
    }

    render() {
        const { handleSubmit, onSubmit, pristine, reset, submitting } = this.props;

        return (
            <Form onSubmit={handleSubmit(onSubmit)} action="" className="form-horizontal">
                <Card>
                    <CardHeader>
                        <strong>Edytuj projekt</strong>
                    </CardHeader>
                    <CardBody>
                        <Field component={TextFormGroup} name="name" placeholder="Nazwa" type="text" />
                        <Field
                            component={TextFormGroup}
                            name="description"
                            placeholder="Opis"
                            type="textarea"
                            rows="10"
                        />
                    </CardBody>
                    <CardFooter>
                        <FormButtons pristine={pristine} submitting={submitting} reset={reset} />
                    </CardFooter>
                </Card>
            </Form>
        );
    }
}

EditProjectForm.propTypes = {
    resourceId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    ...FormButtons.propTypes,
};

EditProjectForm = reduxForm({
    form: 'editProject',
    enableReinitialize: true,
    validate: validator,
})(SubmittingAware(EditProjectForm));

const mapStateToProps = (state) => ({
    initialValues: state.projectReducer.project,
});

export default connect(mapStateToProps, {})(EditProjectForm);
