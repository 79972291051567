import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { FormContainer } from '../../../../../components/Form/FormContainer/FormContainer';
import { editProjectActionCreator, getProjectActionCreator } from '../../../../../domain/project/ProjectActions';
import EditProjectDescriptionForm from './EditProjectDescriptionForm';

const EditProjectDescription = ({ projectId }) => {
    const dispatch = useDispatch();

    const onSubmitEditProjectDescriptionForm = (data) => {
        const body = {
            name: data.name ? data.name : null,
            description: data.description ? data.description : null,
        };

        return dispatch(editProjectActionCreator(body, projectId));
    };

    return (
        <FormContainer>
            <EditProjectDescriptionForm
                onSubmit={onSubmitEditProjectDescriptionForm}
                resourceId={projectId}
                fetchData={() => dispatch(getProjectActionCreator(projectId))}
            />
        </FormContainer>
    );
};

EditProjectDescription.propTypes = {
    projectId: PropTypes.string,
};

export default EditProjectDescription;
