import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import Page from '../../../../components/Page';
import AddButton from '../../../../customComponents/AddButton';
import {
    deleteClientAndRefreshClientsActionCreator,
    getClientsActionCreator,
} from '../../../../domain/client/ClientActions';
import ClientsTable from './ClientsTable';

const ManageClients = ({ name }) => {
    const dispatch = useDispatch();

    const clients = useSelector((state) => state.clientReducer.clients);

    const handleDeleteClient = (id, login) => {
        dispatch(deleteClientAndRefreshClientsActionCreator(id, login));
    };

    useEffect(() => {
        dispatch(getClientsActionCreator());
    }, [dispatch]);

    return (
        <Page name={name}>
            <Row className="pb-2">
                <Col>
                    <AddButton link="/clients/add">Nowy klient</AddButton>
                </Col>
            </Row>
            <ClientsTable data={clients} onDelete={handleDeleteClient} />
        </Page>
    );
};

export default ManageClients;
