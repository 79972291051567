import './style.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

const AddButton = ({ children = 'Dodaj', link, onClick }) => {
    const history = useHistory();
    const callAction = () => (onClick ? onClick() : history.push(link));

    return (
        <div className="add-button-wrapper">
            <Button color="primary" size="md" className="add-button" block onClick={callAction}>
                <div className="d-inline-block">{children}</div> <i className="fa fa-plus ml-2" />
            </Button>
        </div>
    );
};

AddButton.propTypes = {
    children: PropTypes.string.isRequired,
    link: PropTypes.string,
    onClick: PropTypes.func,
    state: PropTypes.object,
};

export default AddButton;
