import {
    CButton,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CToggler,
} from '@coreui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logoutActionCreator } from '../../domain/security/AuthenticationActions';

const Header = ({ toggleSidebar, showSidebar, togglePanel, showPanel }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = useSelector(({ authenticationReducer }) =>
        authenticationReducer.jwt ? authenticationReducer.jwt.id : null,
    );

    const userLogin = useSelector(({ authenticationReducer }) =>
        authenticationReducer.jwt ? authenticationReducer.jwt.sub : '',
    );

    const handleLogout = () => dispatch(logoutActionCreator());

    const onClickProfile = () => history.push({ pathname: '/profile/manage', userId });

    return (
        <CNavbar color="primary" className="w-100">
            {!showSidebar && <CToggler inNavbar onClick={toggleSidebar} className="mr-3" />}

            <CNavbarBrand>STARCAT</CNavbarBrand>

            <CNavbarNav className="ml-auto mr-3">
                {!showPanel && (
                    <CButton color="warning" onClick={togglePanel}>
                        <i className="fas fa-calendar-alt mr-2" />
                        Pokaż panel zdarzeń
                    </CButton>
                )}
            </CNavbarNav>

            <CNavbarNav>
                <CDropdown inNav>
                    <CDropdownToggle color="primary">{userLogin}</CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownItem onClick={onClickProfile}>
                            <i className="fa fa-user mr-3" /> Profil
                        </CDropdownItem>
                        <CDropdownItem onClick={handleLogout}>
                            <i className="fa fa-lock mr-3" /> Wyloguj
                        </CDropdownItem>
                    </CDropdownMenu>
                </CDropdown>
            </CNavbarNav>
        </CNavbar>
    );
};

export default Header;
