import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

class ClassicCard extends Component {
    render() {
        return (
            <Card>
                <CardHeader>
                    {typeof this.props.header === 'string' ? <strong>{this.props.header}</strong> : this.props.header}
                </CardHeader>
                <CardBody>{this.props.body}</CardBody>
                <CardFooter>{this.props.footer}</CardFooter>
            </Card>
        );
    }
}

ClassicCard.propTypes = {
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    body: PropTypes.element,
    footer: PropTypes.element,
};

export default ClassicCard;
