export const ICONS = {
    ASPECT_SYNONYMS: 'fas fa-glasses',
    OPINION_SYNONYMS: 'fas fa-gavel',
    ASPECTS_TO_IGNORE: 'fas fa-eye-slash',
    STOP_LISTS: 'far fa-hand-paper',
    LISTS_GENERIC: 'fas fa-dna',
};

export default {
    items: [
        {
            name: 'Pulpit',
            description: 'Pulpit',
            url: '/dashboard',
            icon: 'icon-speedometer',
            authority: ['ROLE_USER', 'ROLE_ADMIN'],
        },
        {
            name: 'Źródła danych',
            description: 'Źródła danych',
            icon: 'fas fa-database',
            authority: ['ROLE_USER', 'ROLE_ADMIN'],
            openOnStart: true,
            children: [
                {
                    name: 'Crawlery',
                    description: 'Edytuj crawlery',
                    url: '/crawlers',
                    icon: 'fa fa-bug',
                    authority: ['ROLE_ADMIN'],
                    showOnDashboard: true,
                    sidebarRegexp: /#\/crawlers\/.*/,
                },
                {
                    name: 'Projekty',
                    description: 'Edytuj projekty',
                    url: '/projects',
                    icon: 'icon-folder',
                    authority: ['ROLE_USER', 'ROLE_ADMIN'],
                    showOnDashboard: true,
                    sidebarRegexp: /.*\/#\/projects\/.*/,
                },
            ],
        },
        {
            name: 'Raporty',
            description: 'Raporty',
            icon: 'fas fa-file-contract',
            authority: ['ROLE_USER', 'ROLE_ADMIN'],
            openOnStart: true,
            children: [
                {
                    name: 'Raporty',
                    description: 'Edytuj raporty',
                    url: '/reports',
                    icon: 'fas fa-file-contract',
                    authority: ['ROLE_ADMIN'],
                    showOnDashboard: true,
                },
                {
                    name: 'Klienci',
                    description: 'Edytuj klientów',
                    url: '/clients',
                    icon: 'fas fa-user-friends',
                    authority: ['ROLE_USER', 'ROLE_ADMIN'],
                    showOnDashboard: true,
                },
                {
                    name: 'Procesy',
                    description: 'Dziennik procesów',
                    url: '/processes',
                    icon: 'fas fa-microchip',
                    authority: ['ROLE_ADMIN'],
                    showOnDashboard: false,
                },
            ],
        },
        {
            name: 'Listy',
            description: 'Listy',
            icon: 'fas fa-list-ul',
            authority: ['ROLE_USER', 'ROLE_ADMIN'],
            openOnStart: false,
            children: [
                {
                    name: 'Listy generyczne',
                    description: 'Edytuj listy generyczne',
                    url: '/listsGeneric',
                    icon: ICONS.LISTS_GENERIC,
                    authority: ['ROLE_ADMIN'],
                    showOnDashboard: false,
                },
                {
                    name: 'Synonimy aspektów',
                    description: 'Edytuj synonimy aspektów',
                    url: '/aspectSynonyms',
                    icon: ICONS.ASPECT_SYNONYMS,
                    authority: ['ROLE_ADMIN'],
                    showOnDashboard: false,
                },
                {
                    name: 'Synonimy opinii',
                    description: 'Edytuj synonimy opinii',
                    url: '/opinionSynonyms',
                    icon: ICONS.OPINION_SYNONYMS,
                    authority: ['ROLE_ADMIN'],
                    showOnDashboard: false,
                },
                {
                    name: 'Aspekty do ignorowania',
                    description: 'Edytuj aspekty do ignorowania',
                    url: '/aspectsToIgnore',
                    icon: ICONS.ASPECTS_TO_IGNORE,
                    authority: ['ROLE_ADMIN'],
                    showOnDashboard: false,
                },
                {
                    name: 'Stoplisty',
                    description: 'Edytuj stoplisty',
                    url: '/stopLists',
                    icon: ICONS.STOP_LISTS,
                    authority: ['ROLE_ADMIN'],
                    showOnDashboard: false,
                },
                // {
                //     name: 'Słowniki',
                //     description: 'Edytuj słowniki',
                //     url: '/dictionaries',
                //     icon: 'fas fa-spell-check',
                //     authority: ['ROLE_ADMIN'],
                //     showOnDashboard: false,
                // },
            ],
        },
        {
            name: 'Użytkownicy',
            description: 'Edytuj użytkowników',
            url: '/users',
            icon: 'fas fa-user-astronaut',
            authority: ['ROLE_ADMIN'],
            showOnDashboard: true,
        },
    ],
};
