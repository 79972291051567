import classnames from 'classnames';
import React from 'react';

const InputFeedback = ({ children }) => <span className="text-danger">{children}</span>;

const Label = ({ error, children, ...props }) => {
    return <label {...props}>{children}</label>;
};

const TextInput = ({
    field: { name, ...field },
    form: { touched, errors },
    className,
    label,
    type,
    options,
    ...props
}) => {
    const error = errors[name];
    const touch = touched[name];
    const classes = classnames(
        'form-group',
        {
            'animated shake error': !!error,
        },
        className,
    );
    return (
        <div className={classes}>
            <Label htmlFor={name} error={error} className="align-middle font-weight-bold">
                <i className="fas fa-chevron-right mr-3" />
                {label}
            </Label>
            {type === 'text' && <input id={name} className="form-control" type={type} {...field} {...props} />}
            {type === 'select' && (
                <select id={name} className="form-control" {...field} {...props}>
                    {options.map((option, index) => (
                        <option key={index} value={option.key}>
                            {option.value}
                        </option>
                    ))}
                </select>
            )}
            {touch && error && <InputFeedback>{error}</InputFeedback>}
        </div>
    );
};

export default TextInput;
