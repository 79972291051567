import React from 'react';
import { Button } from 'reactstrap';

const CloseButton = ({ onClose, color = 'danger' }) => (
    <Button onClick={onClose} color={color} outline>
        <i className="fa fa-times mr-2" /> Zamknij
    </Button>
);

export default CloseButton;
