import cn from 'classnames';
import React from 'react';
import { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import Breadcrumb, { routes } from '../../components/Breadcrumb/Breadcrumb';
import EventPanel from '../../components/EventPanel';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import NotificationContainer from '../../components/NotificationContainer/NotificationContainer';
import Sidebar from '../../components/Sidebar';
import { Admin, User } from '../../domain/security/AuthorityAwareComponentWrapper/AuthorityAwareComponentWrapper';
import ErrorBoundaryComponentWrapper from '../ErrorBoundaryComponentWrapper';
import Clients from './Clients';
import Crawlers from './Crawlers/Crawlers';
import Dashboard from './Dashboard/Dashboard';
import AspectsToIgnore from './Lists/AspectsToIgnore';
import AspectSynonyms from './Lists/AspectSynonyms';
import GenericListsPage from './Lists/Generic';
import OpinionSynonyms from './Lists/OpinionSynonyms';
import StopLists from './Lists/StopLists';
import Processes from './Processes';
import Profile from './Profile/Profile';
import Projects from './Projects/Projects';
import Reports from './Reports';
import Users from './Users/Users';

const DEFAULT_COL = { lg: { size: 10, offset: 1 } };
const COL_WITH_PANEL_OPEN = { lg: { size: 9 } };
const PANEL_COL = { lg: { size: 3 } };

const getContentCol = (isOpen) => (isOpen ? COL_WITH_PANEL_OPEN : DEFAULT_COL);

const Main = (props) => {
    const [showSidebar, setShowSidebar] = useState(true);
    const [showPanel, setShowPanel] = useState(false);
    const classNames = cn({ 'no-padding-right': showPanel });

    const toggleSidebar = () => setShowSidebar(!showSidebar);
    const togglePanel = () => setShowPanel(!showPanel);

    return (
        <div className="c-app">
            {showSidebar && <Sidebar {...props} toggleSidebar={toggleSidebar} showSidebar={showSidebar} />}

            <div className="c-wrapper">
                <div className="c-header">
                    <Header
                        toggleSidebar={toggleSidebar}
                        showSidebar={showSidebar}
                        togglePanel={togglePanel}
                        showPanel={showPanel}
                    />
                </div>

                <div className="c-body">
                    <main className="c-main no-padding">
                        <Container fluid className={classNames}>
                            <Row>
                                <Col {...getContentCol(showPanel)} className="pt-40">
                                    <Breadcrumb routes={routes} />
                                    <NotificationContainer {...props} />

                                    <Switch>
                                        <Route
                                            path="/profile"
                                            component={ErrorBoundaryComponentWrapper(User(Profile))}
                                        />
                                        <Route
                                            path="/dashboard"
                                            component={ErrorBoundaryComponentWrapper(User(Dashboard))}
                                        />
                                        <Route
                                            path="/projects"
                                            component={ErrorBoundaryComponentWrapper(User(Projects))}
                                        />
                                        <Route
                                            path="/reports"
                                            component={ErrorBoundaryComponentWrapper(User(Reports))}
                                        />
                                        <Route path="/users" component={ErrorBoundaryComponentWrapper(Admin(Users))} />
                                        <Route
                                            path="/crawlers"
                                            component={ErrorBoundaryComponentWrapper(Admin(Crawlers))}
                                        />
                                        <Route
                                            path="/clients"
                                            component={ErrorBoundaryComponentWrapper(User(Clients))}
                                        />
                                        <Route
                                            path="/processes"
                                            component={ErrorBoundaryComponentWrapper(User(Processes))}
                                        />
                                        <Route
                                            path="/aspectSynonyms"
                                            component={ErrorBoundaryComponentWrapper(Admin(AspectSynonyms))}
                                        />
                                        <Route
                                            path="/listsGeneric"
                                            component={ErrorBoundaryComponentWrapper(Admin(GenericListsPage))}
                                        />
                                        <Route
                                            path="/opinionSynonyms"
                                            component={ErrorBoundaryComponentWrapper(Admin(OpinionSynonyms))}
                                        />
                                        <Route
                                            path="/aspectsToIgnore"
                                            component={ErrorBoundaryComponentWrapper(Admin(AspectsToIgnore))}
                                        />
                                        <Route
                                            path="/stopLists"
                                            component={ErrorBoundaryComponentWrapper(Admin(StopLists))}
                                        />
                                        <Redirect from="/" to="/dashboard" />
                                    </Switch>
                                </Col>

                                {showPanel ? (
                                    <Col {...PANEL_COL}>
                                        <EventPanel togglePanel={togglePanel} />
                                    </Col>
                                ) : null}
                            </Row>
                        </Container>
                    </main>
                </div>

                <footer className="c-footer">
                    <Footer />
                </footer>
            </div>
        </div>
    );
};

export default Main;
