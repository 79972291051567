import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddUser from './Add/AddUser';
import EditUser from './Edit/EditUser';
import ManageUsers from './Manage/ManageUsers';

const Users = () => (
    <Switch>
        <Route path="/users/manage" render={(props) => <ManageUsers name="Zarządzaj użytkownikami" {...props} />} />
        <Route path="/users/add" render={(props) => <AddUser name="Dodaj użytkownika" {...props} />} />
        <Route path="/users/edit/:id" render={(props) => <EditUser name="Edytuj użytkownika" {...props} />} />
        <Redirect from="/users" to="/users/manage" />
    </Switch>
);

export default Users;
