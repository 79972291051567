import * as rrs from 'react-redux-spinner';

export const LOADING_START = 'LOADING_START';
export const LOADING_DONE = 'LOADING_DONE';

export function startLoadingActionCreator() {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({
                type: LOADING_START,
                [rrs.pendingTask]: rrs.begin,
            });
            resolve();
        });
    };
}

export function stopLoadingActionCreator() {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({
                type: LOADING_DONE,
                [rrs.pendingTask]: rrs.end,
            });
            resolve();
        });
    };
}
