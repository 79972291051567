import React from 'react';
import useSWR from 'swr';

import AccordionPanel from '../../../../components/AccordionPanel';
import { useFormEditPopup } from '../../../../components/hooks';
import { CenteredSpinner } from '../../../../components/Spinner/Spinner';
import AddButton from '../../../../customComponents/AddButton';
import { API_URL } from '../../../../environment';
import { useFetcher } from '../../../../infrastructure/api/tools';
import { commonFieldValidator } from '../../../../tools/validatorHelpers';
import AddedListsTable from '../commons/AddedListsTable';
import AddListModal from '../commons/AddListsModal';

const OneListAccordion = ({ type, icon, onSubmit, data, onDelete, text, initialValues, commonProps }) => {
    const form = 'addListToReportForm' + type;
    const fetcher = useFetcher();
    const props = useFormEditPopup('', onSubmit, () => {}, form);
    const { data: selectData } = useSWR(`${API_URL}/lists/${type}`, fetcher);

    return (
        <AccordionPanel text={text} icon={icon} id={type} {...commonProps}>
            <AddListModal
                {...props}
                data={selectData}
                type={type}
                name={type}
                initialValues={initialValues}
                validate={commonFieldValidator([type])}
                form={form}
                shouldError={() => true}
            />
            <AddButton onClick={props.toggle} />

            {data ? <AddedListsTable data={data} onDeleteAction={onDelete} /> : <CenteredSpinner />}
        </AccordionPanel>
    );
};

export default OneListAccordion;
