import React from 'react';

import { useAccordionPanel } from '../../../../components/AccordionPanel';
import { ICONS } from '../../../../components/Sidebar/_nav';
import { LIST_TYPES } from '../../../../consts/listTypes';
import OneListAccordion from './OneListAccordion';

const ListsAccordion = ({ onDelete, onSubmit, data }) => {
    const commonProps = useAccordionPanel();

    const prepareProps = (type) => ({
        type,
        commonProps,
        onSubmit: onSubmit(type),
        onDelete: onDelete(type),
        data: data[type],
        initialValues: { [type]: '' },
    });

    return (
        <>
            <OneListAccordion
                {...prepareProps(LIST_TYPES.ASPECT_SYNONYMS)}
                icon={ICONS.ASPECT_SYNONYMS}
                text="Synonimy aspektów"
            />
            <OneListAccordion
                {...prepareProps(LIST_TYPES.OPINION_SYNONYMS)}
                icon={ICONS.OPINION_SYNONYMS}
                text="Synonimy opinii"
            />
            <OneListAccordion
                {...prepareProps(LIST_TYPES.ASPECTS_TO_IGNORE)}
                icon={ICONS.ASPECTS_TO_IGNORE}
                text="Aspekty do ignorowania"
            />
            <OneListAccordion {...prepareProps(LIST_TYPES.STOP_LISTS)} icon={ICONS.STOP_LISTS} text="Stoplisty" />
        </>
    );
};

export default ListsAccordion;
