import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../components/Form/FormGroup/FormGroup';

const validator = (values) => {
    const errors = {};
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Nieprawidłowy adres email';
    }
    if (values.password && values.repeatPassword && !(values.password === values.repeatPassword)) {
        errors.repeatPassword = 'Hasła muszą być takie same';
    }
    return errors;
};

let EditUserForm = ({ handleSubmit, onSubmit, pristine, reset, submitting }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
            <Card>
                <CardHeader>
                    <strong>Edytuj użytkownika</strong>
                </CardHeader>
                <CardBody>
                    <Field component={TextFormGroup} name="login" placeholder="Login" type="text" />
                    <Field
                        component={TextFormGroup}
                        name="password"
                        placeholder="Hasło"
                        type="password"
                        help="min. 8 znaków"
                    />
                    <Field
                        component={TextFormGroup}
                        name="repeatPassword"
                        placeholder="Powtórz hasło"
                        type="password"
                    />
                    <Field
                        component={TextFormGroup}
                        name="name"
                        placeholder="Nazwa"
                        type="text"
                        help="Imię lub nazwa firmy"
                    />
                    <Field component={TextFormGroup} name="email" placeholder="Email" type="email" />
                    <Field component={TextFormGroup} name="enabled" type="select" placeholder="Aktywny">
                        <option value="true">Tak</option>
                        <option value="false">Nie</option>
                    </Field>
                    <Field component={TextFormGroup} name="role" type="select" placeholder="Uprawnienia">
                        <option value="">Wybierz role...</option>
                        <option value="USER">Użytkownik</option>
                        <option value="ADMIN">Administrator</option>
                    </Field>
                </CardBody>
                <CardFooter>
                    <FormButtons pristine={pristine} submitting={submitting} reset={reset} />
                </CardFooter>
            </Card>
        </Form>
    );
};

EditUserForm.propTypes = {
    resourceId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    reset: PropTypes.func,
    submitting: PropTypes.bool,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

EditUserForm = reduxForm({
    form: 'editUser',
    enableReinitialize: true,
    validate: validator,
})(EditUserForm);

export default EditUserForm;
