import { SubmissionError } from 'redux-form';

import { logoutActionCreator } from '../../domain/security/AuthenticationActions';
import { apiErrorActionCreator, redirectActionCreator } from './ErrorActions';

const handleError = (error, dispatch, redirect = null) =>
    handleApiError(error, new DispatchingHandler(dispatch, redirect));

export default handleError;

class DispatchingHandler {
    _dispatch;
    _redirect;

    constructor(dispatch, redirect) {
        this._dispatch = dispatch;
        this._redirect = redirect;
    }

    handleGenericApiError = (error, message) => this._dispatch(apiErrorActionCreator(error, message));

    handleUnauthorizedError = () => this._dispatch(logoutActionCreator());

    handleNotFoundError = (error) => {
        if (this._redirect != null) {
            this._dispatch(redirectActionCreator(this._redirect.history, this._redirect.parentUrl));
        } else {
            this.handleGenericApiError(error, `Błąd serwera: ${error.response.status}`);
        }
    };
}

function handleApiError(error, handler) {
    if (error.response) {
        handleErrorResponse(error, handler);
    } else {
        handler.handleGenericApiError(error, 'Błąd serwera: brak połączenia');
    }
}

function handleErrorResponse(error, handler) {
    if (error.response.status === 400 && error.response.data.errors) {
        throw new SubmissionError(extractValidationErrors(error));
    } else if (error.response.status === 401 || error.response.status === 403) {
        handler.handleUnauthorizedError();
    } else if (error.response.status === 404) {
        handler.handleNotFoundError(error, `Błąd serwera: ${error.response.status}`);
    } else {
        handler.handleGenericApiError(error, `Błąd serwera: ${error.response.status}`);
    }
}

function extractValidationErrors(apiErrorResponse) {
    const fieldsWithMessages = apiErrorResponse.response.data.errors.map((e) => ({
        [e.field]: mapErrorMessage(e.defaultMessage),
    }));
    return Object.assign({}, ...fieldsWithMessages);
}

const errorMessagesMap = new Map([
    ['parameter is required', 'Pole wymagane'],
    ['not found', 'Zasób nie istnieje'],
    ['already exists', 'Zasób już istnieje'],
    ['value must be positive integer', 'Wartość powinna być dodatnią liczbą całkowitą'],
]);

function mapErrorMessage(message) {
    const mappedMessage = errorMessagesMap.get(message);
    if (mappedMessage) return mappedMessage;
    else return message;
}
