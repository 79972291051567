import 'react-table/react-table.css';

import React from 'react';
import ReactTable from 'react-table';

const MIN_ROWS = 5;

const Table = (props) => (
    <ReactTable
        minRows={MIN_ROWS}
        previousText="Poprzednia"
        nextText="Następna"
        loadingText="Ładowanie..."
        noDataText="Brak danych"
        pageText="Strona"
        ofText="z"
        rowsText="wierszy"
        className="-striped -highlight"
        filterable
        defaultFilterMethod={(filter, row) =>
            row[filter.id].toUpperCase().startsWith(filter.value.trim().toUpperCase())
        }
        {...props}
    />
);

export default Table;
