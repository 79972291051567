import React from 'react';
import useSWR from 'swr';

import Page from '../../../../components/Page';
import { API_URL } from '../../../../environment';
import { REFRESH_0, useFetcher } from '../../../../infrastructure/api/tools';
import ProcessesTable from './ProcessesTable';

const ProcessesAll = () => {
    const fetcher = useFetcher();
    const { data } = useSWR(`${API_URL}/reports/rebuild`, fetcher, REFRESH_0);

    return (
        <Page name="Procesy">
            <ProcessesTable data={data || []} />
        </Page>
    );
};

export default ProcessesAll;
