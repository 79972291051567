import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

const getChildrenUrls = (navs) => {
    let urls = [];

    for (let element of navs) {
        if (element.children) {
            const childrenUrls = getChildrenUrls(element.children);
            urls = [...urls, ...childrenUrls];
        } else {
            urls.push(element.url);
        }
    }

    return urls;
};
const DropdownParent = ({ parent, children, pathname }) => {
    const [isOpen, setIsOpen] = useState(false);
    const arrow = classnames('sidebar-menu-dropdown-icon', 'fas', { 'fa-caret-up': isOpen, 'fa-caret-down': !isOpen });

    useEffect(() => {
        const childrenUrls = getChildrenUrls(parent.children);
        const isActive = childrenUrls.some((el) => pathname.includes(el));
        !isOpen && setIsOpen(isActive);
        /* eslint-disable */
    }, [pathname]);
    /* eslint-enable */

    return (
        <li className="c-sidebar-nav-item nav-dropdown mt-1">
            <span className="c-sidebar-nav-link nav-dropdown-toggle" onClick={() => setIsOpen(!isOpen)}>
                <i className={`c-sidebar-nav-icon ${parent.icon}`} /> {parent.name} <i className={arrow} />
            </span>
            {isOpen && <ul className="c-sidebar-nav-dropdown-items">{children}</ul>}
        </li>
    );
};

export default DropdownParent;
