import PropTypes from 'prop-types';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

export const FormButtons = ({ submitting, showClose = true, onClose }) => {
    const history = useHistory();

    return (
        <div className="text-right">
            {showClose ? (
                <Button
                    className="mr-2 pl-2 pr-2"
                    size="sm"
                    color="danger"
                    disabled={submitting}
                    onClick={onClose || history.goBack}
                >
                    <i className="fa fa-ban mr-2" /> Anuluj
                </Button>
            ) : null}

            <Button className="pl-2 pr-2" type="submit" size="sm" color="primary" disabled={submitting}>
                <i className="fa fa-save mr-2" /> Zapisz
            </Button>
        </div>
    );
};

FormButtons.propTypes = {
    submitting: PropTypes.bool.isRequired,
    showClose: PropTypes.bool,
    onClose: PropTypes.func,
};
