import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'reactstrap';
import { Field, propTypes, reduxForm } from 'redux-form';

import { FormButtons } from '../../../../../components/Form/FormButtons/FormButtons';
import { TextFormGroup } from '../../../../../components/Form/FormGroup/FormGroup';
import SubmittingAware from '../../../../../components/SubmittingAware/SubmittingAware';
import { delaysArray } from '../../../../../domain/crawler/Delay';
import mapToInitialValues from './InitialValuesMapper';
import style from './style.module.scss';

const validator = (values, props) => {
    const errors = {};
    const dynamicRequiredFields = props.parameters
        .filter((parameter) => resolveRequired(parameter))
        .map((parameter) => parameter.name);
    const requiredFields = ['name', 'delay'].concat(dynamicRequiredFields);
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Pole wymagane';
        }
    });
    return errors;
};

function resolveInputType(parameter) {
    return parameter.parameterType === 'BOOLEAN' ? 'checkbox' : 'text';
}

function resolveRequired(parameter) {
    return parameter.parameterType !== 'BOOLEAN' && parameter.required;
}

class AddCrawlerForm extends React.Component {
    componentDidMount() {
        this.handleInitialize();
    }

    handleFormSubmit = (template) => (formData) => {
        return this.props.onSubmit({ template, formData });
    };

    handleInitialize() {
        const dynamicInitialValues = mapToInitialValues(this.props.template);
        const middleDelayValue = delaysArray[Math.round((delaysArray.length - 1) / 2)].key;
        const initData = Object.assign(dynamicInitialValues, { delay: middleDelayValue });
        this.props.initialize(initData);
    }

    render() {
        const { handleSubmit, pristine, reset, submitting, parameters, template } = this.props;
        return (
            <Form
                onSubmit={handleSubmit(this.handleFormSubmit(template))}
                action=""
                className={`form-horizontal ${style.form}`}
            >
                <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                    <li>
                        <Field
                            component={TextFormGroup}
                            name="name"
                            placeholder="Nazwa crawlera"
                            required
                            type="text"
                        />
                    </li>
                    {parameters.map((parameter) => (
                        <li key={parameter.id}>
                            <Field
                                component={TextFormGroup}
                                name={parameter.name}
                                placeholder={parameter.description}
                                required={resolveRequired(parameter)}
                                type={resolveInputType(parameter)}
                            />
                        </li>
                    ))}
                    <li>
                        <Field
                            component={TextFormGroup}
                            name="delay"
                            required
                            placeholder="Częstotliwość uruchamiania"
                            type="select"
                        >
                            {delaysArray.map((delay, index) => (
                                <option key={index} value={delay.key}>
                                    {delay.value}
                                </option>
                            ))}
                        </Field>
                    </li>
                </ul>
                <FormButtons pristine={pristine} submitting={submitting} reset={reset} />
            </Form>
        );
    }
}

AddCrawlerForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    parameters: PropTypes.array.isRequired,
    template: PropTypes.object.isRequired,
    ...propTypes,
};

AddCrawlerForm = reduxForm({
    form: 'addCrawler',
    validate: validator,
})(SubmittingAware(AddCrawlerForm));

export default AddCrawlerForm;
