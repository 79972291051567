import React from 'react';
import { Field } from 'redux-form';

import { TextFormGroup } from '../../../../components/Form/FormGroup/FormGroup';

const ListSelect = ({ data, name = 'listId' }) => (
    <Field component={TextFormGroup} name={name} type="select" placeholder="Lista">
        <option value="">Lista...</option>
        {data.map((el) => (
            <option key={el.id} value={el.id}>
                {el.name}
            </option>
        ))}
    </Field>
);

export default ListSelect;
