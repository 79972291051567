import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';

import Table from '../../../../components/Table/Table';

const COLUMNS = [
    {
        Header: 'Nazwa crawlera',
        accessor: 'crawlerName',
    },
    {
        Header: 'Nazwa projektu',
        accessor: 'projectName',
    },
    {
        Header: 'Rozpoczęcie',
        accessor: 'started',
    },
    {
        Header: 'Zakończenie',
        accessor: 'ended',
    },
    {
        Header: 'Planowane',
        accessor: 'plannedStartTime',
    },
];

const ShowProjectAction = ({ id }) => (
    <div className="text-right">
        <Link to={`/projects/${id}`}>Zobacz projekt</Link>
    </div>
);

const RunsTime = ({ data }) => {
    const column = {
        Header: 'Akcja',
        Cell: ({ original }) => <ShowProjectAction id={original.projectId} />,
    };
    const columns = [...COLUMNS, column];

    return (
        <Card>
            <CardBody>
                <CardTitle>
                    <h2>Uruchomienia crawlerów</h2>
                </CardTitle>
                <CardSubtitle className="mb-3">Dane z ostanich 10 uruchomień crawlerów</CardSubtitle>
                <Table columns={columns} data={data} showPagination={false} filterable={false} />
            </CardBody>
        </Card>
    );
};

export default RunsTime;
